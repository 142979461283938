import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import CommentIcon from "@material-ui/icons/Comment";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import DescriptionIcon from "@material-ui/icons/Description";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import GestureIcon from "@material-ui/icons/Gesture";
import PollIcon from "@material-ui/icons/Poll";
import GroupIcon from '@material-ui/icons/Group';
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/fondoblack.jpg";
import logo from "assets/img/ConCertezaGris.png";
import axios from "axios";
import Documentos from "./Documentos";
import Firmantes from "./Firmantes";
import Clientes from "./Clientes";
import Grupos from "./Grupos";
import Firmas from "./Firmas";
import ConsultaLogs from "containers/Logs/ConsultaLogs";
import ConsultaLogCaptures from "containers/Logs/ConsultaLogCaptures";
import EstadisticasGraficas from "./EstadisticasGraficas";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import GraficaPrincipal from "containers/Graficas/PantallaPrincipal";
const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Statistics() {
  // styles
  const classes = useStyles();

  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");

  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [user, setUser] = React.useState("");

  const [valueState, setValueState] = React.useState(0);
  const handleChangeTabs = (event, value) => {
    setValueState(value);
  };

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    let token = localStorage.getItem("Token");
    console.log(token);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/auth/user`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setUser(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const buscarRol = (rol) => {
    if (user) {
      const found = user.roles.find((element) => element === rol);
      return found;
    }
    return null;
  };

  const buscarPermiso = (permiso) => {
    if (user) {
      const found = user.permissions.search(permiso);
      return found;
    }
    return null;
  };

  //tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function MenuAdmin() {
    return (
        <CustomTabs
          handleChange={handleChangeTabs}
          valueState={valueState}
          headerColor={"info"}
          Statistics={true}
          title=""
          tabs={[
            /*
            {
              tabName: "COMPAÑÍAS",
              tabIcon: PersonPinIcon,
              tabContent: (
                <div>
                  <Clientes />
                </div>
              ),
            },
            */
            {
              tabName: "DOCUMENTOS",
              tabIcon: DescriptionIcon,
              tabContent: (
                <div>
                  <Documentos />
                </div>
              ),
            },
            {
              tabName: "FIRMANTES",
              tabIcon: SupervisedUserCircleIcon,
              tabContent: (
                <div>
                  <Firmantes />
                </div>
              ),
            },

            {
              tabName: "FIRMAS",
              tabIcon: GestureIcon,
              tabContent: (
                <div>
                  <Firmas />
                </div>
              ),
            },

            {
              tabName: "GRÁFICAS",
              tabIcon: PollIcon,
              tabContent: (
                <div>
                  <EstadisticasGraficas />
                </div>
              ),
            },

            {
              tabName: "LOGS",
              tabIcon: CommentIcon,
              tabContent: (
                <div>
                  <ConsultaLogCaptures />
                </div>
              ),
            },

          ]}
        />
    );
  }

  function MenuCreador() {
    return (
        <CustomTabs
          handleChange={handleChangeTabs}
          valueState={valueState}
          headerColor={"info"}
          Statistics={true}
          title=""
          tabs={[
/*
            {
              tabName: "COMPAÑÍAS",
              tabIcon: GroupIcon,
              tabContent: (
                <div>
                  <Grupos />
                </div>
              ),
            },
*/
            {
              tabName: "DOCUMENTOS",
              tabIcon: DescriptionIcon,
              tabContent: (
                <div>
                  <Documentos />
                </div>
              ),
            },

            {
              tabName: "FIRMANTES",
              tabIcon: SupervisedUserCircleIcon,
              tabContent: (
                <div>
                  <Firmantes />
                </div>
              ),
            },

            {
              tabName: "FIRMAS",
              tabIcon: GestureIcon,
              tabContent: (
                <div>
                  <Firmas />
                </div>
              ),
            },

            {
              tabName: "GRÁFICAS",
              tabIcon: PollIcon,
              tabContent: (
                <div>
                  <EstadisticasGraficas />
                </div>
              ),
            },

            {
              tabName: "LOGS",
              tabIcon: CommentIcon,
              tabContent: (
                <div>
                  <ConsultaLogCaptures />
                </div>
              ),
            },

          ]}
        />
    );
  }

  function MenuCDO() {
    return (
        <CustomTabs
          handleChange={handleChangeTabs}
          valueState={valueState}
          headerColor={"info"}
          Statistics={true}
          title=""
          tabs={[
/*
            {
              tabName: "COMPAÑÍAS",
              tabIcon: PersonPinIcon,
              tabContent: (
                <div>
                  <Clientes />
                </div>
              ),
            },
  */

            {
              tabName: "COMPAÑÍAS",
              tabIcon: GroupIcon,
              tabContent: (
                <div>
                  <Grupos />
                </div>
              ),
            },

            {
              tabName: "DOCUMENTOS",
              tabIcon: DescriptionIcon,
              tabContent: (
                <div>
                  <Documentos />
                </div>
              ),
            },
            {
              tabName: "FIRMANTES",
              tabIcon: SupervisedUserCircleIcon,
              tabContent: (
                <div>
                  <Firmantes />
                </div>
              ),
            },

            {
              tabName: "FIRMAS",
              tabIcon: GestureIcon,
              tabContent: (
                <div>
                  <Firmas />
                </div>
              ),
            },

            {
              tabName: "GRÁFICAS",
              tabIcon: PollIcon,
              tabContent: (
                <div>
                  <EstadisticasGraficas />
                </div>
              ),
            },

            {
              tabName: "LOGS",
              tabIcon: CommentIcon,
              tabContent: (
                <div>
                  <ConsultaLogCaptures />
                </div>
              ),
            },

            {
              tabName: "LOGS CDO",
              tabIcon: CommentIcon,
              tabContent: (
                <div>
                  <ConsultaLogs />
                </div>
              ),
            },

          ]}
        />
    );
  }

  return (
    <React.Fragment>
      {/*<div className={classes.root}>*/}

      {/*<Grid xs={12} sm={12} md={12}> */}
        {
          //usando permisos todas >= 0
          buscarPermiso("stats.cdo") >= 0 ? (
            <MenuCDO />
          ) : buscarPermiso("stats.admin") >= 0 ? (
            <MenuAdmin />
          ) : buscarPermiso("stats.view") >= 0 ? (
            <MenuCreador />
          ) : null
          /*
            //usando roles
            (buscarRol("CDO"))? <MenuCDO /> :
            (buscarRol("Stats Admin")) ? <MenuAdmin /> :
            (buscarRol("Creador")) ? <MenuCreador /> : null
            */
        }
      {/*</Grid>     */}
    </React.Fragment>
  );
}
