import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import validator from "validator";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// import MuiButton from "@material-ui/core/MuiButton";
import ButtonC from "components/CustomButtons/Button.js";
import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
//import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { green, deepOrange } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
import ErrorIcon from "@material-ui/icons/Error";
import Snackbar from "components/Snackbar/Snackbar.js";
import Snackbarlol from "@material-ui/core/Snackbar";
import FormHelperText from "@material-ui/core/FormHelperText";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";

import DeleteIcon from "@material-ui/icons/Delete";

//LEMP
import Autocomplete from "@material-ui/lab/Autocomplete";
import UbicarFirma from "./newUbicarFirma";
import Notificados from "./Notificados";
//LEMP
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import StarIcon from "@material-ui/icons/Star";

//listItem
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";

//* dialog

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";
import { MySnackbar } from "components/Snackbar/MySnackbar";
import { withStyles } from "@material-ui/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 5,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  codigo: {
    marginTop: theme.spacing(-1),
  },
  formControl: {
    margin: theme.spacing(-1),
    padding: 0,
    marginRight: "-15px",
  },
  container: {
    padding: theme.spacing(2),
  },

  buttonProgress: {
    color: green[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const useStyles2 = makeStyles((theme) => ({
  green: {
    color: "#fff",
    backgroundColor: green["A700"],
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));
const useStylestable = makeStyles(styles2);

export default function AddressForm(props) {
  const classespro = useStylesprogrees();

  const ref = React.createRef();
  function goTop() {
    console.log("REF");
    ref.current.scrollIntoView();
  }
  const history = useHistory();
  const [tokenstate, setTokenstate] = useState("");
  const [disabledFirmante, setdisabledFirmante] = useState(true);
  const [obligatorioCelular, setobligatorioCelular] = useState(false);
  const [disabledModificar, setdisabledModificar] = useState(true);
  const [obliCelularModificar, setobliCelularModificar] = useState(true);
  //data firmantes
  const [datosFirmantes, setDatosFirmantes] = useState([{}]);
  const [firmantesPrevios, setFirmantesPrevios] = useState(false);
  const [tiposActuacion, setTiposActuacion] = useState("Por su Propio Derecho");
  const [tipoActuacion, setTipoActuacion] = useState({
    codigo: 1,
    valor: "Por su Propio Derecho",
  });
  const [estiloCelular, setEstiloCelular] = useState("gray");
  const [modalNotificados, setModalNotificados] = useState(false);
  const [textoValidacionMail, setTextoValidacionMail] = useState("");
  const [checked, setChecked] = useState([1]);
  /*LEMP*/
  /*arreglo para almacenar el objeto coordenadas*/
  const [coordenadasFirma, setCoordenadasFirma] = useState([{}]);
  /*indicador para saber si estoy cargando un nuevo firmante*/
  const [nuevoFirmante, setNuevoFirmante] = React.useState(true);
  const [valueNotificaciones, setValueNotificaciones] = React.useState("1");

  const handleChangeNotificacion = (event) => {
    setValueNotificaciones(event.target.value);
  };
  /*LEMP*/

  const classes2 = useStylestable();
  const classesicon = useStyles2();

  const classes = useStyles();
  const {
    idDocumento,
    senFirmante,
    Register,
    idRequired,
    idDocumentoDetenido,
    idprocces,
    setIdRequired,
  } = props;
  const [value, setValue] = useState("1");

  const [messageError, setMessageError] = useState("");
  const [openAlert, setOpenAlert] = useState("");
  const [nameFirmante, setNameFirmante] = useState("");
  const [numFirmante, setNumFirmante] = useState(1);
  const [modalEditarFirmante, setModalEditarFirmante] = useState(false);

  const timer = useRef();
  const [displaySign, setDisplaySign] = useState(false);
  const [snackData, setSnackData] = useState({
    openSnackError: false,
    snackError: "",
    snackMessage: "",
    snackSeverity: "",
  });
  const { openSnackError, snackError } = snackData;
  const [idFirmante, setIdFirmante] = useState("");
  const [valueModificar, setValueModificar] = useState("1");

  const [progress, setprogress] = useState(false);
  const [arrayIDFirmante, setArrayIDFirmante] = useState([]);
  const [messageSucces, setMessageSucces] = useState("");
  const [openSucces, setOpenSucces] = useState(false);
  const [companyFrecuente, setCompanyFrecuente] = useState([]);

  const [state, setState] = useState({
    checkedVida: false,
    checkedsolicitud: false,
  });

  const [datosModificar, setDatosModificar] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    rol: "Por su Propio Derecho",
    Compañia: "",
  });
  const [datos, setDatos] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    rol: "",
    Compañia: "",
    idFirmante: "",
  });
  //almacena todas las firmas configuradas en el documento
  const [ubicacionFirmaHistoria, setUbicacionFirmaHistoria] = useState([]);

  const [firmaYaHechas, setFirmasYaHechas] = useState([]);
  //almacena la coordenadas de la última posisición de la firma
  const [coordenadas, setCoordenadas] = useState({
    page: 0,
    x: 0,
    y: 0,
    screenX: 0,
    screenY: 0,
    w: 0,
    h: 0,
    top: 0,
    left: 0,
    nombre: "",
    idFirmante: "",
    height: 0,
    width: 0,
  });

  const [arrayFirmantes, setArrayFirmantes] = useState([]);
  const myRef = React.createRef();
  const RefCompany = React.createRef();

  useEffect(() => {
    datafirmantes();
    getTiposActuacion();

    //funcion para actualizar los firmantes unicos y asi validar el disabled del boton de enviar a firmar

    let token = localStorage.getItem("Token");
    setTokenstate(token);
    // console.log(token);
    //LEMP
    //busca los firmantes del usuario en curso
    var config = {
      method: "get",
      //url: `${process.env.REACT_APP_URL}api/firmantes`,
      url: `${process.env.REACT_APP_URL}api/firmantesfrecuentes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        /*
        //se filtra la respuesta eliminado duplicados
        let arr = response.data;
        if (arr) {
          let pp = arr.filter(
            (ele, ind) =>
              ind === arr.findIndex((elem) => elem.email === ele.email)
          );
          console.log(pp);
          setDatosFirmantes(pp);
*/
        console.log("Firmantes previos:", response.data);
        setDatosFirmantes(response.data);
        const okcompany = handleItemCompany(response.data);
        const dataArraCompany = new Set(okcompany);
        let result = [...dataArraCompany];
        setCompanyFrecuente(result);
        console.log("OKSCOMPAN", result);
        setFirmantesPrevios(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [arrayIDFirmante]);

  const ButtonStyleTip = withStyles({
    root: {
      "&.Mui-disabled": {
        pointerEvents: "auto",
      },
    },
  })(Button);
  const handleItemCompany = (firmantes) => {
    return firmantes.map((item) => {
      return item.company;
    });
  };

  const ButtonWithTooltip = ({ tooltipText, disabled, onClick, ...other }) => {
    const adjustedButtonProps = {
      disabled: disabled,
      component: disabled ? "div" : undefined,
      onClick: disabled ? undefined : onClick,
    };
    return (
      <Tooltip title={tooltipText}>
        <ButtonStyleTip {...other} {...adjustedButtonProps} />
      </Tooltip>
    );
  };

  const datafirmantes = () => {
    let token = localStorage.getItem("Token");

    const endpoint = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        // const ok = itemsFirmantes(resp.data.firmantes);
        // setFirmantesdata(ok);
        // setArra(yFirmantes(resp.data.firmantes);
        console.log("ENTRO FIRMANTES", resp.data.type.id);
        if (resp.data.type.id == 2) {
          setIdRequired(true);
        }
        itemFirmantesAPI(resp.data.firmantes, arrayFirmantes);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };

  const handleCloseSnack = () => {
    setSnackData({
      openSnackError: false,
      snackError: "",
      snackMessage: "",
      snackSeverity: "",
    });
  };
  function addFirmanteFrecuente(value) {
    console.log(value);
    let token = localStorage.getItem("Token");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/firmantes/${value.idFirmante}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        let datos = response.data;

        const data = new FormData();
        data.append("name", datos.name);
        data.append("last_name", datos.last_name);
        data.append("email", datos.email);
        if (datos.phone) {
          data.append("phone_country_code", datos.phone_country_code);
          data.append("phone", datos.phone);
        }
        data.append("role", datos.role);
        data.append("firmante_involvements_id", datos.involvement.id);
        data.append("company", datos.company);
        data.append("notification_types_id", datos.notification_types_id);
        data.append("group", "DEFAULT");
        const config1 = {
          method: "post",
          url: `${process.env.REACT_APP_URL}api/firmantesfrecuentes`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };

        axios(config1)
          .then((response) => {
            // Respuesta del servidor
            let data = response.data;
            console.log(data);
            //showNotificacion(JSON.stringify(data));
            setMessageSucces("Firmante agregado a Firmantes Frecuentes");
            setOpenSucces(true);
          })
          .catch((error) => {
            // Error 😨
            console.log(error.response.data);
            if (error.response.data.length > 1) {
              //showNotificacion(error.response.data[0].message);
              showNotificacion(
                "El firmante ya fue registrado en “Firmantes Frecuentes”."
              );
            } else {
              if (error.response.data.errors.email) {
                showNotificacion(error.response.data.errors.email);
              } else if (error.response.data.errors.phone) {
                showNotificacion(error.response.data.errors.phone);
              } else if (error.response.data.message) {
                showNotificacion(error.response.data.message);
              }
            }
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const itemFirmantesAPI = (DataAPi, arrayFirmantes1) => {
    var collectionCoordenadas = [];
    var collection = [];

    console.log("DATAAPI123", DataAPi);
    console.log("collection", collection);
    DataAPi.map(function (data) {
      var firmante = {
        nombre: data.name,
        apellidos: data.last_name,
        correo: data.email,
        codigo: data.phone_country_code,
        celular: data.phone == null ? "" : data.phone,
        rol: data.role,
        Compañia: data.company,
        idFirmante: data._id,
        date_signed: data.date_signed,
        notification_types_id: data.notification_types_id,
        speech_required: data.speech_required,
        involvement_id: data.involvement.id,
      };

      collection.push(firmante);
      // console.log("DATASIGNATURE", data);
      if (data.signature_coords) {
        data.signature_coords.map(function (dataCor) {
          if (data.date_signed === undefined) {
            //firmas plasmadas con anterioridad
            console.log("firmasAnte", dataCor);
            ubicacionFirmaHistoria.push(dataCor);
          } else if (data.date_signed) {
            firmaYaHechas.push(dataCor);
          }
        });
      }
    });

    setArrayFirmantes(collection);
  };

  //FUNCIONES PARA REGISTRAR LA POSICION DE LA FIRMA

  const clickFijar = () => {
    setDisplaySign(false);

    // setUbicacionFirma([...ubicacionFirma, coordenadas]);

    ubicacionFirmaHistoria.push(coordenadas);
    console.log("historiaFirma", ubicacionFirmaHistoria);

    //buscar cordenadas por id de firmante que pertenezcan a al firmante que vamos actualizar
    const firmantesFiltrados = ubicacionFirmaHistoria.filter(
      (x) => x.idFirmante == idFirmante
    );

    console.log("FILTRO", firmantesFiltrados);
    console.log("CORDENADAS", coordenadas);

    const dataFull = eliminarIdFimante(firmantesFiltrados);
    console.log("itemIDqwe", dataFull);

    console.log("borraridfiirmante", firmantesFiltrados);
    let token = localStorage.getItem("Token");

    var datajs = JSON.stringify({
      signature_coords: firmantesFiltrados,
    });
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_URL}api/firmantes/${idFirmante}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: datajs,
    };

    axios(config)
      .then((res) => {
        const data = res.data;

        console.log("modificacion de Gryop.", data);
        // alert("se registro con exito ");
      })
      .catch((error) => {
        alert("El campo signature_coords debe ser un array");
        console.error(error);
      });
  };

  const eliminarIdFimante = (data) => {
    let values = [];
    data.forEach((item) => {
      let object = {};

      object = {
        h: item.h,
        height: item.height,
        left: item.left,
        nombre: item.nombre,
        page: item.page,
        screenX: item.screenX,
        screenY: item.screenY,
        top: item.top,
        w: item.w,
        width: item.width,
        x: item.x,
        y: item.y,
      };
      values.push(object);
    });
    return values;
  };

  function validarMail(email) {
    let tokenSG =
      "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";
    var config = {
      async: true,
      crossDomain: true,
      method: "post",
      url: `https://api.sendgrid.com/v3/validations/email`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenSG,
      },
      processData: false,
      data: `{\"email\":\"${email}\",\"source\":\"signup\"}`,
    };
    axios(config)
      .then(function (response) {
        console.log("Email Validation:", response);
        if (response.data.result.verdict === "Invalid") {
          setTextoValidacionMail("Correo no válido");
        } else {
          setTextoValidacionMail("");
        }
        //verdict: "Invalid"
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //LEMP TIPOS DE ACTUACIÓN
  function getTiposActuacion() {
    const token = `${process.env.REACT_APP_TOKEN}`;
    axios
      .get(`${process.env.REACT_APP_URL}api/firmantes/involvement/types`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Tipos:", response.data);
        setTiposActuacion(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleChangeTipoActuacion = (event) => {
    var t = [];
    Object.keys(tiposActuacion).forEach(function (n, i) {
      t[i] = {
        codigo: n,
        valor: tiposActuacion[n],
      };
    });
    const tipo = t.find((ta) => ta.codigo === event.target.value);
    console.log("tipo12", tipo);
    setTipoActuacion(tipo);
  };
  function SelectTipos() {
    // console.log("TIPOSACTI", tiposActuacion);
    var t = [];
    Object.keys(tiposActuacion).forEach(function (n, i) {
      t[i] = {
        codigo: n,
        valor: tiposActuacion[n],
      };
    });
    // console.log("Tipos", t);
    return (
      <React.Fragment>
        <InputLabel id="demo-simple-select-label">Tipo de Actuación</InputLabel>
        <Select
          labelId="tipoactuacion"
          id="tipoactuacion"
          value={tipoActuacion.codigo}
          onChange={handleChangeTipoActuacion}
        >
          {t.map(({ codigo, valor }, index) => (
            <MenuItem key={index} value={codigo}>
              {valor}
            </MenuItem>
          ))}
        </Select>
      </React.Fragment>
    );
  }

  function handleInputChange(event, value) {
    //console.log(value);
    setDatos({
      ...datos,
      correo: value,
    });
  }

  function b(email) {
    for (var i = datosFirmantes.length; i--; ) {
      if (datosFirmantes[i].email === email) {
        return datosFirmantes[i];
      }
    }
    return null;
  }

  const FiltrarFirmantesCompany = (event, value) => {
    console.log("entro", value);
    let token = localStorage.getItem("Token");

    var config = {
      method: "get",
      //url: `${process.env.REACT_APP_URL}api/firmantes`,
      url: `${process.env.REACT_APP_URL}api/firmantesfrecuentes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        /*
        //se filtra la respuesta eliminado duplicados
        let arr = response.data;
        if (arr) {
          let pp = arr.filter(
            (ele, ind) =>
              ind === arr.findIndex((elem) => elem.email === ele.email)
          );
          console.log(pp);
          setDatosFirmantes(pp);
*/
        console.log("Firmantes previos:");
        const filtro = response.data.filter((item) => item.company == value);

        console.log("FILTRO", filtro);
        setDatosFirmantes(filtro);
        const okcompany = handleItemCompany(response.data);
        const dataArraCompany = new Set(okcompany);
        let result = [...dataArraCompany];
        setCompanyFrecuente(result);
        console.log("OKSCOMPAN", result);
        setFirmantesPrevios(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChange = (event, value) => {
    if (value) {
      var existente = b(value.email);
      console.log("existente", existente);

      if (existente) {
        //validación temporal teléfono mientras se envía código en campo separado
        var codigo;
        var tlf = "";
        if (existente.phone) {
          if (existente.phone === "") {
            codigo = "52";
          } else {
            if (existente.phone.substring(0, 1) === "1") {
              codigo = "1";
              tlf = existente.phone.slice(1);
            } else {
              codigo = "52";
              if (existente.phone.substring(0, 2) === "52") {
                tlf = existente.phone.slice(2);
              } else {
                tlf = existente.phone;
              }
            }
          }
          if (existente.phone_country_code !== "") {
            codigo = existente.phone_country_code;
          }
          console.log("cel:", existente.phone.substring(0, 1));
          console.log("codigo:", codigo);
          console.log("Tlf:", tlf);
        } else {
          codigo = "52";
        }

        //fin validar teléfono
        setDatos({
          ...datos,
          nombre: existente.name,
          apellidos: existente.last_name,
          correo: existente.email,
          codigo: codigo,
          celular: tlf,
          rol: existente.role,
          Compañia: existente.company,
        });

        setTipoActuacion({
          codigo: existente.firmante_involvements_id,
          valor: existente.role,
        });
        setValue(existente.notification_types_id.toString());
      } else {
        setDatos({
          ...datos,
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "",
          Compañia: "",
        });
      }
    } else {
      setDatos({
        ...datos,
        nombre: "",
        apellidos: "",
        correo: "",
        codigo: "52",
        celular: "",
        rol: "",
        Compañia: "",
      });
    }
  };

  const handleChangeVida = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeVidaModificar = (event) => {
    setDatosModificar({
      ...datosModificar,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChange = async (event) => {
    await setValue(event.target.value);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenstate}`,
      Accept: " application/json",
    },
  };

  var areYouReallySure = false;
  function areYouSure() {
    if (allowPrompt) {
      if (!areYouReallySure && true) {
        areYouReallySure = true;
        var confMessage = "Por favor termine de registrar el firmante ";

        return confMessage;
      }
    } else {
      allowPrompt = true;
    }
  }

  var allowPrompt = true;

  window.onbeforeunload = areYouSure;

  const handleGoBackHome = () => {
    let uri = localStorage.getItem("uri");
    history.push(`/${uri}/dashboard`);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const registerNewFirmante = async () => {
    console.log("entroFirmante");
    if (firmantesPrevios) {
      myRef.current
        .getElementsByClassName("MuiAutocomplete-clearIndicator")[0]
        .click();
      RefCompany.current
        .getElementsByClassName("MuiAutocomplete-clearIndicator")[0]
        .click();
    }

    const actuacionDefault = {
      codigo: 1,
      valor: "Por su Propio Derecho",
    };
    var requieredNum = 0;
    if (idRequired == true) {
      requieredNum = 1;
    }
    //nuevo LEMP usando JSON
    //AGREGAR OTRO FIRMANTE

    setNuevoFirmante(true);

    var rol = datos.rol;

    console.log("valorROL", rol);
    if (isObjEmpty(rol) === true) {
      rol = actuacionDefault.valor;
    }

    function isObjEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) return false;
      }

      return true;
    }

    const json = JSON.stringify(coordenadasFirma);
    // const data = `{\n    "documentos_id": "${idDocumento}",\n    "name": "${datos.nombre}",\n    "last_name": "${datos.apellidos}",\n    "email": "${datos.correo}",\n    "phone_country_code": "${datos.codigo}",\n    "phone": "${datos.celular}",\n    "role": "${rol}",\n    "firmante_involvements_id": "${tipoActuacion.codigo}",\n    "company": "${datos.Compañia}",\n    "notification_types_id":${value},\n    "id_required":${idRequired},\n  `;

    const data = new FormData();

    var speech = 0;

    if (state.checkedVida == true) {
      speech = 1;
    }

    var datajs = {};

    if (datos.celular) {
      if (datos.celular.length === 10) {
        datajs = JSON.stringify({
          documentos_id: idDocumento,
          name: datos.nombre,
          last_name: datos.apellidos,
          email: datos.correo,
          phone_country_code: datos.codigo,
          phone: datos.celular,
          role: rol,
          firmante_involvements_id: tipoActuacion.codigo,
          company: datos.Compañia,
          notification_types_id: value,
          id_required: idRequired,
          speech_required: speech,
        });
      } else if (datos.celular.length == 0) {
        datajs = JSON.stringify({
          documentos_id: idDocumento,
          name: datos.nombre,
          last_name: datos.apellidos,
          email: datos.correo,
          role: rol,
          firmante_involvements_id: tipoActuacion.codigo,
          company: datos.Compañia,
          notification_types_id: value,
          id_required: idRequired,
          speech_required: speech,
        });
      }
    } else {
      datajs = JSON.stringify({
        documentos_id: idDocumento,
        name: datos.nombre,
        last_name: datos.apellidos,
        email: datos.correo,
        role: rol,
        firmante_involvements_id: tipoActuacion.codigo,
        company: datos.Compañia,
        notification_types_id: value,
        id_required: idRequired,
        speech_required: speech,
      });
    }

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/firmantes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${tokenstate}`,
      },
      data: datajs,
    };

    //    console.log("coordenadas y agregar otro firmante");
    //    console.log(coordenadasFirma);
    console.log("DATAbodyfirmante", data);

    axios(config)
      .then((response) => {
        setNumFirmante(numFirmante + 1);
        // Respuesta del servidor
        let data = response.data[1];
        setDisplaySign(false);
        //agregar id al registro de firmante

        saveArrayFirmantes(data._id, data, tipoActuacion.codigo);
        setDatos({
          ...datos,
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "",
          Compañia: "",
        });
        setCoordenadasFirma([{}]);
      })
      .catch((error) => {
        // Error 😨
        if (error.response.data.message) {
          setSnackData({
            openSnackError: true,
            snackError: error,
          });
          // showNotificacion(error.response.data.message);
        } else if (error.response.data.errors.email) {
          showNotificacion(error.response.data.errors.email);
        } else if (error.response.data.errors.phone) {
          showNotificacion(error.response.data.errors.phone);
        } else if (error.response.data.message) {
          showNotificacion(error.response.data.message);
        }
      });
  };

  const saveArrayFirmantes = (id, data, tipoActuacion) => {
    const collection = [...arrayFirmantes];

    console.log("DATASave", data);
    var firmante = {
      nombre: datos.nombre,
      apellidos: datos.apellidos,
      correo: datos.correo,
      codigo: datos.codigo,
      celular: datos.celular,
      rol: datos.rol,
      Compañia: datos.Compañia,
      idFirmante: id,
      notification_types_id: data.notification_types_id,
      involvement_id: tipoActuacion,
      speech_required: data.speech_required,
    };

    collection.push(firmante);
    setArrayFirmantes(collection);
  };

  const handlePlaceSign = (value) => {
    console.log("VALUEPOSITION", value.idFirmante);
    setIdFirmante(value.idFirmante);
    setNameFirmante(value.nombre);
    setDisplaySign(true);
  };

  const deleteFirmantes = (value) => {
    console.log("DELETEVALUE", value.idFirmante);
    const UrlDeleteFirmante = `${process.env.REACT_APP_URL}api/firmantes/${value.idFirmante}`;
    axios
      .delete(UrlDeleteFirmante, {
        headers: {
          Authorization: `Bearer ${tokenstate}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log("DELETEVALUE", value.idFirmante);
        console.log("HISTORIASFIRMAS", ubicacionFirmaHistoria);

        const firmantesFiltradosDelete = ubicacionFirmaHistoria.filter(
          (x) => x.idFirmante == value.idFirmante
        );
        console.log("FIRMASBORRADAS", firmantesFiltradosDelete);

        var data3 = [];

        ubicacionFirmaHistoria.forEach((item) => {
          var elemento_en_data2 = firmantesFiltradosDelete.find(
            (element) => element == item
          );
          if (elemento_en_data2 === undefined) {
            data3.push(item);
          }
        });

        setUbicacionFirmaHistoria(data3);

        console.log("respuest a data", response);
        const collection = [...arrayFirmantes];

        const currentIndex = collection.indexOf(value);
        console.log("valorfirmante", currentIndex);
        collection.splice(currentIndex, 1);
        setArrayFirmantes(collection);
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          // console.log(error.response.data.message);
          //  openAlert(error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };

  const handleInitProcess = () => {
    var sync_signState = 0;
    if (valueNotificaciones == "2") {
      sync_signState = 1;
      const URL = `https://api.con-certeza.mx/api/documentos/${idDocumento}?sync_sign=1`;

      let data = {
        sync_sign: sync_signState,
      };

      let token = localStorage.getItem("Token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: " application/json",
        },
      };
      axios
        .put(URL, data, config)
        .then((res) => {
          const data = res.data;

          // console.log("MODIFDOCU.", data);
        })
        .catch((error) => {
          alert("se produjo un error ");
          console.error(error);
        });
    }

    setprogress(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenstate}`,
        Accept: " application/json",
      },
    };

    const urlInit = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}/launch`;
    axios.put(urlInit, {}, config).then((response) => {
      console.log("RES DE INIT", response);
      setprogress(false);
      senFirmante();
      setTimeout(handleGoBackHome, 10500);

      // setTimeout(() => {
      //   let ownLocation = history.location.pathname;
      //   console.log(ownLocation, "OWNLOCATION");
      //   if (ownLocation == "/cdo/dashboard") {
      //     setTimeout(handleGoBackHome, 5500);
      //   }
      // }, 5000);
    });
  };
  function esNumero(text) {
    if (text) {
      var reg = new RegExp("[0-9]+$");
      if (reg.test(text)) {
        return reg.test(text);
      } else {
        return false;
      }
    }
    return false;
  }

  const removerCaracteres = (event) => {
    let phone = event.target.value;
    if (phone) {
      let wordArray = phone.split("");
      let result = "";
      if (wordArray) {
        wordArray.map((item, i) => {
          if (esNumero(wordArray[i])) {
            result += wordArray[i];
          }
        });
        return result;
      } else {
        return false;
      }
    }
    return false;
  };

  const handleInput = (event) => {
    if (event.target.name == "celular") {
      let resultado = removerCaracteres(event);
      setDatos({
        ...datos,
        [event.target.name]: !resultado ? "" : resultado,
      });
      return false;
    }
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
    // console.log("datos", datos);
    // console.log("radios", value);
  };

  let topmt = 332;

  if (idRequired == true) {
    topmt = 300;
  } else {
    topmt = 332;
  }

  // Hooks de efecto para las variables que dependan de acciones al momento de cambiarse para el formulario y validacion de campos para habilitacion de boton.
  useEffect(() => {
    let esCorreo = validator.isEmail(datos.correo);
    // let disabledFirmante = true;
    if (value == 2) {
      setobligatorioCelular(true);
      // obligatorioCelular = true;
      if (datos.celular == undefined) {
        setdisabledFirmante(true);
        // disabledFirmante = true;
      } else if (
        datos.correo.length >= 5 &&
        datos.nombre.length >= 1 &&
        datos.apellidos.length >= 1 &&
        datos.Compañia.length >= 2 &&
        esCorreo &&
        datos.celular.length == 10
      ) {
        setdisabledFirmante(false);
        // disabledFirmante = false;
      } else {
        setdisabledFirmante(true);
      }
    } else if (value == 1) {
      if (
        datos.correo.length >= 3 &&
        datos.nombre.length >= 1 &&
        datos.apellidos.length >= 1 &&
        datos.Compañia.length >= 2 &&
        esCorreo
      ) {
        setdisabledFirmante(false);
        // disabledFirmante = false;
        setobligatorioCelular(false);
        // obligatorioCelular = false;
      } else {
        setdisabledFirmante(true);
      }
    }
  }, [value, obligatorioCelular, datos]);

  useEffect(() => {
    let esCorreo = validator.isEmail(datosModificar.correo);
    // let disabledModificar = true;
    // let obliCelularModificar = false;
    if (valueModificar == 2) {
      // obliCelularModificar = true;
      setobliCelularModificar(true);
      if (datosModificar.celular == undefined) {
        // disabledModificar = true;
        setdisabledModificar(true);
      } else if (
        datosModificar &&
        datosModificar.correo.length >= 3 &&
        datosModificar.nombre.length >= 1 &&
        datosModificar.rol.length >= 1 &&
        datosModificar.apellidos.length >= 1 &&
        datosModificar.Compañia.length >= 2 &&
        datosModificar.celular.length == 10 &&
        esCorreo
      ) {
        // disabledModificar = false;
        setdisabledModificar(false);
      } else {
        setdisabledModificar(true);
      }
    } else if (valueModificar == 1) {
      if (
        datosModificar.correo.length >= 3 &&
        datosModificar.nombre.length >= 1 &&
        datosModificar.apellidos.length >= 1 &&
        datosModificar.Compañia.length >= 2 &&
        esCorreo
      ) {
        // disabledModificar = false;
        setdisabledModificar(false);
        // obliCelularModificar = false;
        setobliCelularModificar(false);
      } else {
        setdisabledModificar(true);
      }
    }
  }, [valueModificar, obliCelularModificar, datosModificar]);

  Array.prototype.unicos = function () {
    return this.filter((valor, indice) => {
      return this.indexOf(valor) === indice;
    });
  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  var idUnicos = arrayIDFirmante.filter(onlyUnique);

  console.log("numerounicos", idUnicos);

  if (idUnicos) {
    // console.log("idFirmnatenunics", arrayIDFirmante);
  }

  let valuesresultado = [];
  idUnicos.forEach((item) => {
    const resultado = ubicacionFirmaHistoria.find(
      (element) => element.idFirmante === item
    );

    if (resultado !== undefined) {
      valuesresultado.push(resultado);
    }
  });

  let disableform = true;

  let firmantesSinFirmar = [];

  arrayFirmantes.forEach((item) => {
    if (item.date_signed) {
    } else {
      firmantesSinFirmar.push(item);
    }
  });

  console.log("firmantesSinFirmar", firmantesSinFirmar);
  if (
    valuesresultado.length === firmantesSinFirmar.length &&
    arrayFirmantes.length > 0
  ) {
    disableform = false;
  }
  // idUnicos.forEach((item) => {
  //   var elemento_en_data2 = ubicacionFirmaHistoria.find(
  //     (element) => element == item
  //   );
  //   console.log("elemento_en_data2", elemento_en_data2);
  // });

  const handleOpenEditar = (value) => {
    setIdFirmante(value.idFirmante);
    setModalEditarFirmante(true);
    var codigo;
    if (value.codigo) {
      codigo = value.codigo;
    } else {
      codigo = "52";
    }
    setDatosModificar({
      nombre: value.nombre,
      apellidos: value.apellidos,
      correo: value.correo,
      codigo: codigo,
      celular: value.celular,
      rol: value.rol,
      Compañia: value.Compañia,
      speech_required: value.speech_required,
    });
    setTipoActuacion({
      codigo: value.involvement_id,
      valor: value.rol,
    });

    setValueModificar(value.notification_types_id.toString());

    //setModalEliminarFirmante(true);
  };

  const handleInputModificar = (event) => {
    if (event.target.name == "celular") {
      let resultado = removerCaracteres(event);
      setDatosModificar({
        ...datosModificar,
        [event.target.name]: !resultado ? "" : resultado,
      });
      return false;
    }
    setDatosModificar({
      ...datosModificar,
      [event.target.name]: event.target.value,
    });
  };
  const editFirmante = async () => {
    var rol = datosModificar.rol;
    if (tipoActuacion.codigo !== 5) {
      rol = tipoActuacion.valor;
    }

    const datajsModi = JSON.stringify({
      name: datosModificar.nombre,
      last_name: datosModificar.apellidos,
      email: datosModificar.correo,
      phone_country_code: datosModificar.codigo,
      phone: datosModificar.celular,
      role: rol,
      firmante_involvements_id: tipoActuacion.codigo,
      company: datosModificar.Compañia,
      notification_types_id: valueModificar,

      speech_required: datosModificar.speech_required,
    });

    let token = localStorage.getItem("Token");

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_URL}api/firmantes/${idFirmante}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: datajsModi,
    };

    axios(config)
      .then((response) => {
        // Respuesta del servidor
        let data = response.data;
        setModalEditarFirmante(false);
        setDatos({
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "Por su Propio Derecho",
          Compañia: "",
        });

        datafirmantes();
        setArrayFirmantes([]);
        setDatosModificar({
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "Por su Propio Derecho",
          Compañia: "",
        });
      })
      .catch((error) => {
        // Error 😨

        if (error.response.data.length > 1) {
          showNotificacion(error.response.data[0].message);
        } else {
          if (error.response.data.errors.email) {
            showNotificacion(error.response.data.errors.email);
          } else if (error.response.data.errors.phone) {
            showNotificacion(error.response.data.errors.phone);
          } else if (error.response.data.message) {
            showNotificacion(error.response.data.message);
          }
        }
      });
  };

  return (
    <React.Fragment>
      <Grid container component="main" className={classes.root}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          component={Paper}
          elevation={6}
          square
          className={classes.container}
        >
          <UbicarFirma
            idDocumento={idDocumento}
            nombre={nameFirmante}
            setCoordenadasFirma={setCoordenadasFirma}
            setNuevoFirmante={setNuevoFirmante}
            nuevoFirmante={nuevoFirmante}
            topmt={topmt}
            displaySign={displaySign}
            setDisplaySign={setDisplaySign}
            idFirmante={idFirmante}
            arrayFirmantes={arrayFirmantes}
            clickFijar={clickFijar}
            setUbicacionFirmaHistoria={setUbicacionFirmaHistoria}
            ubicacionFirmaHistoria={ubicacionFirmaHistoria}
            firmaYaHechas={firmaYaHechas}
            setCoordenadas={setCoordenadas}
          />
          <hr />

          {/* <Button
            variant="contained"
            color="primary"
            disabled={false}
            onClick={() => {
              console.log("clic notificados");
              setModalNotificados(true);
            }}
            className={buttonClassname}
          >
            Notificados
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={disableform}
            onClick={registerNewFirmante}
            className={buttonClassname}
          >
            Registrar otro firmante
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={disableform}
            onClick={registerFirmante}
            className={buttonClassname}
          >
            mandar a firmar
          </Button> */}
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          className={classes.container}
        >
          <Grid>
            <Typography
              variant="h6"
              gutterBottom
              style={{ textTransform: "none" }}
            >
              REGISTRO DE FIRMANTES
            </Typography>
          </Grid>
          <Grid>
            <Typography
              variant="h6"
              gutterBottom
              style={{ textTransform: "none" }}
            >
              Datos de Firmante #{arrayFirmantes.length + 1}
            </Typography>
          </Grid>

          <Grid container spacing={2} ref={ref}>
            <Grid item xs={12} sm={12}>
              {firmantesPrevios ? (
                <Autocomplete
                  ref={myRef}
                  required
                  id="correo"
                  name="correo"
                  freeSolo
                  options={datosFirmantes}
                  getOptionLabel={(option) => option.email}
                  onInputChange={handleInputChange}
                  onChange={onChange}
                  onBlur={() => {
                    if (datos.correo) {
                      validarMail(datos.correo);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      margin="dense"
                      {...params}
                      label="Correo electrónico"
                    />
                  )}
                />
              ) : (
                <React.Fragment>
                  <TextField
                    required
                    margin="dense"
                    id="correo"
                    name="correo"
                    variant="outlined"
                    label="Correo electrónico"
                    value={datos.correo}
                    fullWidth
                    onChange={handleInput}
                    onBlur={() => {
                      if (datos.correo) {
                        validarMail(datos.correo);
                      }
                    }}
                    inputProps={{ maxLength: 60 }}
                    error={datos.correo.length > 2 ? false : true}
                  />
                </React.Fragment>
              )}
              <font color="red">{textoValidacionMail}</font>
            </Grid>
            <Grid item xs={3} sm={6}>
              <TextField
                required
                margin="dense"
                id="nombre"
                name="nombre"
                variant="outlined"
                label="Nombre"
                value={datos.nombre}
                fullWidth
                autoComplete="given-name"
                onChange={handleInput}
                inputProps={{ maxLength: 30 }}
                error={datos.nombre.length > 1 ? false : true}
              />
            </Grid>
            <Grid item xs={3} sm={6}>
              <TextField
                required
                id="apellidos"
                margin="dense"
                name="apellidos"
                variant="outlined"
                label="Apellidos"
                value={datos.apellidos}
                fullWidth
                autoComplete="family-name"
                onChange={handleInput}
                inputProps={{ maxLength: 60 }}
                error={datos.apellidos.length > 2 ? false : true}
              />
            </Grid>

            <Grid item xs={12} sm={3} className={classes.codigo}>
              <font color={estiloCelular} size="2">
                Código
              </font>
              <PhoneInput
                countryCodeEditable={false}
                inputStyle={{ width: "110%" }}
                inputExtraProps={{
                  name: "codigo",
                  id: "codigo",
                  required: true,
                  autoFocus: true,
                  readonly: true,
                }}
                readonly
                id="codigo"
                name="codigo"
                label="codigo"
                onlyCountries={["us", "mx"]}
                localization={{
                  "United States": "Estados Unidos",
                  Mexico: "México",
                }}
                country={datos.codigo === "1" ? "us" : "mx"}
                //country={"mx"}
                //value={datos.codigo}
                onChange={(phone) => {
                  setEstiloCelular("gray");
                  setDatos({
                    ...datos,
                    codigo: phone,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="celular"
                margin="dense"
                name="celular"
                variant="outlined"
                label={value == 2 ? "Celular *" : "Celular"}
                value={datos.celular}
                type={"text"}
                onChange={handleInput}
                inputProps={{ maxLength: 15 }}
                error={value == 2 && datos.celular.length < 10 ? true : false}
              />
              <FormHelperText style={{ color: "red" }}>
                {value == 2 && datos.celular.length < 10
                  ? "Registre el campo celular."
                  : ""}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={5}>
              {firmantesPrevios ? (
                <Autocomplete
                  ref={RefCompany}
                  required
                  id="company"
                  name="Compañia"
                  freeSolo
                  options={companyFrecuente}
                  getOptionLabel={(option) => option}
                  onInputChange={handleInput}
                  onChange={FiltrarFirmantesCompany}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} label="Empresa" />
                  )}
                />
              ) : (
                <TextField
                  required
                  id="Compañia"
                  variant="outlined"
                  name="Compañia"
                  label="Empresa"
                  value={datos.Compañia}
                  fullWidth
                  onChange={handleInput}
                  inputProps={{ maxLength: 60 }}
                  error={datos.Compañia.length > 2 ? false : true}
                />
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectTipos />
            </Grid>
            <Grid item xs={6} sm={6}>
              {
                tipoActuacion.codigo === "5" ? (
                  <TextField
                    required
                    margin="dense"
                    id="rol"
                    name="rol"
                    label="Tipo de actuación"
                    variant="outlined"
                    value={datos.rol}
                    fullWidth
                    onChange={handleInput}
                    inputProps={{ maxLength: 60 }}
                    error={datos.rol.length > 2 ? false : true}
                  />
                ) : null
                /*
        <TextField
          required
          id="rol"
          name="rol"
          label="Tipo de actuación"
          value={datos.rol}
          fullWidth
          onChange={handleInput}
          inputProps={{ maxLength: 60 }}
          error={datos.rol.length > 2 ? false : true}
        />
        */
              }
            </Grid>

            <Grid item xs={3} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Tipo de notificación</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radios"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    style={{ margin: -5 }}
                    value="1"
                    name="1"
                    control={<Radio />}
                    label="Correo electrónico"
                  />
                  <FormControlLabel
                    style={{ margin: -5 }}
                    value="2"
                    name="2"
                    control={<Radio />}
                    label="Mensaje de texto"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={3} sm={6}>
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedVida}
                      onChange={handleChangeVida}
                      name="checkedVida"
                      color="primary"
                    />
                  }
                  label="Solicitar prueba de vida"
                />
              </Grid>

              <ButtonC
                color="success"
                size="md"
                type="button"
                onClick={registerNewFirmante}
                className={classes.button}
                disabled={disabledFirmante}
              >
                agregar firmante
              </ButtonC>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={12}>
            <hr />
            <Grid>
              <Typography
                variant="h6"
                gutterBottom
                style={{ textTransform: "none" }}
              >
                FIRMANTES REGISTRADOS
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid component="label" container alignItems="center">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={valueNotificaciones}
                    onChange={handleChangeNotificacion}
                  >
                    <FormControlLabel
                      style={{ margin: -8 }}
                      value="1"
                      control={<Radio />}
                      label="Mandar a firmar en orden"
                    />
                    <FormControlLabel
                      style={{ margin: -8 }}
                      value="2"
                      control={<Radio />}
                      label="Mandar a firmar en paralelo"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <List dense className={classes.root}>
              {arrayFirmantes.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                arrayIDFirmante.push(value.idFirmante);

                const nombreCompleto = `${value.nombre} ${value.apellidos}`;
                return (
                  <ListItem key={value.name} button>
                    <ListItemAvatar>
                      <Tooltip
                        id="tooltip-top"
                        title={nombreCompleto}
                        placement="top"
                        classes={{
                          tooltip: classes2.tooltip,
                        }}
                      >
                        <Avatar
                          className={
                            value.date_signed
                              ? classesicon.orange
                              : classesicon.green
                          }
                        >
                          {value.nombre.charAt(0).toUpperCase()}
                          {value.apellidos.charAt(0).toUpperCase()}
                        </Avatar>
                      </Tooltip>
                    </ListItemAvatar>
                    <ListItemText
                      id={labelId}
                      primary={` ${nombreCompleto.substr(-35, 21) + "..."}`}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {idDocumentoDetenido
                              ? value.date_signed
                                ? "Ya ha firmado no se puede editar"
                                : "No ha firmado, puede editar"
                              : ""}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    {value.date_signed ? (
                      ""
                    ) : (
                      <ListItemSecondaryAction>
                        <Grid>
                          {value.speech_required == true ? (
                            <Tooltip
                              id="tooltip-top"
                              title="Se solicito prueba de vida"
                              placement="top"
                              classes={{
                                tooltip: classes2.tooltip,
                              }}
                            >
                              <IconButton
                                aria-label="Edit"
                                className={classes2.tableActionButton}
                              >
                                <RecordVoiceOverIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          <Tooltip
                            id="tooltip-top"
                            title="Editar Firmante"
                            placement="top"
                            classes={{
                              tooltip: classes2.tooltip,
                            }}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes2.tableActionButton}
                            >
                              <EditIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                                onClick={() => {
                                  handleOpenEditar(value);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            id="tooltip-top"
                            title="Fijar Firma"
                            placement="top"
                            classes={{
                              tooltip: classes2.tooltip,
                            }}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes2.tableActionButton}
                            >
                              <PhotoSizeSelectSmallIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                                onClick={() => {
                                  handlePlaceSign(value);
                                }}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Eliminar Firmante"
                            placement="top"
                            classes={{
                              tooltip: classes2.tooltip,
                            }}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes2.tableActionButton}
                            >
                              <DeleteIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                                onClick={() => {
                                  deleteFirmantes(value);
                                }}
                              />
                            </IconButton>
                          </Tooltip>

                          {/*boton para marcar como firmante frecuente*/}
                          <Tooltip
                            id="tooltip-top"
                            title="Agregar a Firmantes Frecuentes"
                            placement="top"
                            classes={{
                              tooltip: classes2.tooltip,
                            }}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes2.tableActionButton}
                            >
                              <StarIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                                onClick={() => {
                                  addFirmanteFrecuente(value);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          {/*fin boton para marcar como firmante frecuente*/}
                        </Grid>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={false}
                onClick={() => {
                  setModalNotificados(true);
                }}
              >
                <Typography variant="overline">Observadores</Typography>
              </Button>
            </Grid>
            <Grid item>
              <ButtonWithTooltip
                tooltipText="Asegúrate que cada uno de los firmantes tenga fijado un espacio de firma."
                onClick={handleInitProcess}
                disabled={disableform}
                variant="contained"
                color="primary"
              >
                <Typography variant="overline"> Enviar a firmar</Typography>
              </ButtonWithTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop className={classespro.backdrop} open={progress}>
        <CircularProgress size={44} className={classespro.buttonProgress} />
      </Backdrop>

      {/*dialogo modificar FIRMANTES*/}
      <Dialog
        open={modalEditarFirmante}
        maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          MODIFICAR FIRMANTE
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container spacing={2}>
              <Grid item xs={6} sm={12}>
                <TextField
                  required
                  id="correo"
                  name="correo"
                  variant="outlined"
                  label="Correo electrónico"
                  value={datosModificar.correo}
                  fullWidth
                  autoComplete="email"
                  onChange={handleInputModificar}
                  onBlur={() => {
                    if (datosModificar.correo) {
                      validarMail(datosModificar.correo);
                    }
                  }}
                  inputProps={{ maxLength: 60 }}
                  error={datosModificar.correo.length > 2 ? false : true}
                />
                <font color="red">{textoValidacionMail}</font>
              </Grid>
              <Grid item xs={3} sm={6}>
                <TextField
                  required
                  id="nombre"
                  name="nombre"
                  variant="outlined"
                  label="Nombre(s)"
                  value={datosModificar.nombre}
                  fullWidth
                  autoComplete="given-name"
                  onChange={handleInputModificar}
                  inputProps={{ maxLength: 30 }}
                  error={datosModificar.nombre.length > 1 ? false : true}
                />
              </Grid>
              <Grid item xs={3} sm={6}>
                <TextField
                  required
                  id="apellidos"
                  name="apellidos"
                  variant="outlined"
                  label="Apellido(s)"
                  value={datosModificar.apellidos}
                  fullWidth
                  autoComplete="family-name"
                  onChange={handleInputModificar}
                  inputProps={{ maxLength: 60 }}
                  error={datosModificar.apellidos.length > 2 ? false : true}
                />
              </Grid>

              <Grid item xs={12} sm={2} className={classes.codigo}>
                <font color={"gray"} size="2">
                  Código
                </font>
                <PhoneInput
                  countryCodeEditable={false}
                  inputStyle={{ width: "80%" }}
                  inputExtraProps={{
                    name: "codigo",
                    id: "codigo",
                    required: true,
                    autoFocus: true,
                    readonly: true,
                  }}
                  readonly
                  id="codigo"
                  name="codigo"
                  label="codigo"
                  onlyCountries={["us", "mx"]}
                  localization={{
                    "United States": "Estados Unidos",
                    Mexico: "México",
                  }}
                  country={datosModificar.codigo === "1" ? "us" : "mx"}
                  //value={datosModificar.codigo}
                  onChange={(phone) => {
                    //setEstiloCelular("gray");
                    setDatosModificar({
                      ...datosModificar,
                      codigo: phone,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required={false}
                  id="celular"
                  name="celular"
                  variant="outlined"
                  label={valueModificar == 2 ? "Celular *" : "Celular"}
                  type={"text"}
                  value={datosModificar.celular}
                  onChange={handleInputModificar}
                  inputProps={{ maxLength: 15 }}
                  autoComplete="celular"
                  error={
                    valueModificar == 2 && datosModificar.celular.length < 10
                      ? true
                      : false
                  }
                />
                <FormHelperText style={{ color: "red" }}>
                  {valueModificar == 2 && datosModificar.celular.length < 10
                    ? "Registre el campo celular."
                    : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="Compañia"
                  variant="outlined"
                  label={"Empresa"}
                  name="Compañia"
                  value={datosModificar.Compañia}
                  fullWidth
                  onChange={handleInputModificar}
                  inputProps={{ maxLength: 60 }}
                  error={datosModificar.Compañia.length > 2 ? false : true}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Tipo de notificación</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="radios"
                    value={valueModificar}
                    //onChange={handleChange}
                    onChange={(e) => {
                      setValueModificar(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="1"
                      name="1"
                      control={<Radio />}
                      label="Correo electrónico"
                    />
                    <FormControlLabel
                      value="2"
                      name="2"
                      control={<Radio />}
                      label="Mensaje de texto"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectTipos />
              </Grid>
              <Grid item xs={12} sm={4}>
                {tipoActuacion.codigo === "5" ? (
                  <TextField
                    required
                    id="rol"
                    name="rol"
                    variant="outlined"
                    label="Tipo de actuación"
                    value={datosModificar.rol}
                    fullWidth
                    onChange={handleInputModificar}
                    inputProps={{ maxLength: 60 }}
                    error={datosModificar.rol.length > 2 ? false : true}
                  />
                ) : null}

                <Grid>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={datosModificar.speech_required}
                        onChange={handleChangeVidaModificar}
                        name="speech_required"
                        color="primary"
                      />
                    }
                    label="Solicitar prueba de vida"
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonC
            onClick={() => {
              setModalEditarFirmante(false);
              setDatosModificar({
                nombre: "",
                apellidos: "",
                correo: "",
                codigo: "52",
                celular: "",
                rol: "Por su Propio Derecho",
                Compañia: "",
                grupo: "DEFAULT",
              });
            }}
            color="danger"
          >
            Cancelar
          </ButtonC>
          <ButtonC
            color="success"
            onClick={editFirmante}
            disabled={disabledModificar}
          >
            Modificar firmante
          </ButtonC>
        </DialogActions>
      </Dialog>
      {/*fin dialogo MODIFICAR*/}

      <MySnackbar
        openAlert={openSnackError}
        onClose={handleCloseSnack}
        error={snackError}
        duration={20000}
      />

      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />
      {/* alert succes */}
      <Snackbarlol
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSucces}
        autoHideDuration={6000}
        onClose={() => setOpenSucces(false)}
      >
        <Alert onClose={() => setOpenSucces(false)} severity="success">
          {messageSucces}
        </Alert>
      </Snackbarlol>

      {modalNotificados ? (
        <Notificados
          setModalNotificados={setModalNotificados}
          modalNotificados={modalNotificados}
          idDocumento={idDocumento}
        />
      ) : null}
    </React.Fragment>
  );
}
