import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Webcam from "react-webcam";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function WebcamStreamCapture(props) {
  const {
    resultadoVoice,
    handlestart,
    SetEscuchar,
    succes,
    recordedChunks,
    setRecordedChunks,
    SetResulado,
    dialogError,
    SetDialogError,
    numero,
    SetSucces,
    setRecording,
  } = props;
  const webcamRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [audio, setAudio] = React.useState(false);
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);

  // Promise.all([
  //   faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
  //   faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
  //   faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
  //   faceapi.nets.faceExpressionNet.loadFromUri("/models"),
  // ]).then(useEffect);

  // webcamRef.current.video.readyState.addEventListener("play", () => {
  //   const canvas = faceapi.createCanvasFromMedia(videoRef);
  //   document.body.append(canvas);
  //   const displaySize = { width: videoRef.width, height: videoRef.height };
  //   faceapi.matchDimensions(canvas, displaySize);
  //   setInterval(async () => {
  //     const detections = await faceapi
  //       .detectAllFaces(videoRef, new faceapi.TinyFaceDetectorOptions())
  //       .withFaceLandmarks()
  //       .withFaceExpressions();
  //     console.log("detections", detections);
  //     const resizedDetections = faceapi.resizeResults(detections, displaySize);
  //     canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
  //     faceapi.draw.drawDetections(canvas, resizedDetections);
  //     faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
  //     faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
  //   }, 100);
  // });
  React.useEffect(() => {
    if (succes == true) {
      handleStopCaptureClick();

      SetEscuchar(false);
      SetSucces(false);
    }
  }, [succes]);
  // React.useEffect(() => {
  //   navigator.mediaDevices.enumerateDevices().then(handleDevices);
  // }, [handleDevices]);

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  const handleStartCaptureClick = React.useCallback(() => {
    setRecording(true);
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setRecording(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "pruebavida.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  return (
    <div>
      {capturing ? (
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={() => {
            handleStopCaptureClick();
            SetEscuchar(false);
          }}
        >
          <Typography variant="h6">Detener</Typography>
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={() => {
            handleStartCaptureClick();
            SetEscuchar(true);
            SetResulado();
            setAudio(true);
            handlestart();
          }}
        >
          <Typography variant="h6">Grabar</Typography>
        </Button>
      )}
      <br />
      <br />
      <div>
        <Webcam
          audio={audio}
          muted="muted"
          ref={webcamRef}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: "90%",
            height: 480,
          }}
        />
      </div>

      {/* {recordedChunks.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={() => {
            handleDownload();
          }}
        >
          <Typography variant="h6">Descargar</Typography>
        </Button>
      )} */}

      <Dialog
        open={dialogError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          La prueba de vida no ha sido exitosa, por favor vuelve a intentarlo.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Verifica que el micrófono esté activo, habla fuerte y sin pausas.
            <center>
              <Typography variant="h6" style={{ textTransform: "none" }}>
                Resultado: {resultadoVoice}
              </Typography>
            </center>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              SetDialogError(false);
              numero();
              if (capturing) {
                handleStopCaptureClick();
              }
              SetEscuchar(false);
              SetResulado("");
            }}
            color="primary"
            autoFocus
          >
            Volver a intentar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// https://www.npmjs.com/package/react-webcam
