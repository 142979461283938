import React from "react";
import Avatar from "@material-ui/core/Avatar";
import SigUp from "assets/img/singUpEnter.svg";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import logoCDO from "assets/img/LOGOCDO.png";
import GridItem from "components/Grid/GridItem.js";
//log
import {
  saveLog,
  saveLogFail,
  saveLogCaptureActividad,
} from "containers/Logs/funcionesLog.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <img src={logoCDO} alt="Con-Certeza" width="30" height="35" />
      <GridItem>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Powered by "}
          <Link color="inherit" href="https://certificaciondigital.online/">
            CDO
          </Link>
        </Typography>
      </GridItem>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Register(props) {
  const { handleNext, dataChecbox, getDataGroup } = props;
  const classes = useStyles();
  const history = useHistory();
  const [openDialogEnter, setOpenDialogEnter] = React.useState(false);
  const [checkPrivacidad, setCheckPrivadad] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [dataPermision, setDataPermision] = React.useState();
  const [dataRegister, setDataRegister] = React.useState({
    nameCompany: "",
    name: "",
    description: "",
  });

  const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\d)(?=.*[@$!%*;.,?&])[A-Za-z\d@$!%*?;.,&]{6,16}$/;

  React.useEffect(() => {
    getPermisions();
  }, []);
  const getPermisions = () => {
    let token = localStorage.getItem("Token");

    const endpoint = `${process.env.REACT_APP_URL}api/permissions`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        console.log("LISTAROLES", resp.data);
        const itemdata = itemPermision(resp.data);
        setDataPermision(itemdata);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };
  var arrayCheck = [];
  const handleChangeCheck = (event) => {
    if (event.target.checked == true) {
      dataChecbox.push(event.target.value);
      console.log("valorarray", dataChecbox);
    } else {
      var indice = dataChecbox.indexOf(event.target.value);
      dataChecbox.splice(indice, 1);
      console.log("VALORREPETIDO", dataChecbox);
    }
  };

  const itemPermision = (data) => {
    const values = data.map((item) => [
      <FormControl component="fieldset">
        <FormGroup row>
          <FormControlLabel
            value={item.id}
            control={<Checkbox color="primary" />}
            label={item.name}
            onChange={handleChangeCheck}
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>,
    ]);
    return values;
  };

  // alert functions

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const registerCompany = () => {
    let token = localStorage.getItem("Token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };
    const URL = `${process.env.REACT_APP_URL}api/roles/group`;
    const data = new FormData();
    data.append("name", dataRegister.name);
    data.append("description", dataRegister.description);

    axios
      .post(URL, data, config)
      .then((res) => {
        console.log("datarespuesta", res);
        const data = res.data;
        console.log("dataregistergruop", data.role.id);
        setTimeout(function () {
          modificarAddCompany(data.role.id);
        }, 2000);
      })
      .catch((error) => {
        alert("se produjo un error al registrar el grupo intentelo mas tarde");
        console.error(error);
      });
  };

  const modificarAddCompany = (idrol) => {
    let token = localStorage.getItem("Token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };
    const URL = `${process.env.REACT_APP_URL}api/roles/${idrol}`;

    var dataarray = [
      {
        company: dataRegister.nameCompany,
      },
    ];
    const data = new FormData();
    data.append("custom[]", dataarray);

    axios
      .put(URL, data, config)
      .then((res) => {
        const data = res.data;
        getDataGroup();
        console.log("modificacion de Gryop.", data);
        alert("se modifico con exito ");
      })
      .catch((error) => {
        alert("se produjo un error ");
        console.error(error);
      });
  };

  const handlesuccesSignUp = () => {
    setOpenDialogEnter(false);
    window.location.reload();
  };

  const handleInput = (event) => {
    setDataRegister({
      ...dataRegister,
      [event.target.name]: event.target.value,
    });
  };

  let disableform = true;
  if (
    dataRegister.name.length >= 3 &&
    dataRegister.description.length >= 5 &&
    dataRegister.nameCompany.length >= 5
  ) {
    disableform = false;
  }
  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <GroupAddIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrar compañia
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={12}>
              <TextField
                autoComplete="name"
                required
                name="nameCompany"
                variant="outlined"
                fullWidth
                id="nameCompany"
                value={dataRegister.nameCompany}
                onChange={handleInput}
                label="Nombre de la compañia"
                autoFocus
              />
            </Grid>

            <Typography variant="subtitle1" gutterBottom>
              Registra un grupo
            </Typography>

            <Grid item xs={6} sm={12}>
              <TextField
                autoComplete="name"
                required
                name="name"
                variant="outlined"
                fullWidth
                id="name"
                value={dataRegister.name}
                onChange={handleInput}
                label="Nombre del grupo"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="description"
                value={dataRegister.description}
                onChange={handleInput}
                label="Descripción del grupo"
                name="description"
              />
            </Grid>
          </Grid>
          <br />
          {/* <Grid item xs={12} align="center">
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ textTransform: "none" }}
            >
              Selecciona los permisos para el grupo
            </Typography>
          </Grid> */}
          {/* <Grid>{dataPermision}</Grid> */}
          <Grid item xs={6} align="center"></Grid>
          <Button
            onClick={registerCompany}
            fullWidth
            disabled={disableform}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Registrar
          </Button>
        </form>
      </div>

      <div className={classes.root}>
        <Snackbar
          open={openSnack}
          autoHideDuration={9000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {messageError}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
}
