import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlPanel from "components/controlPanel/ControlPanel";
import axios from "axios";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { isMobile } from "react-device-detect";


export default function VistaPrevia(props) {


//pdf
const [base64, setbase64] = React.useState("");
const [numPages, setNumPages] = React.useState(null);
const [pageNumber, setPageNumber] = React.useState(1);
const [pdfHeigth, setpdfHeigth] = React.useState(475);
const [pdfWidth, setpdfWidth] = React.useState(550);
const [marginTop, setMarginTop] = React.useState(700);
const [scale, setScale] = React.useState(0.8);

function changePage(offset) {
  setPageNumber((prevPageNumber) => prevPageNumber + offset);
}

function previousPage() {
  changePage(-1);
}

function nextPage() {
  changePage(1);
}

function onDocumentLoadSuccess({ numPages }) {
  setNumPages(numPages);
  setPageNumber(1);
}


//pdf


  React.useEffect(() => {
    if (isMobile) {
      setpdfHeigth(500);
      setpdfWidth(290);
    }

    //console.log("imagesPDFVistaPrevia",props.imagesPDF);

    const token = `${process.env.REACT_APP_TOKEN}`;
    //let token = localStorage.getItem("Token");

    let url = `${process.env.REACT_APP_URL}api/documentos/crear`; 
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };

    //var data=`{'texto':${props.texto},'anexos':${JSON.stringify(props.images)}}`;

    const data = new FormData();
    data.append("texto", props.texto);
    data.append("anexos", JSON.stringify(props.images));

    axios
      .post(url, data, config, {
      })
      .then((response) => {
        // Respuesta del servidor
        console.log("Resp:",response);

        let archivo=`data:application/pdf;base64,${response.data}`;
        setbase64(archivo);
        props.setbase64Principal(archivo);


      })
      .catch((error) => {
        // Error 😨
        alert(error.response.data);
      });

  }, []);

  return (
    <React.Fragment>
    {base64.length === 0 ? (
      <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
      }}>
        <CircularProgress />
      </div>
    ) : (
      <div align="center">
      <div className="text-center">
        <p>
          Página {pageNumber || (numPages ? 1 : "--")} de{" "}
          {numPages || "--"}
        </p>
        <button
          className="btn btn-dark"
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Atrás
        </button>{" "}
        <button
          className="btn btn-dark"
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Siguiente
        </button>
      </div>
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file={base64}
        />
        <Document
          error="Error al intentar cargar el PDF"
          loading="Cargando..."
          file={base64}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            scale={scale}
            height={pdfHeigth}
            width={pdfWidth}
            renderTextLayer={false}
            pageNumber={pageNumber}
          />
        </Document>


      </div>
    )}
    </React.Fragment>
  )

}
