import axios from "axios";
import {
  browserName,
  osVersion,
  osName,
  mobileVendor,
  mobileModel,
  browserVersion,
  deviceType,
  deviceDetect,
} from "react-device-detect";

export function saveLog(token, actividad) {
  console.log("LOGIN LOG START");
  let user, ip, info;

  //get user
  axios
    .get(`${process.env.REACT_APP_URL}api/auth/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const data = res.data;
      // console.log("DATAUSER", data.user);
      user = data.user;
      //get browser info
      info = {
        timeOpened: new Date(),
        timezone: new Date().getTimezoneOffset() / 60,
        browser: browserName,
        browserVersion: browserVersion,
        deviceType: deviceType,
        osName: osName,
        osVersion: osVersion,
        mobileVendor: mobileVendor,
        mobileModel: mobileModel,
        pageon: window.location.pathname,
        referrer: document.referrer,
        previousSites: window.history.length,
        browserName: window.navigator.appName,
        browserEngine: window.navigator.product,
        browserVersion1a: window.navigator.appVersion,
        browserVersion1b: navigator.userAgent,
        browserLanguage: navigator.language,
        browserOnline: navigator.onLine,
        browserPlatform: navigator.platform,
        javaEnabled: navigator.javaEnabled(),
        dataCookiesEnabled: navigator.cookieEnabled,
        dataCookies1: document.cookie,
        dataCookies2: decodeURIComponent(document.cookie.split(";")),
        dataStorage: localStorage,
        sizeScreenW: window.screen.width,
        sizeScreenH: window.screen.height,
        sizeDocW: window.document.width,
        sizeDocH: window.document.height,
        sizeInW: window.innerWidth,
        sizeInH: window.innerHeight,
        sizeAvailW: window.screen.availWidth,
        sizeAvailH: window.screen.availHeight,
        scrColorDepth: window.screen.colorDepth,
        scrPixelDepth: window.screen.pixelDepth,
      };
      // console.log("INFO:",info);
      //fin info
      //get ip
      axios.get(`https://geolocation-db.com/json/`).then((res) => {
        console.log("IP:", res.data);
        ip = res.data;
        //saveLog
        if (user && ip) {
          let url = `${process.env.REACT_APP_URL_DEV}api/logs`;
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
              Accept: " application/json",
            },
          };

          var data = {
            user: user,
            actividad: actividad,
            dataBrowser: info,
            ip: ip,
          };
          axios
            .post(url, data, config, {
              // receive two parameter endpoint url ,form data
            })
            .then((response) => {
              // Respuesta del servidor
              console.log(response);
              console.log("LOGIN LOG SAVED");
            })
            .catch((error) => {
              // Error 😨
              if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log(error.response.data.message);
                alert(error.response.data.message);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                // Something happened in setting up the request and triggered an Error
                console.log("Error", error.message);
              }
              console.log(error.config);
            });
        }
        //fin saveLog
      });
      //fin get ip
    })
    .catch((error) => {
      console.error(error);
    });
  //fin get user

  console.log("LOGIN LOG END");
}

export function saveLogCapture(token, actividad) {
  //cambiar para produccion
  //eliminar token fijo y cambiar parámetro de la función a token
  //let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjBmMmE2N2RlNzhhZWQwNjlkYjc3YzM0MjM4MWRkZWMwYmUxZDc0ODk2NjM1ZGZjNjE3NDRlYzg2ZmM2MjZhYjBlMDI3YTUxNjUxODc0ZmIiLCJpYXQiOjE2MTYwMTcxNzksIm5iZiI6MTYxNjAxNzE3OSwiZXhwIjoxNjE2MTAzNTc5LCJzdWIiOiI2Iiwic2NvcGVzIjpbXX0.lW6yB6bJGDEYeTlVHc-uj54a-qYiTFP7KZtdRJmYqeK6sGrpo-BetaaXJN_ANlom3MRDqhLh0NNDupgDJ_7YutWw6l9-lRr1eXw9FYPXM1CDik5YYaiwI2sDUAOMFoG2PSZUXU9DYFkfHsCvcDG9JsdYnqUzsYQ37SoBWySHfa9HESvX5-2aNdEa7YNPVaDPtd5pMGQp91PFlHxLv7L8qpLs0JKqbsgRh3r7iycXmemJ9UMlDM14Sv3YfZ4uHAbuGtN1SoJzhQZbSe35hnSbyD5Gkeam8i3WtEL36bYTNyh5cSEVMcubERFydtlFfIv5OLqyZNzsobT6yDs6HAQ7zgDI5FDnsINsbVdvWA6Fl0Hja7FP2G4k78_3UpjcMkBTYb8T_VTAdRqM5ouewQD8FBQSvsKAOEd6To6NP6_YImZlwlDIeH1IT0zQ91jnWGsl3qXpNkB0SAKNygHK9ULhIKP6E64ph33415stPIzgkFwPS8XPWDQ32Kprdy_JdTzURMSHwpRLHZBQC14Zcsrwxovyb1wfimFb2fgt2Mp0mH5bgSqkRn_nQ7GwXXNwXzn0oYjaLX6tz49ijyNcEwjsOBhsPDNZZqgBlCrCdZoxE-4oRCJkXoC2_fg6M3DHXEW-KYsU_ovhoSDiWuenFwGU3fZqz7fCCiiRzGh-adk6h-8";
  console.log("logcapture START");
  let user, ip, info;

  //get user
  axios
    //cambias para produccion
    .get(`${process.env.REACT_APP_URL}api/auth/user`, {
      //.get(`${process.env.REACT_APP_URL_DEV}api/auth/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const data = res.data;
      console.log("DATAUSER", data.user);
      user = data.user;
      //get browser info
      info = {
        timeOpened: new Date(),
        timezone: new Date().getTimezoneOffset() / 60,
        browser: browserName,
        browserVersion: browserVersion,
        deviceType: deviceType,
        osName: osName,
        osVersion: osVersion,
        mobileVendor: mobileVendor,
        mobileModel: mobileModel,
        pageon: window.location.pathname,
        referrer: document.referrer,
        previousSites: window.history.length,
        browserName: window.navigator.appName,
        browserEngine: window.navigator.product,
        browserVersion1a: window.navigator.appVersion,
        browserVersion1b: navigator.userAgent,
        browserLanguage: navigator.language,
        browserOnline: navigator.onLine,
        browserPlatform: navigator.platform,
        javaEnabled: navigator.javaEnabled(),
        dataCookiesEnabled: navigator.cookieEnabled,
        dataCookies1: document.cookie,
        dataCookies2: decodeURIComponent(document.cookie.split(";")),
        dataStorage: localStorage,
        sizeScreenW: window.screen.width,
        sizeScreenH: window.screen.height,
        sizeDocW: window.document.width,
        sizeDocH: window.document.height,
        sizeInW: window.innerWidth,
        sizeInH: window.innerHeight,
        sizeAvailW: window.screen.availWidth,
        sizeAvailH: window.screen.availHeight,
        scrColorDepth: window.screen.colorDepth,
        scrPixelDepth: window.screen.pixelDepth,
      };
      // console.log("INFO:",info);
      //fin info
      //get ip
      axios.get(`https://geolocation-db.com/json/`).then((res) => {
        console.log("IP:", res.data);
        ip = res.data;
        //saveLog
        if (user && ip) {
          //cambiar para produccion
          let url = `${process.env.REACT_APP_URL}api/logcapture`;
          //let url = `${process.env.REACT_APP_URL_DEV}api/logcapture`;
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              Accept: " application/json",
            },
          };

          var data = {
            user: user,
            actividad: actividad,
            dataBrowser: info,
            ip: ip,
          };
          axios
            .post(url, data, config, {
              // receive two parameter endpoint url ,form data
            })
            .then((response) => {
              // Respuesta del servidor
              console.log(response);
              console.log("LOGIN LOG SAVED");
            })
            .catch((error) => {
              // Error 😨
              if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log(error.response.data.message);
                alert(error.response.data.message);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                // Something happened in setting up the request and triggered an Error
                console.log("Error", error.message);
              }
              console.log(error.config);
            });
        }
        //fin saveLog
      });
      //fin get ip
    })
    .catch((error) => {
      console.error(error);
    });
  //fin get user

  console.log("LOGIN LOG END");
}

export function saveLogFail(actividad, email) {
  console.log("LOGIN LOG START");
  let ip, info;
  let user = {
    name: email,
    email: email,
    company: "",
  };
  console.log("USER:", user);
  //get ip
  axios.get(`https://geolocation-db.com/json/`).then((res) => {
    console.log("IP:", res.data);
    ip = res.data;

    //get browser info
    info = {
      timeOpened: new Date(),
      timezone: new Date().getTimezoneOffset() / 60,
      browser: browserName,
      browserVersion: browserVersion,
      deviceType: deviceType,
      osName: osName,
      osVersion: osVersion,
      mobileVendor: mobileVendor,
      mobileModel: mobileModel,
      pageon: window.location.pathname,
      referrer: document.referrer,
      previousSites: window.history.length,
      browserName: window.navigator.appName,
      browserEngine: window.navigator.product,
      browserVersion1a: window.navigator.appVersion,
      browserVersion1b: navigator.userAgent,
      browserLanguage: navigator.language,
      browserOnline: navigator.onLine,
      browserPlatform: navigator.platform,
      javaEnabled: navigator.javaEnabled(),
      dataCookiesEnabled: navigator.cookieEnabled,
      dataCookies1: document.cookie,
      dataCookies2: decodeURIComponent(document.cookie.split(";")),
      dataStorage: localStorage,
      sizeScreenW: window.screen.width,
      sizeScreenH: window.screen.height,
      sizeDocW: window.document.width,
      sizeDocH: window.document.height,
      sizeInW: window.innerWidth,
      sizeInH: window.innerHeight,
      sizeAvailW: window.screen.availWidth,
      sizeAvailH: window.screen.availHeight,
      scrColorDepth: window.screen.colorDepth,
      scrPixelDepth: window.screen.pixelDepth,
    };
    // console.log("INFO:",info);
    //fin info

    //saveLog
    if (user && ip) {
      let url = `${process.env.REACT_APP_URL_DEV}api/logs`;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          Accept: " application/json",
        },
      };

      var data = {
        user: user,
        actividad: actividad,
        dataBrowser: info,
        ip: ip,
      };
      axios
        .post(url, data, config, {
          // receive two parameter endpoint url ,form data
        })
        .then((response) => {
          // Respuesta del servidor
          console.log(response);
          console.log("LOGIN LOG SAVED");
        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data.message);
            alert(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
    //fin saveLog
  });
  //fin get ip

  console.log("LOGIN LOG END");
}

export function saveLogCaptureActividad(actividad, email) {
  console.log("LOGIN LOG START");
  let ip, info;
  let user = {
    name: email,
    email: email,
    company: "",
  };
  console.log("USER:", user);
  //get ip
  axios.get(`https://geolocation-db.com/json/`).then((res) => {
    console.log("IP:", res.data);
    ip = res.data;

    //get browser info
    info = {
      timeOpened: new Date(),
      timezone: new Date().getTimezoneOffset() / 60,
      browser: browserName,
      browserVersion: browserVersion,
      deviceType: deviceType,
      osName: osName,
      osVersion: osVersion,
      mobileVendor: mobileVendor,
      mobileModel: mobileModel,
      pageon: window.location.pathname,
      referrer: document.referrer,
      previousSites: window.history.length,
      browserName: window.navigator.appName,
      browserEngine: window.navigator.product,
      browserVersion1a: window.navigator.appVersion,
      browserVersion1b: navigator.userAgent,
      browserLanguage: navigator.language,
      browserOnline: navigator.onLine,
      browserPlatform: navigator.platform,
      javaEnabled: navigator.javaEnabled(),
      dataCookiesEnabled: navigator.cookieEnabled,
      dataCookies1: document.cookie,
      dataCookies2: decodeURIComponent(document.cookie.split(";")),
      dataStorage: localStorage,
      sizeScreenW: window.screen.width,
      sizeScreenH: window.screen.height,
      sizeDocW: window.document.width,
      sizeDocH: window.document.height,
      sizeInW: window.innerWidth,
      sizeInH: window.innerHeight,
      sizeAvailW: window.screen.availWidth,
      sizeAvailH: window.screen.availHeight,
      scrColorDepth: window.screen.colorDepth,
      scrPixelDepth: window.screen.pixelDepth,
    };
    // console.log("INFO:",info);
    //fin info

    //saveLog
    if (user && ip) {
      //cambiar para produccion
      let url = `${process.env.REACT_APP_URL}api/logcapture`;
      //let url = `${process.env.REACT_APP_URL_DEV}api/logcapture`;
      const config = {
        headers: {
          "Content-Type": "application/json",
          //cambiar para produccion
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          //Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNDA1Mjg0MTZhNDNmM2FkNTliZTViMTM0NTExNmZiZGM1YTE5OTBmNTdmYzYwNDViY2VkMWEyNjY2MzhkYjZiNzBmMTUyYjkxYTI5YTYzNDYiLCJpYXQiOjE2MTU5MzA0NTksIm5iZiI6MTYxNTkzMDQ1OSwiZXhwIjoxNjE2MDE2ODU5LCJzdWIiOiI2Iiwic2NvcGVzIjpbXX0.CaSkVB1NF-TQNWalOZInqcB7W4R15iNgvTcI0QDwVu8uimUzMWhihs0gFaVNlg_6gVRG_oZ8BnbS_772XLSafwm1AXpKn7ZWEvhUCIOgxQNv79gNZ5VJpgj9imqEBQYAkI8BkLS6jucxDz4qzS4NrOHkkRCm54N5Uu-FGqn7WiIxjJ6jLDTRO1eYwD63Ghf6XKJPXa2mBOsRGtVt01gW6WBBoMmIo1BeNaXj-yPbEw1vLMyCHp1JIEqCYRDu8FkfC-vToB1jFhFv8JG-IgEEU8PQy3UgMxJw1dyhUOYUpLaRr7yoeh5OZAtMgZxWdOsDnMtd4MtVFZ0cnc13bmeIAVQpv88OGoW0EizqBaoJO6uAnM7Ie_7WisT7BTXKU4gVb8vAvEf65dmejlJ0cmvp3bYMF1Sy9uf-TCHIpq7dBz1lsrjDGa_FrN3qIeV-jLukT5zbGpknPitbkHkrhjfNvMNoKXtDPyj4aloTWxkNNccK9eSguHGijcJqiofCCG0WHRzKaxcnm4KoK3KdBd7RfHoYUngZYgZKj4kHQC1VEWrHwmPRxqsEmF8CmPq4THAKXT51_j6lmYiNvdLGhnCdpl1wxrf7IDEQ-zldc1kzNb3cik2NeDVvlRZda4m2CzpHD-FOsGzgnKiRycLWUGH534pwkS5y-KN7yIsh2ch_bfY`,
          Accept: " application/json",
        },
      };

      var data = {
        user: user,
        actividad: actividad,
        dataBrowser: info,
        ip: ip,
      };
      axios
        .post(url, data, config, {
          // receive two parameter endpoint url ,form data
        })
        .then((response) => {
          // Respuesta del servidor
          console.log(response);
          console.log("LOGIN LOG SAVED");
        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data.message);
            alert(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
    //fin saveLog
  });
  //fin get ip

  console.log("LOGIN LOG END");
}

export function saveLogTest(token) {
  console.log("LOGIN LOG START", token);
  let user, ip, info;

  //get ip
  axios.get(`https://geolocation-db.com/json/`).then((res) => {
    console.log("IP:", res.data);
    ip = res.data;
  });
  //fin get ip

  //get user
  axios
    .get(`${process.env.REACT_APP_URL}api/auth/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const data = res.data;
      //console.log("DATAUSER", data.user);
      user = data.user;
    })
    .catch((error) => {
      console.error(error);
    });
  //fin get user

  //get browser info
  info = {
    timeOpened: new Date(),
    timezone: new Date().getTimezoneOffset() / 60,
    browser: browserName,
    browserVersion: browserVersion,
    deviceType: deviceType,
    osName: osName,
    osVersion: osVersion,
    mobileVendor: mobileVendor,
    mobileModel: mobileModel,
    pageon: window.location.pathname,
    referrer: document.referrer,
    previousSites: window.history.length,
    browserName: window.navigator.appName,
    browserEngine: window.navigator.product,
    browserVersion1a: window.navigator.appVersion,
    browserVersion1b: navigator.userAgent,
    browserLanguage: navigator.language,
    browserOnline: navigator.onLine,
    browserPlatform: navigator.platform,
    javaEnabled: navigator.javaEnabled(),
    dataCookiesEnabled: navigator.cookieEnabled,
    dataCookies1: document.cookie,
    dataCookies2: decodeURIComponent(document.cookie.split(";")),
    dataStorage: localStorage,
    sizeScreenW: window.screen.width,
    sizeScreenH: window.screen.height,
    sizeDocW: window.document.width,
    sizeDocH: window.document.height,
    sizeInW: window.innerWidth,
    sizeInH: window.innerHeight,
    sizeAvailW: window.screen.availWidth,
    sizeAvailH: window.screen.availHeight,
    scrColorDepth: window.screen.colorDepth,
    scrPixelDepth: window.screen.pixelDepth,
  };
  // console.log("INFO:",info);
  //fin info

  //saveLog
  if (user && ip) {
    let url = `${process.env.REACT_APP_URL_DEV}api/logs`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        Accept: " application/json",
      },
    };

    var data = {
      user: user,
      actividad: "Login",
      dataBrowser: info,
      ip: ip,
    };
    axios
      .post(url, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log(response);
        console.log("LOGIN LOG SAVED");
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data.message);
          alert(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }
  //fin saveLog
  console.log("LOGIN LOG END");
}
