import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InfoIcon from "@material-ui/icons/Info";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StarIcon from "@material-ui/icons/Star";
import ErrorIcon from "@material-ui/icons/Error";
import Snackbar from "components/Snackbar/Snackbar.js";

import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { DialogComp } from "components/Dialog/DialogComp";
const useStylestable = makeStyles(styles2);

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
}));

export default function Notificados(props) {
  const classes = useStyles();
  const classes2 = useStylestable();
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });
  const myRef = React.createRef();
  const [openAlert, setOpenAlert] = useState("");
  const [messageError, setMessageError] = useState("");
  const [colorAlert, setColorAlert] = useState("warning");
  const [notificadosPrevios, setNotificadosPrevios] = useState(false);
  const [datosNotificadosFrecuentes, setDatosNotificadosFrecuentes] = useState([
    {},
  ]);
  const [modalNotificados, setModalNotificados] = useState(
    props.modalNotificados
  );
  const [listaNotificados, setListaNotificados] = useState([]);
  const [textoValidacionMail, setTextoValidacionMail] = useState("");
  const [datosNotificados, setDatosNotificados] = useState({
    nombren: "",
    apellidosn: "",
    correon: "",
  });

  const [
    openDialogEliminarObservador,
    setOpenDialogEliminarObservador,
  ] = useState(false);
  const [rowDataObservador, setrowDataObservador] = useState({});

  function getNotificadosFrecuentes() {
    let token = localStorage.getItem("Token");

    //busca los firmantes del usuario en curso
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/notificadosfrecuentes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        //se filtra la respuesta eliminado duplicados
        console.log("PREVIOS", response.data);
        setDatosNotificadosFrecuentes(response.data);
        setNotificadosPrevios(true);
      })
      .catch(function (error) {
        console.log(error);
        setNotificadosPrevios(false);
      });
  }

  function b(email) {
    for (var i = datosNotificadosFrecuentes.length; i--; ) {
      if (datosNotificadosFrecuentes[i].email === email) {
        return datosNotificadosFrecuentes[i];
      }
    }
    return null;
  }

  const onChange = (event, value) => {
    console.log("change");
    //console.log(value.email);
    if (value) {
      var existente = b(value.email);
      console.log("existente", existente);

      if (existente) {
        setDatosNotificados({
          ...datosNotificados,
          nombren: existente.name,
          apellidosn: existente.last_name,
          correon: existente.email,
        });
      } else {
        setDatosNotificados({
          nombren: "",
          apellidosn: "",
          correon: "",
        });
      }
    } else {
      setDatosNotificados({
        nombren: "",
        apellidosn: "",
        correon: "",
      });
    }
  };

  function handleInputChange(event, value) {
    //console.log(value);
    setDatosNotificados({
      ...datosNotificados,
      correon: value,
    });
  }

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 10000);
  };

  const handleEliminarObservador = () => {
    let tokenCC = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_URL}api/notificados/${rowDataObservador._id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("FirmanteCC eliminado:", response);
        actualizarNotificados(props.idDocumento);
        setOpenDialogEliminarObservador(false);
      })
      .catch(function (error) {
        console.log(error);
        setOpenDialogEliminarObservador(false);
      });
  };

  const handleCancelarEliminarObservador = () => {
    setOpenDialogEliminarObservador(false);
    setrowDataObservador({});
  };

  const handleConfirmarEliminarFirmante = (rowData) => {
    setOpenDialogEliminarObservador(true);
    setrowDataObservador(rowData);
  };

  React.useEffect(() => {
    actualizarNotificados(props.idDocumento);
    getNotificadosFrecuentes();
    //validarMail("lperroni@hotmail.com");
  }, []);

  function validarMail(email) {
    let tokenSG =
      "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";
    var config = {
      async: true,
      crossDomain: true,
      method: "post",
      url: `https://api.sendgrid.com/v3/validations/email`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenSG,
      },
      processData: false,
      data: `{\"email\":\"${email}\",\"source\":\"signup\"}`,
    };
    axios(config)
      .then(function (response) {
        console.log("Email Validation:", response);
        if (response.data.result.verdict === "Invalid") {
          setTextoValidacionMail("Correo no valido");
        } else {
          setTextoValidacionMail("");
        }
        //verdict: "Invalid"
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleClickNotificados = () => {
    registraNotificados();
  };

  function eliminarNotificado(id) {
    let tokenCC = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_URL}api/notificados/${id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("FirmanteCC eliminado:", response);
        actualizarNotificados(props.idDocumento);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function registraNotificados() {
    var result = await makePostNotificados();
    console.log(result);
    setDatosNotificados({
      nombren: "",
      apellidosn: "",
      correon: "",
    });
    actualizarNotificados(props.idDocumento);
    console.log("Notificado OK");
  }

  function makePostNotificados() {
    if (notificadosPrevios) {
      myRef.current
        .getElementsByClassName("MuiAutocomplete-clearIndicator")[0]
        .click();
    }

    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;

    const data = new FormData();

    data.append("name", datosNotificados.nombren);
    data.append("last_name", datosNotificados.apellidosn);
    data.append("email", datosNotificados.correon);
    data.append("documentos_id", props.idDocumento);
    data.append("notification_types_id", "1");

    var url = "https://api.con-certeza.mx/api/notificados";
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer  " + token,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(url, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  const actualizarNotificados = (idDocumento) => {
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    //https://api.con-certeza.mx/api/notificados/documento/
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/notificados/documento/${idDocumento}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        let arr = response.data;
        console.log("Notificados:", arr);
        setListaNotificados(arr);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleInputNotificados = (event) => {
    setDatosNotificados({
      ...datosNotificados,
      [event.target.name]: event.target.value,
    });
  };

  let disableformnotificados = true;
  if (
    datosNotificados.nombren !== "" &&
    datosNotificados.apellidosn !== "" &&
    datosNotificados.correon !== ""
  ) {
    disableformnotificados = false;
  }

  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  return (
    <React.Fragment>
      {/* MODAL NOTIFICADOS */}
      <Dialog
        open={modalNotificados}
        transition={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        ></DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" gutterBottom></Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                {notificadosPrevios ? (
                  <Autocomplete
                    ref={myRef}
                    required
                    id="correo"
                    name="correo"
                    freeSolo
                    options={datosNotificadosFrecuentes}
                    getOptionLabel={(option) => option.email}
                    onInputChange={handleInputChange}
                    onChange={onChange}
                    onBlur={() => {
                      if (datosNotificados.correon) {
                        validarMail(datosNotificados.correon);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Correo electrónico" />
                    )}
                  />
                ) : (
                  <TextField
                    required
                    id="correon"
                    name="correon"
                    label="Correo electrónico"
                    value={datosNotificados.correon}
                    fullWidth
                    autoComplete="email"
                    onChange={handleInputNotificados}
                    onBlur={() => {
                      if (datosNotificados.correon) {
                        validarMail(datosNotificados.correon);
                      }
                    }}
                    inputProps={{ maxLength: 60 }}
                  />
                )}
                <font color="red">{textoValidacionMail}</font>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="nombren"
                  name="nombren"
                  label="Nombre"
                  value={datosNotificados.nombren}
                  fullWidth
                  autoComplete="given-name"
                  onChange={handleInputNotificados}
                  inputProps={{ maxLength: 30 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="apellidosn"
                  name="apellidosn"
                  label="Apellidos"
                  value={datosNotificados.apellidosn}
                  fullWidth
                  autoComplete="family-name"
                  onChange={handleInputNotificados}
                  inputProps={{ maxLength: 60 }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <br />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#00acc1",
                  color: "white",
                  float: "right",
                }}
                onClick={handleClickNotificados}
                disabled={disableformnotificados}
              >
                <Typography variant="button" style={{ textTransform: "none" }}>
                  Agregar
                </Typography>
              </Button>
            </Grid>
            <br />
            <br />
            <MaterialTable
              localization={localization}
              options={{
                exportButton: false,
                headerStyle: {
                  backgroundColor: "#26c6da",
                  color: "#FFF",
                },
                pageSize: 5, // make initial page size
                pageSizeOptions: [5, 10, 20, 50],
                actionsColumnIndex: -1,
                exportButton: false,
                search: false,
              }}
              columns={[
                { title: "Nombre", field: "name" },
                { title: "Apellido", field: "last_name" },
                { title: "Correo", field: "email" },
              ]}
              data={listaNotificados}
              title={""}
              actions={[
                {
                  icon: () => (
                    <DeleteForeverIcon
                      className={
                        classes2.tableActionButtonIcon + " " + classes2.edit
                      }
                    />
                  ),
                  tooltip: "Eliminar observador",
                  onClick: (event, rowData) => {
                    handleConfirmarEliminarFirmante(rowData);
                  },
                },
                {
                  icon: () => (
                    <StarIcon
                      className={
                        classes2.tableActionButtonIcon + " " + classes2.edit
                      }
                    />
                  ),
                  tooltip: "Agregar Observador Frecuente",
                  onClick: (event, rowData) => {
                    console.log(rowData);
                    const data = new FormData();
                    data.append("name", rowData.name);
                    data.append("last_name", rowData.last_name);
                    data.append("email", rowData.email);
                    data.append("notification_types_id", 1);
                    data.append("group", "DEFAULT");
                    let token = localStorage.getItem("Token");
                    const config = {
                      method: "post",
                      url: `${process.env.REACT_APP_URL}api/notificadosfrecuentes`,
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                      data: data,
                    };
                    axios(config)
                      .then((response) => {
                        // Respuesta del servidor
                        console.log(response.data);
                        showNotificacion(
                          "Notificado agregado a Notificados Frecuentes"
                        );
                      })
                      .catch((error) => {
                        // Error 😨
                        console.log(error.response.data);
                        //showNotificacion("El notificado ya existe en Notificados Frecuentes");
                        if (error.response.data.length > 1) {
                          //showNotificacion(error.response.data[0].message);
                          showNotificacion(
                            "El observador ya fue registrado en “Observadores Frecuentes”."
                          );
                        } else {
                          if (error.response.data.errors.email) {
                            showNotificacion(error.response.data.errors.email);
                          } else if (error.response.data.errors.phone) {
                            showNotificacion(error.response.data.errors.phone);
                          } else if (error.response.data.message) {
                            showNotificacion(error.response.data.message);
                          }
                        }
                      });
                  },
                },
              ]}
            />
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setModalNotificados(false);
              props.setModalNotificados(false);
            }}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <DialogComp
        open={openDialogEliminarObservador}
        title={"¿Está seguro de eliminar al Observador?"}
        content="Se eliminará al Observador de la lista"
        maxWidth={"sm"}
        buttons={[
          {
            buttonEvent: () => handleEliminarObservador(),
            buttonName: "Confirmar",
            buttonColor: "primary",
          },
          {
            buttonEvent: () => handleCancelarEliminarObservador(),
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
        ]}
      />
      <Snackbar
        place="tc"
        color="info"
        icon={ErrorIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />
    </React.Fragment>
  );
}
