import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import Container from "@material-ui/core/Container";
import Tooltip from "@material-ui/core/Tooltip";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

import MaterialTable from "material-table";
import logo from "assets/img/ConCertezaAzul.png";
import axios from "axios";
import localization from "components/localization/localization";
import moment from "moment";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";

const useStylestable = makeStyles(styles2);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    padding: theme.spacing(1),
  },
  parrafo: { margin: 0 },

  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Detalles() {
  const classes2 = useStylestable();
  const classes = useStyles();
  let { idDoc } = useParams();
  const [modal, setModal] = React.useState(false);
  const [detalles, setDetalles] = React.useState("");
  const [listaFirmantes, setListaFirmantes] = React.useState();

  React.useEffect(() => {
    const token = `${process.env.REACT_APP_TOKEN}`;
    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDoc}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);

        setDetalles(res.data);
        setListaFirmantes(res.data.firmantes);
      })
      .catch((error) => {
        if (error.response.data.message) {
          console.log(error.response.data.message);
        }
      });
  }, []);

  return (
    <React.Fragment>
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <img src={logo} alt="logo" width="220" height="60" />
        </Toolbar>
      </AppBar>
      {/*<Container>*/}
      <Box width={1} bgcolor="grey.300" p={1} my={0.5}>
        <Paper elevation={3} style={{ padding: 10 }}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <p align="left">
                <b>Información del proceso de firma digital</b>
              </p>
              <hr />
            </Grid>
            <Grid item xs={12} sm={12}>
              <p className={classes.parrafo}>
                <b>Emisor:</b> {detalles ? detalles.users.name : null}
              </p>
              <p className={classes.parrafo}>
                <b>Compañía:</b>{" "}
                {detalles.corporacion != null
                  ? detalles.corporacion.name
                  : detalles
                  ? detalles.users.company
                  : null}
              </p>
              <p className={classes.parrafo}>
                <b>Correo electrónico:</b>{" "}
                {detalles ? detalles.users.email : null}
              </p>
              <p className={classes.parrafo}>
                <b>Nombre del documento:</b>{" "}
                {detalles ? detalles.filename : null}
              </p>
              <p className={classes.parrafo}>
                <b>ID del documento:</b> {detalles ? detalles._id : null}
              </p>
              <p className={classes.parrafo}>
                <b>Descripción:</b> {detalles ? detalles.description : null}
              </p>
            </Grid>
            <Grid item xs={12} sm={12}>
              {listaFirmantes ? (
                <MaterialTable
                  localization={localization}
                  options={{
                    headerStyle: {
                      color: "green",
                    },
                    toolbar: false,
                    showTitle: false,
                    rowStyle: { fontSize: 12 },
                    search: false,
                    paging: false,
                    actionsColumnIndex: -1,
                    exportButton: false,
                    exportAllData: true,
                  }}
                  columns={[
                    {
                      title: "Nombre",
                      field: "last_name",
                      render: (row) => (
                        <span>{row["name"] + " " + row["last_name"]}</span>
                      ),
                    },
                    {
                      title: "Prueba de vida",
                      field: "speech_required",
                      render: (row) => (
                        <span>
                          {row["speech_required"] == true ? (
                            <Tooltip
                              id="tooltip-top"
                              title="Se solicito prueba de vida"
                              placement="top"
                              classes={{
                                tooltip: classes2.tooltip,
                              }}
                            >
                              <IconButton
                                aria-label="Edit"
                                className={classes2.tableActionButton}
                              >
                                <RecordVoiceOverIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            "No se solicito"
                          )}
                        </span>
                      ),
                    },
                    { title: "Compañía", field: "company" },
                    {
                      title: "Medio de Notificación",
                      field: "notification_types_id",
                      render: (row) => (
                        <span>
                          {row["notification_types_id"] === 1
                            ? row["email"]
                            : row["phone_country_code"] + row["phone"]}
                        </span>
                      ),
                    },
                    {
                      title: "Estatus del Proceso",
                      field: "date_signed",
                      render: (row) => (
                        <span>
                          {row["date_signed"]
                            ? "Firmó"
                            : row["firmas"] && row["firmas"].length > 0
                            ? "Pendiente de confirmar código"
                            : "Pendiente de Firma"}
                        </span>
                      ),
                    },
                    {
                      title: "Registrado",
                      field: "created_at",
                      render: (row) => (
                        <span>
                          {row["created_at"]
                            ? moment(row["created_at"]).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : null}
                        </span>
                      ),
                    },
                    {
                      title: "Enviado",
                      field: "date_notified",
                      render: (row) => (
                        <span>
                          {row["date_notified"]
                            ? moment(row["date_notified"]).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : null}
                        </span>
                      ),
                    },
                    {
                      title: "Aviso de Privacidad Aceptado",
                      field: "date_accepted",
                      render: (row) => (
                        <span>
                          {row["date_accepted"]
                            ? moment(row["date_accepted"]).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : null}
                        </span>
                      ),
                    },
                    {
                      title: "Visto",
                      field: "date_accepted",
                      render: (row) => (
                        <span>
                          {row["date_accepted"]
                            ? moment(row["date_accepted"]).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : null}
                        </span>
                      ),
                    },
                    {
                      title: "Firmado",
                      field: "date_signed",
                      render: (row) => (
                        <span>
                          {row["date_signed"]
                            ? moment(row["date_signed"]).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : null}
                        </span>
                      ),
                    },
                    {
                      title: "Comentarios",

                      render: (row) => (
                        <span>
                          {detalles.reason ? (
                            <React.Fragment>
                              <font size="1">
                                <b>Fecha de detención:</b>{" "}
                              </font>
                              <font size="1">
                                {moment(detalles.stopped_at).format(
                                  "DD/MM/YYYY HH:mm:ss"
                                )}
                              </font>
                              <br></br>
                              <font size="1">
                                <b>Motivo:</b> {detalles.reason}
                              </font>
                              <br></br>
                            </React.Fragment>
                          ) : null}
                          {row["refused_comment"] ? (
                            <React.Fragment>
                              <font size="1">
                                <b>Comentario:</b> {row["refused_comment"]}
                              </font>
                              <br></br>
                            </React.Fragment>
                          ) : null}
                        </span>
                      ),
                    },
                  ]}
                  data={listaFirmantes}
                  title={""}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12}>
              <p>
                Nota: En caso de tener dudas del presente mensaje, por favor
                comunicarte con{" "}
                <b>{detalles ? detalles.users.name + " " : null}</b>
                al correo electrónico
                <b>{detalles ? " " + detalles.users.email : null}</b>.
              </p>
              <p>Este servicio es proporcionado por,</p>
              <p className={classes.parrafo}>
                Certificación Digital Online S.A. de C.V.
              </p>
              <p className={classes.parrafo}>
                <a href="https://certificaciondigital.online/" target="_blank">
                  www.certificaciondigital.online
                </a>
              </p>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      {/*</Container>*/}

      {/*
    <Dialog
      open={modal}
      //transition={Transition}
      keepMounted
      onClose={() => setModal(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
      >
        <b>INFORMACIÓN DEL PROCESO DE FIRMA DIGITAL</b>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
      >
        HOLA
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModal(false)}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
    */}
    </React.Fragment>
  );
}
