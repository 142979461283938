import React from "react";
import { isMobile } from 'react-device-detect';
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress';
import GraficaPie from "./GraficaPie";

export default function GraficaPrincipalPie(props) {

  const [loading, setLoading] = React.useState(true);
  const [dataPie, setDataPie] = React.useState("");

  React.useEffect(() => {
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    //DOCUMENTOS
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        let arr = response.data;
        console.log("DOCUMENTOS", arr);
        //llenar datos para gráfico pie
          var s1=0; //registrados
          var s2=0; //proceso
          var s3=0; //Firmados
          var s4=0; //rechazados
          var s5=0; //conservados
          for (var i = 0; i < arr.length; i++) {
            switch(arr[i].status.id) {
            case 1:
              s1++;
              break;
            case 2:
              s2++;
              break;
            case 3:
              s3++;
              break;
            case 4:
              s4++;
              break;
            case 5:
              s5++;
              break;
          }
        }

        setDataPie([
        { angle: s3, color: "#66bb6a", name: "Firmados", label:s3 },
        { angle: s2, color: "#ffa726", name: "Proceso", label:s2 },
        { angle: s4, color: "#ef5350", name: "Rechazados", label:s4 },
        { angle: s1, color: "#ab47bc", name: "Registrados", label:s1 },
        { angle: s5, color: "#00acc1", name: "Conservados", label:s5 }
      ]);

      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
        //FIN DOCUMENTOS

      }, []);

      return (
        <React.Fragment>
        {
          loading ? (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
              <CircularProgress />
            </div>
          ):null
        }
        <GraficaPie
          data={dataPie}
       />
        </React.Fragment>
      )

}
