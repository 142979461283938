import React from "react";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import send from "assets/img/send.png";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Review(props) {
  const history = useHistory();
  const classes = useStyles();
  const classespro = useStylesprogrees();
  const [open, setOpen] = React.useState(true);
  const { idDocumentoFirmado, setActiveRender, registrado } = props;
  const [nameDocu, setFileName] = React.useState("");
  const [base64, setbase64] = React.useState("");
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(425);
  const [pdfWidth, setpdfWidth] = React.useState(500);

  const token = `${process.env.REACT_APP_TOKEN}`;
  React.useEffect(() => {
    if (isMobile()) {
      setpdfHeigth(500);
      setpdfWidth(290);
    }
    console.log("REVIEW ID ", idDocumentoFirmado);
    const endpoint = `${process.env.REACT_APP_URL}api/documentos/${idDocumentoFirmado}/signed/download`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        console.log("repuesta", resp.data);
        const status = resp.data.status;
        setFileName(resp.data.filename);

        setTimeout(pdfFirmado, 1000);
      });
  }, []);

  const pdfFirmado = () => {
    axios
      .get(
        `${process.env.REACT_APP_URL}api/documentos/${idDocumentoFirmado}/signed/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const handleDescargarPDF = async () => {
    const token = `${process.env.REACT_APP_TOKEN}`;

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocumentoFirmado}/signed/download`,
      //responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config).then((response) => {
      const linkSource = `data:application/pdf;base64,${response.data}`;
      const downloadLink = document.createElement("a");
      const fileName = nameDocu;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  return (
    <React.Fragment>
      <div>
        {base64.length === 0 ? (
          <Backdrop className={classespro.backdrop} open={open}>
            <CircularProgress size={44} className={classespro.buttonProgress} />
          </Backdrop>
        ) : (
          <React.Fragment>
            <Typography variant="h6" gutterBottom>
              Documento Firmado
            </Typography>
            <div>
              <Document
                error="Error al intentar cargar el PDF"
                loading="Cargando..."
                file={`data: application / pdf; base64, ${base64} `}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  scale={1}
                  height={pdfHeigth}
                  width={pdfWidth}
                  renderTextLayer={false}
                  pageNumber={pageNumber}
                />
              </Document>
              <div className="text-center">
                <p>
                  Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                  {numPages || "--"}
                </p>
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  Atrás
                </button>{" "}
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  Siguiente
                </button>
              </div>
            </div>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDescargarPDF}
                className={classes.button}
              >
                Descargar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (registrado == true) {
                    let uri = localStorage.getItem("uri");
                    history.push(`/${uri}/nuevoProceso`);
                  } else {
                    setActiveRender(0);
                  }
                }}
                className={classes.button}
              >
                Finalizar
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
