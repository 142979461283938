import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
//import PlantillasBAZ from "./PlantillasBAZ";
import JoditEditor from "jodit-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonC from "components/CustomButtons/Button.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
//import ShareIcon from '@material-ui/icons/Share';
import PersonAddIcon from "@material-ui/icons/PeopleAlt";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import GestureIcon from "@material-ui/icons/Gesture";
import EditIcon from "@material-ui/icons/Edit";
import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";
import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "@material-ui/core/TextField";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import ControlPanel from "components/controlPanel/ControlPanel";
import Tooltip from "@material-ui/core/Tooltip";
import TocIcon from "@material-ui/icons/Toc";
import AppsIcon from "@material-ui/icons/Apps";
import MaterialTable from "material-table";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import ContainerAutoSign from "containers/autoSign/autoSingContainer";
import Firmantes from "containers/FirmaAvanzada/Participantes";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import mensaje from "assets/img/mensaje.png";
import SnackbarMensaje from "@material-ui/core/Snackbar";
import ErrorIcon from "@material-ui/icons/Error";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import FileCopyIcon from "@material-ui/icons/FileCopy";
//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "quill-paste-smart";
//fin editor
//draggable
import Draggable from "react-draggable";
import firma from "containers/Firmante/rectangle-firma1.png";
//fin draggable
import { CSVLink } from "react-csv";
import Components from "containers/CheckoutNew";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//*****************************************************
//    LLAMADAS A LA API DE CDO
//******************************************************
//credenciales API CDO
const username = "ce2af9cab947ed15288e058ecd5350f5";
const password = "9c9dd0db9b4a23eddf64b96e28b1214b";
const tokenCDO = Buffer.from(`${username}:${password}`, "utf8").toString(
  "base64"
);
const colorinfo = "#00acc1";
const htmlstyle =
  "<style>.ql-align-center {text-align: center;} .ql-align-justify {text-align: justify;}.ql-align-right {text-align: right;}.ql-align-left {text-align: left;}</style>";
//const htmlstyle = "<style>.br {content: '';display: block;font-size: 200%;}.p{line-height: 0%;font-size: 200%}.ql-align-center {text-align: center;} .ql-align-justify {text-align: justify;}.ql-align-right {text-align: right;}.ql-align-left {text-align: left;}</style>";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 250,
    minWidth: 250,
    maxHeight: 250,
    margin: 5,
  },
  headerTitle: {
    //maxWidth: 250
    fontSize: 12,
    fontWeight: "bold",
  },
  subHeaderTitle: {
    //maxWidth: 250
    fontSize: 7,
    fontWeight: "bold",
  },
  media: {
    height: 0,
    //paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    //backgroundColor: red[500],
    //backgroundColor: "#00acc1",
    backgroundColor: "black",
  },
  botondanger: {
    backgroundColor: "red",
    color: "white",
  },
  botongreen: {
    backgroundColor: "green",
    color: "white",
  },
  hr: {
    margin: 1,
  },
  tooltip: {
    margin: 1,
    padding: 1,
  },
}));
const useStylestable = makeStyles(styles2);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function campos(obj) {
  if (obj) {
    var tx = "";
    for (var i = 0; i < obj.length; i++) {
      tx = tx + "[" + obj[i] + "]";
    }
    return (
      "<font color='darkblue' style='background-color:GhostWhite'>" +
      tx +
      "</font>"
    );
  }
}

function ListarPlantillas(props) {
  const classes = useStyles();
  const classes2 = useStylestable();
  const [expanded, setExpanded] = React.useState(false);
  const [pdfHeigthPV, setpdfHeigthPV] = React.useState(200);
  const [pdfWidthPV, setpdfWidthPV] = React.useState(200);
  const [pdfHeigth, setpdfHeigth] = React.useState(475);
  const [pdfWidth, setpdfWidth] = React.useState(550);
  const [scale, setScale] = React.useState(1.0);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1); //setting 1 to show fisrt page
  const [modalVistaPrevia, setModalVistaPrevia] = React.useState(false);
  const [pdfbase64, setPdfbase64] = React.useState("");
  const [x, setX] = React.useState(0);
  const [y, setY] = React.useState(0);
  const [disableFijar, setDisableFijar] = React.useState(false);
  const [coordenadas, setCoordenadas] = React.useState("");
  const clickFijar = () => {
    setDisableFijar(false);
    setX(0);
    setY(0);
  };
  const refField = React.createRef();
  const handleEvent = (e, data) => {
    console.log("Event Type", e.type);
    console.log(e, data);
    setX(Math.round(data.x));
    setY(Math.round(data.y) + 15);
  };

  const colorStatistics = "#3f51b5";
  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  function limpiarformato(html) {
    var str = html;
    var highlight = html;
    var part;
    var fields = [];

    do {
      part = str.substring(str.lastIndexOf("[") + 1, str.lastIndexOf("]"));
      str = str.replace(
        "<span style='background-color:yellow'>[" + part + "]</span>",
        ""
      );
      highlight = highlight.replace(
        "<span style='background-color:yellow'>[" + part + "]</span>",
        "[" + part + "]"
      );
    } while (part !== "");

    return highlight;
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const plantillas = props.plantillas;
  const listItems = plantillas.map((item, i) => (
    <React.Fragment key={i}>
      <Card className={classes.root}>
        <CardHeader
          titleTypographyProps={{ variant: "h5" }}
          title={item.name.toUpperCase()}
          //subheader={item.default ? moment().format("DD-MM-YYYY"):moment(item.updated_at).format("DD-MM-YYYY")}
          subheader={"ID: " + item._id}
          classes={
            (classes.CardHeader,
            {
              title: classes.headerTitle,
              subheader: classes.subHeaderTitle,
            })
          }
          style={{ padding: 2, marginLeft: 5 }}
        />
        <CardContent style={{ margin: 0, paddingTop: 0 }}>
          <hr className={classes.hr} />
          <Tooltip
            title="Ver Documento"
            placement="top"
            className={classes.tooltip}
          >
            <IconButton aria-label="settings">
              <VisibilityIcon
                className={classes2.tableActionButtonIcon + " " + classes2.edit}
                onClick={() => {
                  //props.setAccion(0);
                  //props.setItem(item)
                  //props.setMostrar(true);
                  setModalVistaPrevia(true);
                  setPdfbase64(item.pdf);
                }}
              />
            </IconButton>
          </Tooltip>
          {item.default ? null : (
            <>
              <Tooltip
                title="Editar plantilla"
                placement="top"
                className={classes.tooltip}
              >
                <IconButton aria-label="Editar">
                  <EditIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                    onClick={() => {
                      props.setAccion(2);
                      props.setItem(item);
                      props.setConvertedText(item.html);
                      //props.setConvertedText(limpiarformato(item.html));
                      props.setNombre(item.name);
                      props.setMostrar(true);
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip
                title="Eliminar plantilla"
                placement="top"
                className={classes.tooltip}
              >
                <IconButton aria-label="Eliminar">
                  <DeleteIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                    onClick={() => {
                      props.setAccion(1);
                      props.setItem(item);
                      props.setMostrar(true);
                    }}
                  />
                </IconButton>
              </Tooltip>
            </>
          )}

          <Tooltip
            title="Enviar a firmar"
            placement="top"
            className={classes.tooltip}
          >
            <IconButton aria-label="Enviar a firma">
              <GestureIcon
                className={classes2.tableActionButtonIcon + " " + classes2.edit}
                onClick={() => {
                  props.setAccion(3);
                  props.setItem(item);
                  props.setMostrar(true);
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="Firma masiva"
            placement="top"
            className={classes.tooltip}
          >
            <IconButton aria-label="Firma masiva">
              <PersonAddIcon
                className={classes2.tableActionButtonIcon + " " + classes2.edit}
                onClick={() => {
                  props.setAccion(4);
                  props.setItem(item);
                  props.setMostrar(true);
                  /*
                console.log("-------")
                if(item.fields){
                  for (var i = 0; i < item.fields.length; i++) {
                    console.log("campo:",item.fields[i])
                  }
                }
                */
                }}
              />
            </IconButton>
          </Tooltip>

          {item.default ? (
            <Tooltip
              title="Copiar plantilla"
              placement="top"
              className={classes.tooltip}
            >
              <IconButton aria-label="Copiar plantilla">
                <FileCopyIcon
                  className={
                    classes2.tableActionButtonIcon + " " + classes2.edit
                  }
                  onClick={() => {
                    props.setItem(item);
                    props.setCopiar(true);
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : null}

          <hr className={classes.hr} />

          {item && item.pdf ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              //minHeight="100vh"
              border={1}
              //color="red"
              overflow="auto"
            >
              <Document
                error="Error al intentar cargar el PDF"
                loading="Cargando..."
                file={`data:application/pdf;base64,${item.pdf}`}
                onLoadSuccess={onDocumentLoadSuccess}
                style={{ margin: 0 }}
              >
                <Page
                  scale={1}
                  height={pdfHeigthPV}
                  width={pdfWidthPV}
                  renderTextLayer={false}
                  pageNumber={1}
                  style={{ margin: 0 }}
                />
              </Document>
            </Box>
          ) : null}
        </CardContent>
        <CardActions disableSpacing></CardActions>
      </Card>

      {/*CUADRO DE DIÁLOGO PDF en card*/}
      <Dialog
        open={modalVistaPrevia}
        scroll={"body"}
        transition={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={false}
        maxWidth={"lg"}
        onClose={() => setModalVistaPrevia(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography>
          {pdfbase64 && disableFijar === false ? (
            <ControlPanel
              scale={scale}
              setScale={setScale}
              numPages={numPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              file={`data: application / pdf; base64, ${pdfbase64} `}
            />
          ) : null}
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {pdfbase64 ? (
            <div>
              <Document
                error="Error al intentar cargar el PDF"
                loading="Cargando..."
                file={`data:application/pdf;base64,${pdfbase64}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  //size="LEGAL"
                  scale={scale}
                  height={pdfHeigth}
                  width={pdfWidth}
                  renderTextLayer={false}
                  pageNumber={pageNumber}
                  /*
              onMouseMove={(e)=>{
                setX(e.nativeEvent.offsetX);
                setY(e.nativeEvent.offsetY);
              }}
              */
                />
              </Document>
              {scale === 1 && disableFijar ? (
                <Draggable
                  //onDrag={handleEvent}
                  //onStart={handleEvent}
                  onStop={handleEvent}
                  //onMouseDown={handleEvent}
                  //onMouseUp={handleEvent}
                  //onTouchStart={handleEvent}
                  //onTouchEnd={handleEvent}
                  bounds={{
                    top: -700,
                    left: 0,
                    right: 450,
                    bottom: -100,
                  }}
                  axis="both"
                  //position={null}
                  //grid={[25, 25]}
                >
                  <div>
                    <img
                      src={firma}
                      width="100"
                      height="64"
                      style={{ opacity: "40%" }}
                    />
                    <div
                      style={{ position: "absolute", top: "3px", left: "5px" }}
                    >
                      <font size="1">
                        <b>
                          Pag:{pageNumber}
                          <br /> X={x}/Y={y}
                        </b>
                      </font>
                    </div>
                    <Tooltip
                      id="tooltip-top"
                      title="Ocultar cuadro de coordenadas"
                      placement="top"
                    >
                      <IconButton aria-label="Edit">
                        <CancelIcon
                          style={{ color: "red" }}
                          onClick={() => clickFijar()}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Draggable>
              ) : null}
              {false ? (
                <React.Fragment>
                  <p align="center">
                    <b>
                      <h5
                        style={{
                          display: "inline-block",
                          backgroundColor: "#00acc1",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Coordenadas (para firma masiva): x={x} / y={y - 711}
                      </h5>
                    </b>
                  </p>
                </React.Fragment>
              ) : null}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.botondanger}
            onClick={() => {
              setModalVistaPrevia(false);
              setScale(1.0);
              setDisableFijar(false);
            }}
          >
            Cerrar
          </Button>
          {scale == 1 && disableFijar === false ? (
            <Tooltip title="Ver coordenadas de la firma" placement="top">
              <Button
                aria-label="Coordenadas de la firma"
                className={classes.botongreen}
              >
                <PhotoSizeSelectSmallIcon
                  onClick={() => {
                    setDisableFijar(true);
                  }}
                />
              </Button>
            </Tooltip>
          ) : null}
        </DialogActions>
      </Dialog>
      {/*FIN CUADRO PDF*/}
    </React.Fragment>
  ));
  return (
    <Grid container spacing={2}>
      {props.miniaturas ? (
        listItems
      ) : (
        <div style={{ width: "100%", TextAlign: "center" }}>
          <MaterialTable
            localization={localization}
            options={{
              draggable: false,
              exportButton: { csv: true, pdf: false },
              headerStyle: {
                backgroundColor: colorStatistics,
                color: "#FFF",
              },
              pageSize: 5, // make initial page size
              pageSizeOptions: [5, 20, 50, 100, 500, 1000],
              actionsColumnIndex: -1,
              //actionsColumnIndex: 0,
            }}
            columns={[
              { title: "Nombre", field: "name" },
              {
                title: "Creación",
                field: "created_at",
                render: (row) => (
                  <span>
                    {row["created_at"]
                      ? moment(row["created_at"]).format("DD/MM/YYYY")
                      : null}
                  </span>
                ),
              },
              {
                title: "Campos variables",
                field: "fields",
                render: (row) => (
                  <span>
                    {row["fields"] ? (
                      <div
                        contentEditable="true"
                        dangerouslySetInnerHTML={{
                          __html: campos(row["fields"]),
                        }}
                      ></div>
                    ) : null}
                  </span>
                ),
              },
              { title: "ID", field: "_id" },
            ]}
            data={props.plantillas}
            title="Plantillas"
            actions={[
              {
                icon: () => (
                  <VisibilityIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Ver documento",
                onClick: (event, rowData) => {
                  setModalVistaPrevia(true);
                  setPdfbase64(rowData.pdf);
                },
              },
              {
                icon: () => (
                  <EditIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Editar plantilla",
                onClick: (event, rowData) => {
                  props.setAccion(2);
                  props.setItem(rowData);
                  props.setConvertedText(rowData.html);
                  //props.setConvertedText(limpiarformato(rowData.html));
                  props.setNombre(rowData.name);
                  props.setMostrar(true);
                },
              },
              {
                icon: () => (
                  <DeleteIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Eliminar plantilla",
                onClick: (event, rowData) => {
                  props.setAccion(1);
                  props.setItem(rowData);
                  props.setMostrar(true);
                },
              },
              {
                icon: () => (
                  <GestureIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Enfiar a firma",
                onClick: (event, rowData) => {
                  console.log("Firmar");
                  props.setAccion(3);
                  props.setItem(rowData);
                  props.setMostrar(true);
                },
              },
              {
                icon: () => (
                  <PersonAddIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Firma masiva",
                onClick: (event, rowData) => {
                  props.setAccion(4);
                  props.setItem(rowData);
                  props.setMostrar(true);
                },
              },
            ]}
          />
          {/*CUADRO DE DIÁLOGO PDF*/}
          <Dialog
            open={modalVistaPrevia}
            scroll={"body"}
            transition={Transition}
            keepMounted
            fullScreen={false}
            fullWidth={false}
            maxWidth={"md"}
            onClose={() => setModalVistaPrevia(false)}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle id="classic-modal-slide-title" disableTypography>
              {pdfbase64 ? (
                <ControlPanel
                  scale={scale}
                  setScale={setScale}
                  numPages={numPages}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  file={`data: application / pdf; base64, ${pdfbase64} `}
                />
              ) : null}
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {pdfbase64 ? (
                <div>
                  <Document
                    error="Error al intentar cargar el PDF"
                    loading="Cargando..."
                    file={`data:application/pdf;base64,${pdfbase64}`}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      //size="LEGAL"
                      scale={scale}
                      height={pdfHeigth}
                      width={pdfWidth}
                      renderTextLayer={false}
                      pageNumber={pageNumber}
                    />
                  </Document>
                </div>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.botondanger}
                onClick={() => {
                  setModalVistaPrevia(false);
                  setScale(1.0);
                }}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
          {/*FIN CUADRO PDF*/}
        </div>
      )}
    </Grid>
  );
}

export default function Plantillas() {
  const classes = useStyles();
  const classes2 = useStylestable();
  const [contratosID, setContratosID] = React.useState("");
  const [listaFirmantes, setListaFirmantes] = React.useState("");
  const [enviar, setEnviar] = React.useState(true);
  const [modalFirmante, setModalFirmante] = React.useState(false);
  const [modalEnviado, setModalEnviado] = React.useState(false);
  const [tabState, setabState] = React.useState("");
  const [openForm, setOpenForm] = React.useState(0);
  const [idDocumento, setidDocumento] = React.useState("");
  const [agregarFirmantes, setAgregarFirmantes] = React.useState("");
  //const [user, setUser] = React.useState("");
  const [crearNueva, setCrearNueva] = React.useState(false);
  const [mostrar, setMostrar] = React.useState(false);
  const [copiar, setCopiar] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [accion, setAccion] = React.useState("");
  const [plantillas, setPlantillas] = React.useState([]);
  const [plantillasDefault, setPlantillasDefault] = React.useState([]);
  const [convertedText, setConvertedText] = React.useState("Escribir...");
  const [nombre, setNombre] = React.useState("");
  const [miniaturas, setMiniaturas] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [nombreArchivo, setNombreArchivo] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState("1");
  const [idprocces, setIdprocces] = React.useState(0);
  const { user } = useSelector((state) => state.auth);
  const { permissions } = useSelector((state) => state.auth);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [csv, setCsv] = React.useState("");
  const onChangeCsv = () => {
    var file = document.querySelector('input[type="file"]').files[0];
    setCsv(file);
    console.log(file);
  };
  const [camposPlantilla, setCamposPlantilla] = React.useState("");
  const [contenido, setContenido] = React.useState(
    `fir_name,fir_last_name,fir_notification_types_id,fir_email,fir_country_code,fir_phone,fir_company,fir_rol_code,fir_rol,fir_coord_page,fir_coord_x,fir_coord_y`
  );
  //const contenido = `fir_name,fir_last_name,fir_notification_types_id,fir_email,fir_rol_code,fir_phone,fir_company,fir_rol_code,fir_rol,fir_coord_page,fir_coord_x,fir_coord_y`;

  /*
  const headers = [
  { label: "fir_name", key: "fir_name" },
  { label: "fir_last_name", key: "fir_last_name" },
  { label: "fir_email", key: "fir_email" },
  { label: "fir_country_code", key: "fir_country_code" },
  { label: "fir_phone", key: "fir_phone" },
  { label: "fir_company", key: "fir_company" },
  { label: "fir_rol_code", key: "fir_rol_code" },
  { label: "fir_rol", key: "fir_rol" },
  { label: "fir_coord_page", key: "fir_coord_page" },
  { label: "fir_coord_x", key: "fir_coord_x" },
  { label: "fir_coord_y", key: "fir_coord_y" }
];


const contenido = [
  { fir_name: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
  { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
  { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
];

*/

  //editor
  const editor = React.useRef(null);
  const [content, setContent] = React.useState("");

  const config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    readonly: false, // all options from https://xdsoft.net/jodit/doc/ https://xdan.github.io/jodit/examples/
    width: "auto",
    height: "auto",
    minHeight: 400,
    language: "es",
    debugLanguage: false,
    i18n: "es",
    imageDefaultWidth: 300,
    removeButtons: [
      //'source',
      "font",
      "paragraph",
      "hr",
      "video",
      //'table',
      "link",
      "copyformat",
      "symbol",
      "about",
      "file",
    ],
    /*
    extraButtons: [
      {
	tooltip: 'Enter text and insert',
	icon: 'pencil',
	popup: (editor, current, self, close) => {
		const form = editor.create.fromHTML(
			`<form>
  	    <input type="text"/>
  	    <button type="submit">Insert</button>
  	  </form>`
		);

		editor.e.on(form, 'submit', (e) => {
			e.preventDefault();
			editor.s.insertHTML(form.querySelector('input').value);
			close();
		});

		return form;
	}
}
    ],
    */
  };

  const [pordefecto, setPordefecto] = React.useState(false);
  const marcaDefault = (permissions) => {
    if (permissions.search("stats.cdo") >= 0) {
      return (
        <Grid item xs={12} sm={12}>
          <label htmlFor="contained-button-file">
            <input
              type="checkBox"
              checked={pordefecto}
              onClick={() => console.log("Clicked!")}
              onChange={() => setPordefecto((pordefecto) => !pordefecto)}
            />
            Es una plantilla por defecto
          </label>
        </Grid>
      );
    }
  };

  const [openGuardado, setOpenGuardado] = React.useState(false);
  const [mensaje, setMensaje] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const handleCloseGuardado = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenGuardado(false);
  };

  function handleGoBackHome() {
    console.log("backhome");
    let uri = localStorage.getItem("uri");
    history.push(`/${uri}/dashboard`);
  }
  const history = useHistory();

  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  const handleReset = () => {
    //BORRA EL DOCUMENTO EN CDO
    console.log("contrato a eliminar CDO", contratosID.cdo);
    if (contratosID.cdo) {
      const URL =
        "https://api.certificaciondigital.online/api/contrato/" +
        contratosID.cdo +
        "/delete";
      var config = {
        method: "get",
        url: URL,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Basic " + tokenCDO,
        },
      };
      axios(config)
        .then(function (response) {
          console.log("ContratoCDO borrado:", response.data);
          setModalFirmante(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    //BORRA EL DOCUMENTO EN CON CERTEZA
    if (contratosID.cc) {
      let tokenCC = localStorage.getItem("Token");

      var config = {
        method: "delete",
        url: `${process.env.REACT_APP_URL}api/documentos/${contratosID.cc}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + tokenCC,
        },
      };

      axios(config)
        .then(function (response) {
          console.log("ContratoCC borrado:", response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const activarProcesoCDO = (idContratoCDO) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idContratoCDO +
      "/enable";
    var config = {
      method: "get",
      url: URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + tokenCDO,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Contrato CDO activado:", response.data);
        modificarStatusCC(contratosID.cc, 2);
        setModalFirmante(false);
        setModalEnviado(true);
        //handleGoBackHome();
      })
      .catch(function (error) {
        console.log(error);
        alert("EL CONTRATO DEBE TENER AL MENOS UN FIRMANTE");
      });
  };

  function modificarStatusCC(idContratoCC, newStatus) {
    //let tokenCC = localStorage.getItem("Token");
    let tokenCC = `${process.env.REACT_APP_TOKEN}`;

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${idContratoCC}?documento_status_id=${newStatus}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("ContratoCC Actualizado:", response.data);
        console.log(newStatus);
        //window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function eliminarFCC(id) {
    console.log("entro a eliminarFCC");
    let tokenCC = localStorage.getItem("Token");

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_URL}api/firmantes/${id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("FirmanteCC eliminado:", response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //const eliminarFirmanteCC = (contratoCC, emailfirmante) => {
  const eliminarFirmanteCC = (contratoCC, rfc) => {
    console.log("entro a eliminarFirmanteCC");
    let tokenCC = localStorage.getItem("Token");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${contratoCC}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        var firmantesCC = response.data.firmantes;
        const found = firmantesCC.find((element) => element.rfc === rfc);
        console.log("found firmante:", found);
        if (found) {
          eliminarFCC(found._id);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const eliminarFirmanteCDO = (idfirmante) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/firmante/" +
      idfirmante +
      "/delete";
    var config = {
      method: "get",
      url: URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + tokenCDO,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Eliminado Firmante CDO:", response.data);
        bucarFirmantesCDO(contratosID.cdo);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function bucarFirmantesCDO(contratoCDO_id) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      contratoCDO_id +
      "/firmante/lista";
    var config = {
      method: "get",
      url: URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + tokenCDO,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Firmantes CDO:", response.data);
        setListaFirmantes(response.data);
        if (response.data.length === 0) {
          setEnviar(true);
        } else {
          setEnviar(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getPlantillas() {
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/plantillas`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("Plantillas:", response.data);
        let data = response.data;
        let filtradas = data.filter((p) => p.default != "true");
        setPlantillas(filtradas);
      })
      .catch(function (error) {
        console.log(error);
        setPlantillas([]);
      });
  }

  function getPlantillasDefault() {
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/plantillas/default/get`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("Plantillas Default:", response.data);
        setPlantillasDefault(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setPlantillasDefault([]);
      });
  }

  function limpiarformato(html) {
    var str = html;
    var highlight = html;
    var part;
    var fields = [];

    do {
      part = str.substring(str.lastIndexOf("[") + 1, str.lastIndexOf("]"));
      str = str.replace(
        "<span style='background-color:yellow'>[" + part + "]</span>",
        ""
      );
      highlight = highlight.replace(
        "<span style='background-color:yellow'>[" + part + "]</span>",
        "[" + part + "]"
      );
    } while (part !== "");

    return highlight;
  }

  function agregarcampos(item) {
    let newDate = new Date();
    var str = item.html + "<font color='white'>" + newDate + "</font>";

    for (var i = 0; i < item.fields.length; i++) {
      //str = str.replace("<span style='background-color:yellow'>["+item.fields[i]+"]</span>","<b>["+item.fields[i]+"]</b>");
      str = str.replaceAll("[" + item.fields[i] + "]", data[i]);
    }
    console.log(str);
    return str;
  }

  function updatePlantilla(id) {
    var str = content;
    var part;
    var fields = [];
    do {
      part = str.substring(str.lastIndexOf("[") + 1, str.lastIndexOf("]"));
      console.log("CAMPO:", part);
      if (part != "") {
        fields.push(part);
      }
      str = str.replace("[" + part + "]", "");
    } while (part !== "");

    let token = localStorage.getItem("Token");

    var FormData = require("form-data");
    var data = new FormData();
    data.append("name", nombre);
    data.append("html", content);
    fields = fields.reverse();
    let original = fields;
    fields = [...new Set(fields)];
    //verificar si tiene repetidos
    if (fields.length !== original.length) {
      setOpenGuardado(true);
      setMensaje("Existen campos variables que se repiten en el documento");
      setSeverity("info");
    }
    //fin verificar
    for (var i = 0; i < fields.length; i++) {
      data.append("fields[]", fields[i]);
    }
    if (fields.length === 0) {
      data.append("fields[]", "");
    }
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/plantillas/modificar/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        //setOpenGuardado(true);
        //setSeverity("info");
        //setMensaje("Plantilla actualizada");
        getPlantillas();
        getPlantillasDefault();
      })
      .catch(function (error) {
        console.log(error);
        setOpenGuardado(true);
        setSeverity("error");
        setMensaje("Error al modificar la plantilla. Acceso no autorizado");
      });
  }

  function deletePlantilla(id) {
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_URL}api/plantillas/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("Delete:", response.data);
        getPlantillas();
        getPlantillasDefault();
      })
      .catch(function (error) {
        console.log(error);
        setOpenGuardado(true);
        setSeverity("warning");
        setMensaje("Error al eliminar plantilla. Acceso no autorizado");
      });
  }

  async function crearDocumentoFirmaAvanzada(item) {
    var html = await agregarcampos(item);

    var FormData = require("form-data");
    var dataForm = new FormData();
    if (nombreArchivo) {
      dataForm.append("filename", nombreArchivo + ".pdf");
    } else {
      dataForm.append("filename", item.name + ".pdf");
    }
    dataForm.append("html", html);
    dataForm.append("documento_types_id", 3);

    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/plantillas/avanzada`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: dataForm,
    };

    return new Promise(function (resolve, reject) {
      axios(config).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async function crearDocumento(item) {
    console.log("campos", item.fields);
    console.log("data", data);
    var html = await agregarcampos(item);

    var FormData = require("form-data");
    var dataForm = new FormData();

    if (nombreArchivo) {
      dataForm.append("filename", nombreArchivo + ".pdf");
    } else {
      dataForm.append("filename", item.name + ".pdf");
    }
    dataForm.append("html", html);
    switch (selectedValue) {
      case "1":
        dataForm.append("documento_types_id", 1);
        setIdprocces("1");
        break;

      case "2":
        dataForm.append("documento_types_id", 2);
        setIdprocces("2");
        break;

      case "3":
        dataForm.append("documento_types_id", 8);
        setIdprocces("8");
        break;

      case "4":
        dataForm.append("documento_types_id", 7);
        setIdprocces("7");
        break;
    }

    for (var i = 0; i < item.fields.length; i++) {
      dataForm.append("fields[]", item.fields[i]);
      dataForm.append("linea[]", data[i]);
    }

    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/plantillas/create`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: dataForm,
    };

    return new Promise(function (resolve, reject) {
      axios(config).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  function handleCopiar() {
    console.log("copiar", item);
    var data = new FormData();
    data.append("name", item.name);
    data.append("html", item.html);
    for (var i = 0; i < item.fields.length; i++) {
      data.append("fields[]", item.fields[i]);
    }
    if (item.fields.length === 0) {
      data.append("fields[]", "");
    }

    let token = localStorage.getItem("Token");
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/plantillas`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        getPlantillas();
        getPlantillasDefault();
        setCopiar(false);
      })
      .catch(function (error) {
        console.log(error);
        setMensaje("Error copiando plantilla");
        setSeverity("error");
      });
  }

  function handleSave() {
    console.log("HTML", content);
    var str = content;
    var part;
    var fields = [];
    do {
      part = str.substring(str.lastIndexOf("[") + 1, str.lastIndexOf("]"));
      console.log("CAMPO:", part);
      if (part != "") {
        fields.push(part);
      }
      str = str.replace("[" + part + "]", "");
    } while (part !== "");
    console.log("CAMPOS:", fields);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("name", nombre);
    data.append("html", content);
    if (pordefecto) {
      data.append("default", true);
    }
    fields = fields.reverse();
    let original = fields;
    fields = [...new Set(fields)];

    //verificar si tiene repetidos
    if (fields.length !== original.length) {
      setOpenGuardado(true);
      setMensaje("Existen campos variables que se repiten en el documento");
      setSeverity("info");
    }
    //fin verificar

    for (var i = 0; i < fields.length; i++) {
      data.append("fields[]", fields[i]);
    }
    if (fields.length === 0) {
      data.append("fields[]", "");
    }
    let token = localStorage.getItem("Token");
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/plantillas`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        getPlantillas();
        getPlantillasDefault();
        setCrearNueva(false);
        setContent("");
        setNombre("");
        setPordefecto(false);
      })
      .catch(function (error) {
        console.log(error);
        //alert("Error creando plantilla");
        setOpenGuardado(true);
        setMensaje("Error creando plantilla");
        setSeverity("error");
      });
  }

  React.useEffect(() => {
    getPlantillas();
    getPlantillasDefault();
  }, []);

  const Textos = () => {
    return (
      <React.Fragment>
        {item.fields && item.fields[0]
          ? item.fields.map((_itm, index) => (
              <TextField
                key={index}
                label={_itm}
                value={data[index]}
                onChange={(e) => {
                  const { value, id } = e.target;
                  const newArray = [...data];
                  newArray[id] = value;
                  setData(newArray);
                  console.log(value);
                }}
                //onBlur={handleChange}
                name="name"
                id={index.toString()}
                variant="outlined"
              />
            ))
          : null}
      </React.Fragment>
    );
  };

  let disableFirmarMasiva = false;
  if (csv === "") {
    disableFirmarMasiva = true;
  }

  let disableFirmar = false;
  //console.log("disablefirmar",disableFirmar);
  //console.log("fields",item.fields)
  if (item.fields && item.fields[0]) {
    for (var i = 0; i < item.fields.length; i++) {
      if (item.fields[i] && data[i]) {
        //disableFirmar=true;
      } else {
        disableFirmar = true;
      }
    }
  }

  async function crearProcesoMasivo(item) {
    var FormData = require("form-data");
    var dataForm = new FormData();
    dataForm.append("csv", csv);
    if (nombreArchivo) {
      dataForm.append("filename", nombreArchivo + ".pdf");
    } else {
      dataForm.append("filename", item.name + ".pdf");
    }
    switch (selectedValue) {
      case "1":
        dataForm.append("documento_types_id", 1);
        break;
      case "2":
        dataForm.append("documento_types_id", 2);
        setIdprocces("1");
        break;
      case "3":
        dataForm.append("documento_types_id", 8);
        setIdprocces("2");
        break;
    }

    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/plantillas/masiva/${item._id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: dataForm,
    };

    return new Promise(function (resolve, reject) {
      axios(config).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          //alert("Archivo de Datos Inválido. No se puede realizar el proceso");
          setOpenGuardado(true);
          setMensaje(
            "Archivo de Datos Inválido. No se puede realizar el proceso"
          );
          setSeverity("error");
          reject(error);
        }
      );
    });
  }

  async function handleFirmarMasiva(item) {
    console.log("Firma", item);
    if (selectedValue === "5") {
      console.log("avanzada");
    } else {
      var docs = await crearProcesoMasivo(item);
      console.log(docs);
      setOpenGuardado(true);
      setMensaje("Ha culminado el envío del documento para Firma masiva");
      setSeverity("info");
    }
  }

  async function handleFirmar(item) {
    if (selectedValue === "5") {
      console.log("avanzada");
      var doc = await crearDocumentoFirmaAvanzada(item);
      console.log("Documento Avanzada", doc);
      setContratosID({
        cc: doc[1]._id,
        cdo: doc[1].cdo_contratos_id,
      });
      bucarFirmantesCDO(doc[1].cdo_contratos_id);
      //bucarNotificadosCDO(rowData.cdo_contratos_id);
      setModalFirmante(true);
    } else {
      var doc = await crearDocumento(item);
      console.log("Documento", doc);
      await setidDocumento(doc[1]._id);
      setabState(1);
      if (selectedValue === "4") {
        setOpenForm(2);
      } else {
        setOpenForm(1);
      }
    }
    setOpenGuardado(true);
    setSeverity("info");
    setMensaje("Documento Guardado en Registrados");
  }

  let disableform = true;
  if (nombre.length >= 3 && convertedText.length >= 3) {
    disableform = false;
  }

  return (
    <React.Fragment>
      {openForm === 1 ? (
        <GridContainer>
          <Components
            tabs={tabState}
            idDocumentotable={idDocumento}
            setOpenForm={setOpenForm}
            idprocces={idprocces}
          />
        </GridContainer>
      ) : null}

      {openForm === 2 ? (
        <ContainerAutoSign
          tabs={tabState}
          idDocumentotable={idDocumento}
          setOpenForm={setOpenForm}
        />
      ) : null}

      {openForm == 0 ? (
        <React.Fragment>
          {miniaturas ? (
            <Tooltip title="Ver tabla" placement="top">
              <IconButton
                aria-label="settings"
                color="primary"
                variant="contained"
              >
                <TocIcon
                  onClick={() => {
                    setMiniaturas(false);
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Ver miniaturas" placement="top">
              <IconButton
                aria-label="settings"
                color="primary"
                variant="contained"
              >
                <AppsIcon
                  onClick={() => {
                    setMiniaturas(true);
                  }}
                />
              </IconButton>
            </Tooltip>
          )}

          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setCrearNueva(true);
            }}
            //style={{ backgroundColor: "#00acc1", color: "white" }}
          >
            Crear nueva plantilla
          </Button>
          <hr />
          {miniaturas && plantillasDefault.length > 0 ? (
            <>
              <AppBar position="static">
                <b>
                  <font style={{ margin: 10 }}>PLANTILLAS PREDETERMINADAS</font>
                </b>
              </AppBar>
              <br />
              {plantillasDefault.length > 0 ? (
                <ListarPlantillas
                  plantillas={plantillasDefault}
                  setAccion={setAccion}
                  setItem={setItem}
                  setMostrar={setMostrar}
                  setCrearNueva={setCrearNueva}
                  setConvertedText={setContent}
                  setNombre={setNombre}
                  miniaturas={miniaturas}
                  setCopiar={setCopiar}
                />
              ) : null}
            </>
          ) : null}
          {plantillas.length > 0 ? (
            <>
              <br />
              <AppBar position="static">
                <b>
                  <font style={{ margin: 10 }}>MIS PLANTILLAS</font>
                </b>
              </AppBar>
              <br />
              <ListarPlantillas
                plantillas={plantillas}
                setAccion={setAccion}
                setItem={setItem}
                setMostrar={setMostrar}
                setCrearNueva={setCrearNueva}
                setConvertedText={setContent}
                setNombre={setNombre}
                miniaturas={miniaturas}
              />
            </>
          ) : null}
        </React.Fragment>
      ) : null}

      {/*DIALOGO CREAR PLANTILLA*/}
      <Dialog
        open={crearNueva}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        fullScreen={true}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          CREAR NUEVA PLANTILLA
        </DialogTitle>
        <DialogContent>
          <TextField
            id="standard-full-width"
            label="Nombre de la Plantilla"
            style={{ margin: 8 }}
            placeholder="Ingrese el nombre"
            //helperText="Full width!"
            fullWidth
            margin="normal"
            style={{ width: "80%" }}
            onChange={(e) => {
              setNombre(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <br />
          <font color="red" size="1">
            <b>
              Agregar los campos variables que contendrá el documento entre [ ]
              y sin espacios
            </b>
          </font>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={(newContent) => {}}
          />
          {permissions ? marcaDefault(permissions) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className={classes.botondanger}
            onClick={() => {
              setNombre("");
              setContent("");
              setCrearNueva(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={disableform}
            className={classes.botongreen}
            onClick={() => {
              handleSave();
              //setCrearNueva(false);
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN CREAR PLANTILLA*/}

      {/*DIALOGO MOSTRAR PLANTILLA*/}
      <Dialog
        open={mostrar}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        fullScreen={false}
        fullWidth={true}
        maxWidth={accion === 1 ? "sm" : "lg"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {accion === 0 || accion === 3 ? item.name.toUpperCase() : null}
        </DialogTitle>
        <DialogContent>
          {accion === 0 ? (
            <div dangerouslySetInnerHTML={{ __html: item.html }}></div>
          ) : null}

          {accion === 1 ? (
            <React.Fragment>
              <h3 align="center">¿Está seguro de eliminar plantilla?</h3>
              <p align="center">
                <b>{item.name.toUpperCase()}</b>
              </p>
            </React.Fragment>
          ) : null}

          {accion === 2 ? (
            <React.Fragment>
              <TextField
                id="standard-full-width"
                label="Nombre de la Plantilla"
                style={{ margin: 8 }}
                placeholder="Ingrese el nombre"
                //helperText="Full width!"
                fullWidth
                margin="normal"
                style={{ width: "80%" }}
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={nombre}
              />
              <br />
              <font color="red" size="1">
                <b>
                  Agregar los campos variables que contendrá el documento entre
                  [ ] y sin espacios
                </b>
              </font>
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
              />
            </React.Fragment>
          ) : null}

          {accion === 3 ? (
            <React.Fragment>
              <TextField
                label={"Nombre del archivo"}
                value={nombreArchivo}
                onChange={(e) => {
                  setNombreArchivo(e.target.value);
                  console.log(e.target.value);
                }}
                name="nombreArchivo"
                variant="outlined"
                style={{ width: "100%" }}
              />
              <hr />
              <Typography variant="subtitle1" gutterBottom>
                <b>Selecciona el tipo de proceso</b>
              </Typography>
              <hr />
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="firma"
                  name="firma1"
                  value={selectedValue}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Firma simple con confirmación por código"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Firma simple con identificación"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Firma simple confirmación con casilla de verificación"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Manifestación unilateral"
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Firma electrónica avanzada"
                  />
                </RadioGroup>
              </FormControl>
              <hr />

              {item.fields && item.fields[0] ? (
                <React.Fragment>
                  <Typography variant="subtitle1" gutterBottom>
                    <b>Completa los campos</b>
                  </Typography>
                  <hr />
                </React.Fragment>
              ) : null}
              {/*JSON.stringify(data)*/}

              {item ? Textos() : null}
            </React.Fragment>
          ) : null}

          {accion === 4 ? (
            <React.Fragment>
              <TextField
                label={"Nombre del archivo"}
                value={nombreArchivo}
                onChange={(e) => {
                  setNombreArchivo(e.target.value);
                  console.log(e.target.value);
                }}
                name="nombreArchivo"
                variant="outlined"
                style={{ width: "100%" }}
              />
              <hr />
              <Typography variant="subtitle1" gutterBottom>
                <b>Selecciona el tipo de proceso</b>
              </Typography>
              <hr />
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="firma"
                  name="firma1"
                  value={selectedValue}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Firma simple con confirmación por código"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Firma simple con identificación"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Firma simple confirmación con casilla de verificación"
                  />
                  {/*
                <FormControlLabel value="4" control={<Radio />} label="Manifestación unilateral" />
                <FormControlLabel value="5" control={<Radio />} label="Firma electrónica avanzada" />
                */}
                </RadioGroup>
              </FormControl>
              <hr />
              <Typography variant="subtitle1" gutterBottom>
                <b>Formato para el llenado de los datos</b>
              </Typography>
              <hr />

              <Button
                variant="contained"
                color="secondary"
                component="span"
                //disabled={loading}
                //className={buttonClassname}
              >
                <CSVLink
                  data={contenido}
                  //headers={headers}
                  asyncOnClick={true}
                  onClick={(event, done) => {
                    let header = "";
                    for (var i = 0; i < item.fields.length; i++) {
                      header = header + item.fields[i] + ",";
                    }
                    console.log(header);
                    setContenido(header + contenido);
                    done(); // REQUIRED to invoke the logic of component
                  }}
                  filename={"formato-firma-masiva.csv"}
                  target="_blank"
                >
                  <Typography
                    variant="h6"
                    style={{ textTransform: "none", color: "white" }}
                  >
                    DESCARGA FORMATO
                  </Typography>
                </CSVLink>
              </Button>
              <hr />
              <Typography variant="subtitle1" gutterBottom>
                <b>Agrega el archivo .CSV con los datos completos</b>
              </Typography>
              <hr />
              <input
                accept=".csv"
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                name="PDF"
                onChange={onChangeCsv}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  //disabled={loading}
                  //className={buttonClassname}
                >
                  <Typography variant="h6" style={{ textTransform: "none" }}>
                    {csv ? csv.name : "CARGA ARCHIVO PREVIAMENTE LLENADO"}
                  </Typography>
                </Button>
              </label>
              <hr />
            </React.Fragment>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            //color="primary"
            className={classes.botondanger}
            onClick={() => {
              setMostrar(false);
              setData([]);
              setContent("");
              setNombre("");
              setCsv("");
              setContenido(
                `fir_name,fir_last_name,fir_notification_types_id,fir_email,fir_country_code,fir_phone,fir_company,fir_rol_code,fir_rol,fir_coord_page,fir_coord_x,fir_coord_y`
              );
            }}
            //style={{ backgroundColor: "#00acc1", color: "white" }}
          >
            CANCELAR
          </Button>

          {accion === 1 ? (
            <Button
              variant="contained"
              className={classes.botondanger}
              onClick={() => {
                deletePlantilla(item._id);
                setMostrar(false);
              }}
              //style={{ backgroundColor: "#00acc1", color: "white" }}
            >
              Eliminar
            </Button>
          ) : null}

          {accion === 2 ? (
            <Button
              variant="contained"
              //color="secondary"
              className={classes.botongreen}
              onClick={() => {
                updatePlantilla(item._id);
                setMostrar(false);
              }}
              //style={{ backgroundColor: "#00acc1", color: "white" }}
            >
              guardar
            </Button>
          ) : null}

          {accion === 3 ? (
            <Button
              variant="contained"
              color="primary"
              disabled={disableFirmar}
              onClick={() => {
                console.log("Firma", selectedValue);
                handleFirmar(item);
                setMostrar(false);
              }}
            >
              Enviar a Firma
            </Button>
          ) : null}

          {accion === 4 ? (
            <Button
              variant="contained"
              color="primary"
              disabled={disableFirmarMasiva}
              onClick={() => {
                handleFirmarMasiva(item);
                setMostrar(false);
              }}
            >
              Enviar a Firma Masiva
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      {/* FIN MOSTRAR PLANTILLA*/}

      {/*DIALOGO PROCESO INICIADO*/}
      <Dialog
        open={modalEnviado}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        fullScreen={false}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
        <DialogContent>
          <div>
            <Typography variant="h6" gutterBottom>
              Creación de proceso de firma exitoso, se notificará a los
              involucrados.
            </Typography>

            <div align="center">
              <img src={mensaje} alt="logo" width="260" height="220" />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            //className={classes.botondanger}
            onClick={() => {
              setModalEnviado(false);
              handleGoBackHome();
            }}
            //style={{ backgroundColor: "#00acc1", color: "white" }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN PROCESO INICIADO*/}

      {/*MODAL AGREGAR FIRMANTE*/}
      <Dialog
        open={modalFirmante}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"md"}
        onClose={() => {
          setModalFirmante(false);
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          AGREGAR FIRMANTE
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <Firmantes
            bucarFirmantesCDO={bucarFirmantesCDO}
            setEnviar={setEnviar}
            contrato={contratosID.cdo}
            contratoCC={contratosID.cc}
          />
          <hr />
          {enviar === false ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<PlayCircleFilledWhiteIcon />}
              onClick={() => {
                console.log(contratosID);
                activarProcesoCDO(contratosID.cdo);
                setModalFirmante(false);
              }}
            >
              Mandar a Firmar
            </Button>
          ) : null}

          {/* ESTO ES UNA PRUEBA*/}
          {listaFirmantes ? (
            <MaterialTable
              localization={localization}
              options={{
                exportButton: false,
                search: false,
                headerStyle: {
                  backgroundColor: "#009cde",
                  color: "#FFF",
                },
                pageSize: 5, // make initial page size
                pageSizeOptions: [5, 10, 20, 50],
                actionsColumnIndex: -1,
              }}
              columns={[
                { title: "NOMBRE Y APELLIDOS", field: "nombre" },
                { title: "CORREO", field: "email" },
                { title: "RFC", field: "rfc" },
              ]}
              //data={listaFirmantesCDO}
              data={listaFirmantes}
              title={"Firmantes"}
              actions={[
                {
                  icon: () => (
                    <DeleteIcon
                      className={
                        classes2.tableActionButtonIcon + " " + classes2.edit
                      }
                    />
                  ),
                  tooltip: "Eliminar firmante",
                  onClick: (event, rowData) => {
                    //console.log("eliminar CDO:", rowData);
                    //console.log("eliminar CC:", contratosID.cc);
                    eliminarFirmanteCDO(rowData._id);
                    eliminarFirmanteCC(contratosID.cc, rowData.rfc);
                  },
                },
              ]}
            />
          ) : null}
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <ButtonC
            variant="contained"
            color="danger"
            size="md"
            type="button"
            className={classes.button}
            style={{ backgroundColor: colorinfo, color: "white" }}
            onClick={() => {
              handleGoBackHome();
            }}
          >
            Guardar en registrados
          </ButtonC>
          <ButtonC
            variant="contained"
            color="danger"
            size="md"
            type="button"
            onClick={handleReset}
            className={classes.button}
          >
            Eliminar
          </ButtonC>
        </DialogActions>
      </Dialog>
      {/*FIN DIALOG AGREGAR FIRMANTE*/}

      {/*DIALOGO COPIAR PLANTILLA*/}
      <Dialog
        open={copiar}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        fullScreen={false}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          COPIAR PLANTILLA
        </DialogTitle>
        <DialogContent>
          ¿Esta Seguro de hacer un copia de la plantilla <b>{item.name}</b>?
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className={classes.botondanger}
            onClick={() => {
              setCopiar(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            className={classes.botongreen}
            onClick={() => {
              //handleSave();
              handleCopiar();
            }}
          >
            Copiar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN COPIAR PLANTILLA*/}

      {/*MENSAJE GUARDADO*/}
      <SnackbarMensaje
        open={openGuardado}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleCloseGuardado}
      >
        <Alert onClose={handleCloseGuardado} severity={severity}>
          {mensaje}
        </Alert>
      </SnackbarMensaje>
      {/*FIN MENSAJE GUARDADO*/}
    </React.Fragment>
  );
}
