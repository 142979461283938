/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Button from "@material-ui/core/Button";
// import ButtonC from "components/CustomButtons/Button.js";
// import AccessTime from "@material-ui/icons/AccessTime";
import DescriptionIcon from "@material-ui/icons/Description";
import GestureIcon from "@material-ui/icons/Gesture";
import DeleteIcon from "@material-ui/icons/Delete";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import FolderIcon from "@material-ui/icons/Folder";
import Fab from "@material-ui/core/Fab";
import NavigationIcon from "@material-ui/icons/Navigation";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import StoreLog from "containers/Logs/StoreLog.js";
//log
import { saveLog, saveLogCapture } from "containers/Logs/funcionesLog.js";
import ControlPanel from "components/controlPanel/ControlPanel";

import TextField from "@material-ui/core/TextField";
import localization from "components/localization/localization";
import axios from "axios";
import moment from "moment";
import { Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InfoIcon from "@material-ui/icons/Info";
import { useHistory } from "react-router-dom";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import PauseIcon from "@material-ui/icons/Pause";
import CancelIcon from "@material-ui/icons/Cancel";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import PeopleIcon from "@material-ui/icons/People";
import Paper from "@material-ui/core/Paper";
//import NoteAddIcon from "@material-ui/icons/NoteAdd";
import GetAppIcon from "@material-ui/icons/GetApp";
import StopIcon from "@material-ui/icons/Stop";
import EmailIcon from "@material-ui/icons/Email";
import { grayColor } from "assets/jss/material-dashboard-react.js";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Checkbox from "@material-ui/core/Checkbox";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Snackbar from "components/Snackbar/Snackbar.js";
import ErrorIcon from "@material-ui/icons/Error";
import { green } from "@material-ui/core/colors";
import Table from "components/Table/Table.js";
import ContainerAutoSign from "containers/autoSign/autoSingContainer";
import Archivos from "containers/archivo/archivo";
import VistaArchivos from "containers/archivo/vistaArchivos";
import Backdrop from "@material-ui/core/Backdrop";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Components from "containers/CheckoutNew";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

import { useSelector } from "react-redux";
import EditFlow from "containers/editFlow/editFlowContainer";

//table material

import MaterialTable from "material-table";

// import { dailySalesChart, emailsSubscriptionChart } from "variables/charts.js";
import Firmantes from "containers/FirmaAvanzada/Participantes";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Visibility } from "@material-ui/icons";
import { DialogComp } from "components/Dialog/DialogComp";

import NoteAddIcon from "@material-ui/icons/NoteAdd";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  fab: {
    display: "scroll",
    position: "fixed",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
const useStylestable = makeStyles(styles2);

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  root: {
    background:
      "  linear-gradient(to right top, #eee404, #f1e405, #f3e407, #f6e308, #f8e30a)",
    borderRadius: "3px",

    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    backgroundColor: grayColor[0],
    float: "left",
  },

  iconGrayColor: {
    backgroundColor: grayColor[0],
    borderRadius: "3px",

    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    float: "left",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  botoninfo: {
    backgroundColor: "#00acc1",
    color: "white",
  },

  botondanger: {
    backgroundColor: "red",
    color: "white",
  },

  botongreen: {
    backgroundColor: "green",
    color: "white",
  },
}));

export default function Dashboard() {
  //*****************************************************
  //    LLAMADAS A LA API DE CDO
  //******************************************************
  //credenciales API CDO
  const username = "ce2af9cab947ed15288e058ecd5350f5";
  const password = "9c9dd0db9b4a23eddf64b96e28b1214b";
  const token = Buffer.from(`${username}:${password}`, "utf8").toString(
    "base64"
  );
  const [archivoAlmacenar, setArchivoAlmacenar] = React.useState("");
  const PROXY_URL = "";
  const { permissions } = useSelector((state) => state.auth);
  const [idDeleteCDO, setidDeleteCDO] = useState("");
  const [modalFirmante, setModalFirmante] = useState(false);
  const [modalFirmantes, setModalFirmantes] = useState(false);
  const [modalFirmantesCC, setModalFirmantesCC] = useState(false);
  const [listaFirmantes, setListaFirmantes] = useState("");
  const [listaFirmantesCC, setListaFirmantesCC] = useState("");
  const [listaNotificados, setListaNotificados] = useState("");
  const [contratosID, setContratosID] = useState("");
  const [scale, setScale] = useState(1.3);
  const [enviar, setEnviar] = useState(true);

  const [openDialogEliminarFirmante, setOpenDialogEliminarFirmante] = useState(
    false
  );

  const [rowDataFirmante, setrowDataFirmante] = useState({});

  //modificar metadata
  const [openModificar, setOpenModificar] = React.useState(false);
  const [descripcion, setDescripcion] = React.useState("");

  const [diasAlert, setDiasAlert] = React.useState(0);
  const [desde, setDesde] = React.useState("");
  const [hasta, setHasta] = React.useState("");
  const [alerta, setAlerta] = React.useState("");
  const [diasAlerta, setDiasAlerta] = React.useState(0);
  const [checked, setChecked] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState("");
  const [idprocces, setIdprocces] = React.useState("");
  const [sendFolder, setSendFolder] = React.useState(false);
  const [openCarpetas, setOpenCarpetas] = React.useState(false);
  const [openAlmacenar, setOpenAlmacenar] = React.useState(false);

  const handleChangeBox = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      if (diasAlerta === 0 || diasAlerta === null) {
        setDiasAlert(30);
        setDiasAlerta(30);
      } else {
        setDiasAlert(diasAlerta);
      }
    } else {
      setDiasAlert(0);
      setDiasAlerta(0);
    }
  };

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };

  function handleModificar() {
    console.log("handleModificar NOT ARROW FUNCTION");
    //console.log("modificar",docuDataPerson);
    /*
  console.log(desde);
  console.log(hasta);
  console.log(diasAlerta);
  console.log(checked);
  */
    let tokenCC = localStorage.getItem("Token");
    //let tokenCC = `${process.env.REACT_APP_TOKEN}`;
    let urlmodificar = "";
    let desc = "";
    console.log("desde:", desde);
    console.log("hasta:", hasta);
    console.log("dias:", diasAlerta);

    if (descripcion != "" && descripcion != undefined) {
      desc = descripcion;
    }
    urlmodificar = "?description=" + desc;

    if (desde !== "" && desde !== undefined && desde !== "Invalid date") {
      urlmodificar =
        urlmodificar + "&meta[vigencia_start]=" + desde + "00:00:00";
    }
    if (desde === "" || desde === "Invalid date") {
      urlmodificar = urlmodificar + "&meta[vigencia_start]=";
      urlmodificar = urlmodificar + "&meta[vigencia_end]=";
    } else {
      if (hasta !== "" && hasta !== undefined && hasta !== "Invalid date") {
        urlmodificar =
          urlmodificar + "&meta[vigencia_end]=" + hasta + "23:59:59";
      }
      if (hasta === "" || hasta === "Invalid date") {
        urlmodificar = urlmodificar + "&meta[vigencia_end]=";
      }
    }

    if (checked) {
      urlmodificar = urlmodificar + "&meta[dias_alerta]=" + diasAlerta;
    } else {
      urlmodificar = urlmodificar + "&meta[dias_alerta]=";
    }

    //let modif=checked;

    /*
if(desc!=""){
  urlmodificar=urlmodificar+"?description="+desc;
}
*/

    /*
if(desde !=="" && desde!==undefined){
  if(desc!=""){
      urlmodificar = urlmodificar+"&meta[vigencia_start]="+desde + "00:00:00";
  }
  else{
    urlmodificar = urlmodificar+"?meta[vigencia_start]="+desde + "00:00:00";
  }
}
*/

    /*
if(hasta !=="" && hasta!==undefined){
  urlmodificar = urlmodificar+"&meta[vigencia_end]="+hasta + "23:59:59";
}
else{
  modif=false;
}

if (modif) {
  if(checked){
    urlmodificar = urlmodificar+"&meta[dias_alerta]="+diasAlerta;
  }
}
*/

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${docuDataPerson._id}${urlmodificar}`,
      //url : `${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("ContratoCC Actualizado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    //reload

    switch (valueState) {
      case 0:
        datatable();
        break;
      case 1:
        docuFirmados();
        break;
      case 2:
        docuProceso();
        break;
      case 3:
        docuRechazados();
        break;
      case 4:
        docuRegister();
        break;
    }

    setOpenModificar(false);
    setDescripcion("");
    setDesde("");
    setHasta("");
    setDiasAlerta(30);
  }

  //logs
  const [logStored, setlogStored] = useState(false);

  //fin logs

  const [datos, setDatos] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    rfc: "",
    compañia: "",
  });
  const [stopCDO, setStopCDO] = useState(false);
  const [idStopCDO, setIdstopCDO] = useState("");
  const [recordatorioCDO, setRecordatorioCDO] = useState(false);

  //FIRMANTES cdo
  const [listaFirmantesCDO, setListaFirmantesCDO] = useState([{}]);
  const actualizarFirmantes = (idcontrato) => {
    if (contratosID) {
      const URL =
        "https://api.certificaciondigital.online/api/contrato/" +
        idcontrato +
        "/firmante/lista";
      var config = {
        method: "get",
        url: PROXY_URL + URL,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Basic " + token,
        },
      };
      axios(config)
        .then(function (response) {
          console.log("Firmantes:", response.data);
          setListaFirmantesCDO(response.data);
          //        console.log("Participantes ",response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  //FIN FIRMANTES cdo

  //SINCRONIZAR CDO / CON CERTEZA

  const actualizarFirmante = (idfirmantecc, date_signed, date_notified) => {
    let tokenCC = `${process.env.REACT_APP_TOKEN}`;

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/firmantes/${idfirmantecc}?date_signed=${date_signed}&date_notified=${date_notified}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("Firmante CC Actualizado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const actualizarStatusFirmantes = (idcontratocc, firmantescdo) => {
    let token = localStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/documentos/${idcontratocc}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
      })
      .then(async (resp) => {
        var firmantescc = resp.data.firmantes;
        for (var i = 0; i < firmantescdo.length; i++) {
          for (var j = 0; j < firmantescc.length; j++) {
            if (firmantescdo[i].email === firmantescc[j].email) {
              var date_signed = moment(firmantescdo[i].fecha_firma).format(
                "YYYYMMDD HH:mm:ss"
              );
              var date_notified = moment(firmantescdo[i].fecha_inicio).format(
                "YYYYMMDD HH:mm:ss"
              );
              actualizarFirmante(
                firmantescc[j]._id,
                date_signed,
                date_notified
              );
            }
          }
        }
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  const actualizarContrato = (idcontratocc, idcontratocdo) => {
    console.log(idcontratocc, "->", idcontratocdo);
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idcontratocdo +
      "/get";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Contratos:", response.data);
        if (response.data.status.codigo === 2) {
          modificarStatusCCNorel(idcontratocc, 3);
          //modificar estatus firmantes
          actualizarStatusFirmantes(idcontratocc, response.data.firmantes);
        } else {
          if (response.data.status.codigo === 3) {
            modificarStatusCCNorel(idcontratocc, 4);
            //modificar estatus firmantes
            actualizarStatusFirmantes(idcontratocc, response.data.firmantes);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const actualizarStatusCC = (data) => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].cdo_contratos_id) {
        console.log("Encontre a:", data[i]);
        actualizarContrato(data[i]._id, data[i].cdo_contratos_id);
      }
    }
  };
  //FIN SINCRONIZAR

  //mostrar compañia que no se almacena en la plataforma CDO
  const companyCDO = (email) => {
    console.log("Firmantes CC", listaFirmantesCC);
    for (var i = 0; i < listaFirmantesCC.length; i++) {
      if (listaFirmantesCC[i].email === email) {
        return listaFirmantesCC[i].company;
      }
    }
    return null;
  };

  //para mostrar el estatus cuando un documento es rechazado
  const estatusCDO = (data) => {
    //console.log("estatus:",data);
    if (data.rechazo) {
      //return "Rechazó: " + data.motivo_rechazo;
      return "Rechazó";
    } else {
      if (data.firmo) {
        return "Firmó";
      } else {
        return "Pendiente de Firma";
      }
    }
  };

  const handleInputFirmante = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  //habilitar guardar firmante si los campos estan llenos
  let disableformfirmante = true;
  if (
    datos.nombre !== "" &&
    datos.apellidos !== "" &&
    datos.correo !== "" &&
    datos.rfc !== "" &&
    datos.compañia !== ""
  ) {
    disableformfirmante = false;
  }

  const activarProcesoCDO = (idContratoCDO) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idContratoCDO +
      "/enable";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Contrato CDO activado:", response.data);
        modificarStatusCC(contratosID.cc, 2);
      })
      .catch(function (error) {
        console.log(error);
        alert("EL CONTRATO DEBE TENER AL MENOS UN FIRMANTE");
      });
  };

  function modificarStatusCC(idContratoCC, newStatus) {
    //let tokenCC = localStorage.getItem("Token");
    let tokenCC = `${process.env.REACT_APP_TOKEN}`;

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${idContratoCC}?documento_status_id=${newStatus}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("ContratoCC Actualizado:", response.data);
        console.log(newStatus);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function modificarStatusCCNorel(idContratoCC, newStatus) {
    //let tokenCC = localStorage.getItem("Token");
    let tokenCC = `${process.env.REACT_APP_TOKEN}`;

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${idContratoCC}?documento_status_id=${newStatus}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("ContratoCC Actualizado:", response.data);
        console.log(newStatus);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleStopApiCDO = () => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idStopCDO +
      "/stop/" +
      comment;
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Contrato CDO detenido:", response.data);
        modificarStatusCC(idStop, 1);
        setopenStop(false);
      })
      .catch(function (error) {
        console.log(error);
        alert("NO ES POSIBLE DETENER EL PROCESO");
      });
  };

  const eliminarFirmanteCDO = (idfirmante) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/firmante/" +
      idfirmante +
      "/delete";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Eliminado Firmante CDO:", response.data);
        bucarFirmantesCDO(contratosID.cdo);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function eliminarFCC(id) {
    console.log("entro a eliminarFCC");
    let tokenCC = localStorage.getItem("Token");

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_URL}api/firmantes/${id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("FirmanteCC eliminado:", response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //const eliminarFirmanteCC = (contratoCC, emailfirmante) => {
  const eliminarFirmanteCC = (contratoCC, rfc) => {
    console.log("entro a eliminarFirmanteCC");
    let tokenCC = localStorage.getItem("Token");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${contratoCC}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        var firmantesCC = response.data.firmantes;
        const found = firmantesCC.find((element) => element.rfc === rfc);
        console.log("found firmante:", found);
        if (found) {
          eliminarFCC(found._id);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //GUARDAR FIRMANTES CDO
  function makePostRequestFirmantes(idContratoCDO) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idContratoCDO +
      "/firmante/save";

    const data = new FormData();
    data.append("nombre", datos.nombre + " " + datos.apellidos);
    data.append("rfc", datos.rfc);
    data.append("email", datos.correo);

    var urlDocumento = PROXY_URL + URL;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  //GUARDAR FIRMANTES CON CERTEZA
  function makePostRequestFirmantesCC(idContratoCC) {
    let tokenCC = localStorage.getItem("Token");
    console.log("idContratoCC", idContratoCC);
    const data = new FormData();
    data.append("documentos_id", idContratoCC);
    data.append("name", datos.nombre);
    data.append("last_name", datos.apellidos);
    data.append("notification_types_id", "1");
    data.append("email", datos.correo);
    data.append("role", "No Aplica");
    data.append("firmante_involvements_id", "0");
    data.append("company", datos.compañia);

    //var  urlDocumento= PROXY_URL+URL;
    var urlDocumento = "https://api.con-certeza.mx/api/firmantes";
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer  " + tokenCC,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async function registraFirmantes(idContratoCDO, idContratoCC) {
    //  console.log("registraFirmantes",idContratoCDO,idContratoCC);

    var result = await makePostRequestFirmantes(idContratoCDO);
    console.log(result);
    console.log("Firmante CDO OK");

    //    var idContratoCC = await buscarContratoCC(idContratoCDO);
    //    console.log("contratoCC:",idContratoCC);

    var resultCC = await makePostRequestFirmantesCC(idContratoCC);
    console.log(resultCC);
    console.log("Firmante CC OK");

    setDatos({
      nombre: "",
      apellidos: "",
      correo: "",
      //celular: "",
      rfc: "",
      compañia: "",
    });
  }

  function bucarFirmantesCDO(contratoCDO_id) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      contratoCDO_id +
      "/firmante/lista";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Firmantes CDO:", response.data);
        setListaFirmantes(response.data);
        if (response.data.length === 0) {
          setEnviar(true);
        } else {
          setEnviar(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function bucarNotificadosCDO(contratoCDO_id) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      contratoCDO_id +
      "/notificador/lista";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Notificados CDO:", response.data);
        setListaNotificados(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const eliminarContratoCDO = (idcontrato) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idcontrato +
      "/delete";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Contrato CDO eliminado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDescargarConstanciaCDO = (nombre, idConstancia) => {
    var nombre_archivo = nombre + ".zip";
    var URL =
      "https://api.certificaciondigital.online/api/constancia/solicitud/" +
      idConstancia +
      "/descargar";

    var config = {
      method: "get",
      url: PROXY_URL + URL,
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + token,
      },
    };
    axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleDescargarCDO = (nombre, status, id) => {
    var nombre_archivo = nombre;
    var URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      id +
      "/descargar-certificado";
    if (status === 3) {
      nombre_archivo = nombre + ".zip";
    } else {
      URL =
        "https://api.certificaciondigital.online/api/contrato/" +
        id +
        "/descargar";
    }

    var config = {
      method: "get",
      url: PROXY_URL + URL,
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + token,
      },
    };
    axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
    });
  };

  const aplicaFirmantes = (data) => {
    if (data.type.id === 5) {
      return <span>No Aplica</span>;
    }
    return <span>{data.firmantes_names}</span>;
  };

  const handleCloseCDO = () => {
    setStopCDO(false);
    setopenStop(false);
    setComment("");
  };
  //FIN LLAMADAS

  const classespro = useStylesprogrees();

  const classes2 = useStylestable();
  const history = useHistory();

  const [dataUsuarios, setDataUsuarios] = useState([]);
  const [idDocumento, setidDocumento] = useState("");
  const [idDocuDetenido, setidDocuDetenido] = useState("");
  const [comment, setComment] = useState("");
  const [disabledDetener, setdisabledDetener] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [permissionDetele, setpermissionDelete] = useState(false);
  const [openForm, setOpenForm] = useState(0);
  const [dataFirmados, setDataFirmados] = useState([]);
  const [dataEnProceso, setDataEnProceso] = useState([]);
  const [dataRechazados, setDataRechazados] = useState([]);
  const [docuData, setDocuData] = useState([]);
  const [openDialogInfo, setOpenDialogInfo] = useState(false);
  const [dataRegister, setDataRegister] = useState([]);
  const [openDialogFirmantes, setOpenDialogFirmantes] = useState(false);
  const [openFirmantesRechazados, setopenFirmantesRechazados] = useState(false);
  const [firmantes, setFirmantes] = useState([]);
  const [namefirmantes, setNameFirmantes] = useState([]);
  const [base64, setbase64] = useState("");
  const [tabState, setabState] = useState("");
  const [open, setOpen] = useState(true);
  const [openDelete, setopenDelete] = useState(false);
  const [openStop, setopenStop] = useState(false);
  const [idDelete, setidDelete] = useState("");
  const [openReminder, setopenReminder] = useState(false);
  const [idSend, setIdSend] = useState("");
  const [idStop, setIdstop] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfHeigth, setpdfHeigth] = useState(575);
  const [pdfWidth, setpdfWidth] = useState(660);
  const [tipoDoc, setTipoDoc] = useState("");

  const [docuDataPerson, setDocuDataPerson] = useState([]);

  const [openZip, setOpenZip] = useState(false);

  //componente Customtabs
  const [valueState, setValue] = useState(0);
  const [headerColor, setHeaderColor] = useState("info");
  const handleChange = (event, value) => {
    setValue(value);
    if (value == 0) {
      setHeaderColor("info");
    } else if (value == 1) {
      setHeaderColor("success");
    } else if (value == 2) {
      setHeaderColor("warning");
    } else if (value == 3) {
      setHeaderColor("danger");
    } else if (value == 4) {
      setHeaderColor("primary");
    }
  };

  function ShowMeta(props) {
    let mainData;
    if (typeof props.json === "string") {
      mainData = JSON.parse(props.json);
    } else {
      mainData = props.json;
    }
    return (
      <>
        {Object.keys(mainData).map((key, i) => (
          <p key={i}>
            <b>{key.toUpperCase()}:</b> {mainData[key]}
          </p>
        ))}
      </>
    );
  }

  React.useEffect(() => {
    getUsuarios();
    docuRegister();
    docuProceso();
    docuFirmados();
    docuRechazados();
    datatable();

    const permissionDelete = permissions.includes("documento.delete");
    if (permissionDelete == true) {
      console.log("si tiene delete");
      setpermissionDelete(true);
    } else if (permissionDelete == false) {
      setpermissionDelete(false);
      console.log("no tiene delete");
    }
    //actualizarStatusCC();
    // dataChart();
    if (isMobile()) {
      setpdfHeigth(500);
      setpdfWidth(290);
    }
    //Guardar log
    if (logStored === false) {
      let token = localStorage.getItem("Token");
      saveLog(token, "Dashboard");
      saveLogCapture(token, "Dashboard");
      setlogStored(true);
    }
    //fin log
  }, []);

  const Pointer = { cursor: "pointer" };
  const datatable = async () => {
    let token = localStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/documentos`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        // console.log("repuesta table", resp.data);

        const data = resp.data;
        data.map(function (item) {
          item.created_at = moment(item.created_at).format("DD-MM-YYYY");
          item.firmantes_names = item.firmantes_names.replace(/,/g, " , ");
          return item;
        });
        console.log("TOT:", data);
        setDocuData(data);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };
  const handleCloseFirmante = () => {
    setOpenDialogFirmantes(false);
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const handleOpenFirmantesRechazados = (data) => {
    setopenFirmantesRechazados(true);
    const ok = itemFirmanteRechazados(data.firmantes);
    setFirmantes(ok);
  };

  const handleOpenFirmantes = (data) => {
    setOpenDialogFirmantes(true);

    const ok = itemFirmanteFirmados(data.firmantes);
    setFirmantes(ok);
  };
  const itemFirmante = (data) => {
    console.log("data firmante");
    const values = Object.values(data).map((item) => [
      item.name,
      item.last_name,
      item.role,
      item.date_signed,
    ]);

    return values;
  };

  const itemFirmanteFirmados = (data) => {
    const values = Object.values(data).map((item) => [
      item.name,
      item.last_name,
      item.role,
      moment(item.date_signed).format("DD-MM-YYYY LT "),
      "MX",
    ]);

    return values;
  };

  const itemFirmanteRechazados = (data) => {
    const values = Object.values(data).map((item) => [
      item.name,
      item.last_name,
      item.role,
      item.refused_comment,
    ]);

    return values;
  };

  const handleOpenInfo = (data) => {
    setOpenDialogInfo(true);
    console.log("datainfo", data);
    setDocuDataPerson(data);
  };
  const handleCloseInfo = () => {
    console.log("handleCloseInfo");
    setOpenDialogInfo(false);
  };

  //obtener la lista de usuarios
  const getUsuarios = () => {
    //setDataRegister([]);
    let token = localStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/users`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
      })
      .then(async (resp) => {
        console.log("usuarios ", resp);
        setDataUsuarios(resp.data);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };
  //regresar nombre del usuario
  function findUser(userid) {
    const resultado = dataUsuarios.find((user) => user.id === userid);
    if (resultado) {
      return resultado.name;
    } else {
      return null;
    }
  }

  const docuRegister = () => {
    setDataRegister([]);
    let token = localStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/documentos/status/1`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        // console.log("Data Registrados ", resp);
        const data = resp.data;
        data.map(function (item) {
          item.created_at = moment(item.created_at).format("DD-MM-YYYY");
          item.firmantes_names = item.firmantes_names.replace(/,/g, " , ");
          return item;
        });
        setDataRegister(data);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };

  const docuProceso = () => {
    setDataEnProceso([]);
    let token = localStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/documentos/status/2`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        console.log("Data en proces ", resp.data);
        const data = resp.data;
        actualizarStatusCC(resp.data);
        data.map(function (item) {
          item.created_at = moment(item.created_at).format("DD-MM-YYYY");

          item.firmantes_names = item.firmantes_names.replace(/,/g, " , ");
          return item;
        });

        setDataEnProceso(data);
        console.log("ITEMPROCES", resp.data);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };

  const docuFirmados = () => {
    let token = localStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/documentos/status/3`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        const data = resp.data;
        data.map(function (item) {
          item.created_at = moment(item.created_at).format("DD-MM-YYYY");
          item.firmantes_names = item.firmantes_names.replace(/,/g, " , ");

          return item;
        });

        setDataFirmados(data);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };

  // funcion de peticion de documentos rechazados

  const docuRechazados = () => {
    let token = localStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/documentos/status/4`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        const data = resp.data;

        data.map(function (item) {
          item.created_at = moment(item.created_at).format("DD-MM-YYYY");
          item.firmantes_names = item.firmantes_names.replace(/,/g, " , ");

          return item;
        });

        setDataRechazados(data);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };

  const getTotalDownloadPdf = (data) => {
    console.log("DATA TYPE:", data.type.id);
    if (data.type.id === 3) {
      handleDescargarCDO(data.filename, data.status.id, data.cdo_contratos_id);
    } else {
      if (data.status.name === "firmado") {
        getZipDocument(data._id, data.filename);
      } else {
        //handleOpenPDF(data._id);
        handleDescargarPDF(data._id, data.filename);
      }
    }
  };

  //funcion para solo ver los pdf firmados y no firmados
  const getTotalVisualizarPdf = (data) => {
    if (data.status.name === "firmado") {
      handleOpenPDFFirmado(data._id, data.filename);
    } else {
      handleOpenPDF(data._id);
    }
  };

  //funcion para ver el pdf firmado
  const handleOpenPDFFirmado = async (idDocu) => {
    setOpenDialog(true);
    const token = `${process.env.REACT_APP_TOKEN}`;

    axios
      .get(
        `${process.env.REACT_APP_URL}api/documentos/${idDocu}/signed/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //funcion para descargar el paquete de constancia del documentos

  const getZipDocument = (idDocu, fileName) => {
    const nameFormat = fileName.slice(0, -4);
    setOpenZip(true);

    let token = localStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocu}/bundle/download`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nameFormat}.zip`);
      document.body.appendChild(link);
      link.click();
      setOpenZip(false);
    });
  };

  const handleDescargarPDF = async (idDocu, nombre) => {
    const token = localStorage.getItem("Token");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocu}/download`,
      //responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config).then((response) => {
      const linkSource = `data:application/pdf;base64,${response.data}`;
      const downloadLink = document.createElement("a");
      const fileName = nombre;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      /*
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
      */
    });
    /*
    const token = localStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDocu}/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
      */
  };

  // funcion de abrir el pdf no firmado
  const handleOpenPDF = async (idDocu) => {
    setOpenDialog(true);
    const token = localStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDocu}/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleclosepdf = () => {
    console.log("handleclosepdf!!!!!!!!!!1");
    setOpenDialog(false);
    setbase64("");
    setScale(1.3);
  };

  const handleOpenForm = async (data) => {
    console.log("datainfo", data.type.id);
    if (data.type.id == 7) {
      showNotificacion("No se pueden editar firmantes en autofirma");
    } else {
      setIdprocces(data.type.id);
      if (data.stopped_at) {
        setidDocuDetenido(data._id);
        setabState(1);
        await setOpenForm(3);
      } else {
        if (data.type.id == 7) {
          setidDocumento(data._id);
          setabState(1);
          console.log("auto-firma");
          setOpenForm(2);
        } else {
          setidDocumento(data._id);
          setabState(1);
          await setOpenForm(1);
        }
      }
    }
  };
  const classes = useStyles();
  const handleChangeTabs = (value) => {
    handleChange("eventCard", value);
  };

  const handleOpenDelete = (data) => {
    setidDeleteCDO(data.cdo_contratos_id);
    setidDelete(data._id);
    setopenDelete(true);
  };

  const handleDelete = () => {
    let token = localStorage.getItem("Token");
    saveLog(token, "Eliminar " + idDelete);
    saveLogCapture(token, "Eliminar " + idDelete);
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/documentos/${idDelete}`;
    console.log("URL DELETE");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log("res Delete", response);
        //eliminar contrato CDO
        if (idDeleteCDO) {
          eliminarContratoCDO(idDeleteCDO);
        }
        docuRegister();

        setopenDelete(false);
      })
      .catch((error) => {
        // Error 😨

        alert(error.data.message);
      });
    //eliminar contrato CDO
    if (idDeleteCDO) {
      eliminarContratoCDO(idDeleteCDO);
    }
  };

  const handleOpenStop = (Id) => {
    setIdstop(Id);
    setopenStop(true);
  };

  const handleStopApi = () => {
    setdisabledDetener(true);
    console.log("entro", stopCDO);
    if (stopCDO) {
      handleStopApiCDO();
    } else {
      const token = localStorage.getItem("Token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: " application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const urlStop = `${process.env.REACT_APP_URL}api/documentos/${idStop}/stop?comment=${comment}`;
      axios
        .put(urlStop, {}, config)
        .then(async (response) => {
          console.log("responseStop", response);
          setopenStop(false);
          docuProceso();
          docuRegister();
          setComment("");
        })
        .catch((error) => {
          // Error 😨
          alert(error.message);
          if (error.response) {
            setComment("");
            alert(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          console.log(error.config);
        });
    }
  };

  const handleInputComment = (event) => {
    setComment(event.target.value);
  };
  const handleOpenReminder = (Id) => {
    setopenReminder(true);
    setIdSend(Id);
  };

  const handleSendReminder = () => {
    let token = localStorage.getItem("Token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: " application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const urlSend = `${process.env.REACT_APP_URL}api/documentos/${idSend}/push`;
    axios
      .put(urlSend, {}, config)
      .then(async (response) => {
        console.log("REMINDER", response);
        setopenReminder(false);
      })
      .catch((error) => {
        // Error 😨
        setopenReminder(false);
        if (error.response) {
          console.log(error.response.data.message);
          alert(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const handleChangeDataDocuPerson = () => {
    setOpenDialogInfo(false);
    if (docuDataPerson.description !== "") {
      setDescripcion(docuDataPerson.description);
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.vigencia_start !== "" &&
      docuDataPerson.meta.vigencia_start !== undefined
    ) {
      setDesde(moment(docuDataPerson.meta.vigencia_start).format("YYYY-MM-DD"));
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.vigencia_end !== "" &&
      docuDataPerson.meta.vigencia_end !== undefined
    ) {
      setHasta(moment(docuDataPerson.meta.vigencia_end).format("YYYY-MM-DD"));
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.dias_alerta !== "" &&
      docuDataPerson.meta.dias_alerta !== undefined
    ) {
      setDiasAlerta(docuDataPerson.meta.dias_alerta);
    }
    setOpenModificar(true);
  };

  const handleCancelarActualizacion = () => {
    setOpenModificar(false);
    setDescripcion("");
    setDesde("");
    setHasta("");
    setDiasAlerta(30);
  };
  const handleInserFolder = (dataDocu) => {
    console.log("dataDocu", dataDocu);
    setidDocumento(dataDocu._id);
    setSendFolder(true);
  };

  const handleEliminarFirmante = () => {
    console.log("eliminar CDO:", rowDataFirmante);
    console.log("eliminar CC:", contratosID.cc);
    eliminarFirmanteCDO(rowDataFirmante._id);
    //eliminarFirmanteCC(contratosID.cc, rowDataFirmante.email);
    eliminarFirmanteCC(contratosID.cc, rowDataFirmante.rfc);
    setOpenDialogEliminarFirmante(false);
  };

  const handleCancelarEliminarFirmante = () => {
    setOpenDialogEliminarFirmante(false);
    setrowDataFirmante({});
  };

  const handleConfirmarEliminarFirmante = (rowData) => {
    setOpenDialogEliminarFirmante(true);
    setrowDataFirmante(rowData);
  };
  useEffect(() => {
    if (comment.length >= 5) {
      setdisabledDetener(false);
    } else {
      setdisabledDetener(true);
    }
  }, [comment]);

  function onChangeAlmacenar() {
    setArchivoAlmacenar(document.querySelector('input[type="file"]').files[0]);
    setOpenAlmacenar(true);
  }

  return (
    <div>
      {openForm == 0 ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div style={{ position: "relative", marginTop: -30 }}>
              <Fab
                variant="extended"
                color="primary"
                onClick={() => setOpenCarpetas(true)}
              >
                <FolderIcon style={{ marginRight: 2 }} />
                Carpetas
              </Fab>
            </div>

            <CustomTabs
              handleChange={handleChange}
              valueState={valueState}
              headerColor={headerColor}
              title=""
              tabs={[
                {
                  tabName: `Documentos (${docuData.length})`,
                  tabIcon: DescriptionIcon,
                  tabContent: (
                    <div>
                      <Typography
                        variant="h5"
                        style={{ textTransform: "none" }}
                      >
                        Todos los documentos ({docuData.length})
                      </Typography>
                      <MaterialTable
                        localization={localization}
                        title=""
                        columns={[
                          { title: "Nombre", field: "filename" },

                          { title: "Fecha", field: "created_at" },
                          {
                            title: "Estatus del Documento",
                            field: "status.name",
                            render: (row) => (
                              <span>
                                {row.reason && row.documento_status_id !== 4
                                  ? "detenido"
                                  : row.status.name}
                              </span>
                            ),
                          },
                          { title: "Tipo", field: "type.name" },
                          {
                            title: "Emisor",
                            field: "uid",
                            render: (row) => findUser(row.uid),
                          },

                          {
                            title: "Firmantes",
                            field: "firmantes_names",
                            render: (row) => aplicaFirmantes(row),
                          },

                          { title: "ID", field: "_id" },
                        ]}
                        data={docuData}
                        actions={[
                          {
                            icon: () => (
                              <GetAppIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Descargar Documento",
                            onClick: (event, rowData) => {
                              //Guardar log
                              let token = localStorage.getItem("Token");
                              saveLog(token, "Descargar " + rowData._id);
                              saveLogCapture(token, "Descargar " + rowData._id);
                              //fin log
                              if (rowData.type.id === 5) {
                                console.log("Descargar constanciaCDO");
                                console.log(rowData);
                                console.log(rowData.constancia);
                                var obj = JSON.parse(rowData.constancia);
                                //var obj = JSON.parse('{"id" : "b0b7aab4-6e7d-49e3-b932-66cb9d9ba6de", "hash" : "3000b2954437d06c35bc0e96063ac10b0debdaf072cc5303130acc088ad59460", "datetime" : "2021-02-19 16:00:28", "descripcion" : "Constancia NOM151 -CONTRATODJEMEPLO1902-3.pdf", "originalname" : "CONTRATODJEMEPLO1902-3.pdf"}');
                                //var obj = rowData.constancia;
                                //console.log(obj.descripcion,obj.id);
                                handleDescargarConstanciaCDO(
                                  obj.descripcion,
                                  obj.id
                                );
                              } else {
                                getTotalDownloadPdf(rowData);
                              }
                            },
                          },
                          {
                            icon: () => (
                              <FolderIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Guardar en Carpeta",
                            onClick: (event, rowData) => {
                              handleInserFolder(rowData);
                            },
                          },
                          {
                            icon: () => (
                              <VisibilityIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Ver documento",
                            onClick: (event, rowData) => {
                              if (rowData.cdo_contratos_id) {
                                handleOpenPDF(rowData._id);
                              } else {
                                getTotalVisualizarPdf(rowData);
                              }
                            },
                          },
                          (rowData) => ({
                            icon: () => (
                              <PeopleIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Participantes",
                            onClick: (event, rowData) => {
                              console.log("mostrar firmante", rowData);
                              if (rowData.type.id === 3) {
                                setContratosID({
                                  cc: rowData._id,
                                  cdo: rowData.cdo_contratos_id,
                                });
                                setListaFirmantesCC(rowData.firmantes);
                                bucarFirmantesCDO(rowData.cdo_contratos_id);
                                bucarNotificadosCDO(rowData.cdo_contratos_id);
                                setModalFirmantes(true);
                              } else {
                                // !ANTERIOR SALVADOR handleOpenTodosFirmantes(rowData);
                                setTipoDoc(rowData.type.id);
                                setListaFirmantes(rowData.firmantes);
                                setListaNotificados(rowData.notificados);
                                setModalFirmantesCC(true);
                              }
                            },
                            disabled: rowData.type.id === 5,
                          }),
                          (rowData) => ({
                            icon: () => (
                              <InfoIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Información del documento",
                            onClick: (event, rowData) =>
                              handleOpenInfo(rowData),
                          }),
                          //permissions.includes("documento.delete") === true
                          false
                            ? (rowData) => ({
                                icon: () => (
                                  <DeleteIcon
                                    className={
                                      classes2.tableActionButtonIcon +
                                      " " +
                                      classes2.edit
                                    }
                                  />
                                ),
                                tooltip: "Eliminar documento",
                                onClick: (event, rowData) => {
                                  handleOpenDelete(rowData);
                                },
                              })
                            : "",
                        ]}
                        options={{
                          pageSize: 20, // make initial page size
                          pageSizeOptions: [20, 50, 100],
                          actionsColumnIndex: -1,
                          exportButton: true,
                          exportAllData: true,
                        }}
                      />
                    </div>
                  ),
                },
                {
                  tabName: `firmados (${dataFirmados.length})`,
                  tabIcon: GestureIcon,
                  tabContent: (
                    <div>
                      <Typography
                        variant="h5"
                        style={{ textTransform: "none" }}
                      >
                        Documentos firmados ({dataFirmados.length})
                      </Typography>
                      <MaterialTable
                        localization={localization}
                        title=""
                        columns={[
                          { title: "Nombre", field: "filename" },

                          { title: "Fecha", field: "created_at" },
                          { title: "Tipo", field: "type.name" },
                          {
                            title: "Emisor",
                            field: "uid",
                            render: (row) => findUser(row.uid),
                          },
                          {
                            title: "Firmantes",
                            field: "firmantes_names",
                          },
                          { title: "ID", field: "_id" },
                        ]}
                        data={dataFirmados}
                        actions={[
                          {
                            icon: () => (
                              <GetAppIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Descargar PDF",
                            onClick: (event, rowData) => {
                              //Guardar log
                              let token = localStorage.getItem("Token");
                              saveLog(token, "Descargar " + rowData._id);
                              saveLogCapture(token, "Descargar " + rowData._id);
                              //fin log
                              if (rowData.type.id === 3) {
                                getTotalDownloadPdf(rowData);
                              } else {
                                getZipDocument(rowData._id, rowData.filename);
                              }
                            },
                          },

                          {
                            icon: () => (
                              <VisibilityIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Visualizar PDF",
                            onClick: (event, rowData) => {
                              if (rowData.cdo_contratos_id) {
                                handleOpenPDF(rowData._id);
                              } else {
                                handleOpenPDFFirmado(rowData._id);
                              }
                            },
                          },
                          (rowData) => ({
                            icon: () => (
                              <PeopleIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Participantes",
                            onClick: (event, rowData) => {
                              console.log("mostrar firmante CDO");
                              if (rowData.type.id === 3) {
                                setContratosID({
                                  cc: rowData._id,
                                  cdo: rowData.cdo_contratos_id,
                                });
                                setListaFirmantesCC(rowData.firmantes);
                                bucarFirmantesCDO(rowData.cdo_contratos_id);
                                bucarNotificadosCDO(rowData.cdo_contratos_id);
                                setModalFirmantes(true);
                              } else {
                                //anterior salvador handleOpenFirmantes(rowData);
                                setTipoDoc(rowData.type.id);
                                setListaFirmantes(rowData.firmantes);
                                setListaNotificados(rowData.notificados);
                                setModalFirmantesCC(true);
                              }
                            },
                            disabled: rowData.birthYear < 2000,
                          }),
                          (rowData) => ({
                            icon: () => (
                              <InfoIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Información del documento",
                            onClick: (event, rowData) =>
                              handleOpenInfo(rowData),
                            disabled: rowData.birthYear < 2000,
                          }),
                          //permissions.includes("documento.delete") === true
                          false
                            ? (rowData) => ({
                                icon: () => (
                                  <DeleteIcon
                                    className={
                                      classes2.tableActionButtonIcon +
                                      " " +
                                      classes2.edit
                                    }
                                  />
                                ),
                                tooltip: "Eliminar documento",
                                onClick: (event, rowData) => {
                                  handleOpenDelete(rowData);
                                },
                              })
                            : "",
                        ]}
                        options={{
                          pageSize: 20, // make initial page size
                          pageSizeOptions: [5, 20, 50, 100],
                          actionsColumnIndex: -1,
                          exportButton: true,
                          exportAllData: true,
                        }}
                      />
                    </div>
                  ),
                },
                {
                  tabName: `En espera (${dataEnProceso.length})`,
                  tabIcon: PauseIcon,
                  tabContent: (
                    <div>
                      <Typography
                        variant="h5"
                        style={{ textTransform: "none" }}
                      >
                        Documentos en espera ({dataEnProceso.length})
                      </Typography>
                      <MaterialTable
                        localization={localization}
                        title=""
                        columns={[
                          { title: "Nombre", field: "filename" },

                          { title: "Fecha", field: "created_at" },
                          { title: "Tipo", field: "type.name" },
                          {
                            title: "Emisor",
                            field: "uid",
                            render: (row) => findUser(row.uid),
                          },
                          {
                            title: "Firmantes",
                            field: "firmantes_names",
                          },

                          { title: "ID", field: "_id" },
                        ]}
                        data={dataEnProceso}
                        actions={[
                          {
                            icon: () => (
                              <EmailIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Enviar recordatorio",
                            onClick: (event, rowData) => {
                              if (rowData.type.id === 3) {
                                setRecordatorioCDO(true);
                              } else {
                                handleOpenReminder(rowData._id);
                              }
                            },
                          },
                          {
                            icon: () => (
                              <StopIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Detener proceso",
                            onClick: (event, rowData) => {
                              //Guardar log
                              let token = localStorage.getItem("Token");
                              saveLog(token, "Detener " + rowData._id);
                              saveLogCapture(token, "Detener " + rowData._id);
                              //setActividadLog("Detener "+rowData._id);
                              //setlogStored(false);
                              //fin log
                              if (rowData.type.id === 3) {
                                setStopCDO(true);
                                setIdstopCDO(rowData.cdo_contratos_id);
                                handleOpenStop(rowData._id);
                              } else {
                                handleOpenStop(rowData._id);
                              }
                            },
                          },
                          {
                            icon: () => (
                              <Visibility
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Ver PDF",
                            onClick: (event, rowData) =>
                              handleOpenPDF(rowData._id),
                          },
                          (rowData) => ({
                            icon: () => (
                              <PeopleIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Participantes",
                            onClick: (event, rowData) => {
                              console.log("mostrar firmantes", rowData);
                              if (rowData.type.id === 3) {
                                setContratosID({
                                  cc: rowData._id,
                                  cdo: rowData.cdo_contratos_id,
                                });
                                setListaFirmantesCC(rowData.firmantes);
                                bucarFirmantesCDO(rowData.cdo_contratos_id);
                                bucarNotificadosCDO(rowData.cdo_contratos_id);
                                setModalFirmantes(true);
                              } else {
                                setListaFirmantes(rowData.firmantes);
                                setListaNotificados(rowData.notificados);
                                setModalFirmantesCC(true);
                                //ANTERIOR SALVADOR openFirmantesProcess(rowData)
                              }
                            },
                            disabled: rowData.birthYear < 2000,
                          }),
                          (rowData) => ({
                            icon: () => (
                              <InfoIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Información del documento",
                            onClick: (event, rowData) =>
                              handleOpenInfo(rowData),
                            disabled: rowData.birthYear < 2000,
                          }),
                          //permissions.includes("documento.delete") === true
                          false
                            ? (rowData) => ({
                                icon: () => (
                                  <DeleteIcon
                                    className={
                                      classes2.tableActionButtonIcon +
                                      " " +
                                      classes2.edit
                                    }
                                  />
                                ),
                                tooltip: "Eliminar documento",
                                onClick: (event, rowData) => {
                                  handleOpenDelete(rowData);
                                },
                              })
                            : "",
                        ]}
                        options={{
                          pageSize: 20, // make initial page size
                          actionsColumnIndex: -1,
                          pageSizeOptions: [5, 20, 50, 100],
                          exportButton: true,
                          exportAllData: true,
                        }}
                      />
                    </div>
                  ),
                },
                {
                  tabName: `Rechazados (${dataRechazados.length})`,
                  tabIcon: CancelIcon,
                  tabContent: (
                    <div>
                      <Typography
                        variant="h5"
                        style={{ textTransform: "none" }}
                      >
                        Documentos rechazados ({dataRechazados.length})
                      </Typography>
                      <MaterialTable
                        localization={localization}
                        title=""
                        columns={[
                          { title: "Nombre", field: "filename" },

                          { title: "Fecha", field: "created_at" },
                          { title: "Tipo", field: "type.name" },
                          {
                            title: "Emisor",
                            field: "uid",
                            render: (row) => findUser(row.uid),
                          },
                          {
                            title: "Firmantes",
                            field: "firmantes_names",
                          },
                          { title: "ID", field: "_id" },
                        ]}
                        data={dataRechazados}
                        actions={[
                          {
                            icon: () => (
                              <Visibility
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Ver PDF",
                            onClick: (event, rowData) =>
                              handleOpenPDF(rowData._id),
                          },
                          (rowData) => ({
                            icon: () => (
                              <PeopleIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Participantes",
                            onClick: (event, rowData) => {
                              if (rowData.type.id === 3) {
                                setContratosID({
                                  cc: rowData._id,
                                  cdo: rowData.cdo_contratos_id,
                                });
                                setListaFirmantesCC(rowData.firmantes);
                                bucarFirmantesCDO(rowData.cdo_contratos_id);
                                bucarNotificadosCDO(rowData.cdo_contratos_id);
                                setModalFirmantes(true);
                              } else {
                                //handleOpenFirmantesRechazados(rowData);
                                setTipoDoc(rowData.type.id);
                                setListaFirmantes(rowData.firmantes);
                                setListaNotificados(rowData.notificados);
                                setModalFirmantesCC(true);
                              }
                            },
                            disabled: rowData.birthYear < 2000,
                          }),
                          (rowData) => ({
                            icon: () => (
                              <InfoIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Información del documento",
                            onClick: (event, rowData) =>
                              handleOpenInfo(rowData),
                            disabled: rowData.birthYear < 2000,
                          }),
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          exportButton: true,
                          exportAllData: true,
                          pageSize: 20, // make initial page size
                          pageSizeOptions: [5, 20, 50, 100],
                        }}
                      />
                    </div>
                  ),
                },
                {
                  tabName: `Registrados (${dataRegister.length})`,
                  tabIcon: OpenInBrowserIcon,
                  tabContent: (
                    <div>
                      <Typography
                        variant="h5"
                        style={{ textTransform: "none" }}
                      >
                        Documentos registrados ({dataRegister.length})
                      </Typography>
                      <MaterialTable
                        localization={localization}
                        title=""
                        columns={[
                          { title: "Nombre", field: "filename" },
                          {
                            title: "Estatus del Documento",
                            field: "status.name",
                            render: (row) => (
                              <span>
                                {row.reason ? "detenido" : row.status.name}
                              </span>
                            ),
                          },
                          { title: "Tipo", field: "type.name" },
                          {
                            title: "Emisor",
                            field: "uid",
                            render: (row) => findUser(row.uid),
                          },
                          { title: "Fecha", field: "created_at" },
                          { title: "ID", field: "_id" },
                        ]}
                        data={dataRegister}
                        actions={[
                          {
                            icon: () => (
                              <Visibility
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Ver PDF",
                            onClick: (event, rowData) =>
                              handleOpenPDF(rowData._id),
                          },
                          (rowData) => ({
                            icon: () => (
                              <PeopleIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: rowData.stopped_at
                              ? "Editar Firmantes"
                              : "Agregar firmantes",
                            onClick: (event, rowData) => {
                              if (rowData.type.id === 3) {
                                setContratosID({
                                  cc: rowData._id,
                                  cdo: rowData.cdo_contratos_id,
                                });
                                //actualizarFirmantes(rowData.cdo_contratos_id);
                                //setListaFirmantesCC(rowData.firmantes);
                                bucarFirmantesCDO(rowData.cdo_contratos_id);
                                //bucarNotificadosCDO(rowData.cdo_contratos_id);
                                setModalFirmante(true);
                              } else {
                                handleOpenForm(rowData);
                              }
                            },
                            disabled: rowData.type.id === 10,
                          }),
                          (rowData) => ({
                            icon: () => (
                              <InfoIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Información del documento",
                            onClick: (event, rowData) =>
                              handleOpenInfo(rowData),
                          }),
                          (rowData) => ({
                            icon: () => (
                              <DeleteIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Eliminar documento",
                            onClick: (event, rowData) => {
                              handleOpenDelete(rowData);
                            },
                            disabled: rowData.type.id === 10,
                          }),
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          exportButton: true,
                          exportAllData: true,
                          pageSize: 20, // make initial page size
                          pageSizeOptions: [5, 20, 50, 100],
                        }}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      ) : openForm == 1 ? (
        <GridContainer>
          <Components
            tabs={tabState}
            idDocumentotable={idDocumento}
            setOpenForm={setOpenForm}
            idprocces={idprocces}
          />
        </GridContainer>
      ) : openForm == 2 ? (
        <ContainerAutoSign
          tabs={tabState}
          idDocumentotable={idDocumento}
          setOpenForm={setOpenForm}
        />
      ) : openForm == 3 ? (
        <Components
          tabs={tabState}
          idDocumentoDetenido={idDocuDetenido}
          setOpenForm={setOpenForm}
          idprocces={idprocces}
        />
      ) : (
        ""
      )}
      {/* Dialogo de espera de descarga de paquete de documentos firmados */}
      <DialogComp
        open={openZip}
        title="Descarga de documento"
        content=" La carpeta con los documentos firmados se descargaran al lugar habitual de Descargas: Esto puede tardar unos segundos..."
        grid={true}
      />
      {/* <div>
        <Dialog
          open={openZip}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Descarga de documento"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography>
                La carpeta con los documentos firmados se descargaran al lugar
                habitual de Descargas: Esto puede tardar unos segundos...
              </Typography>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                {" "}
                <CircularProgress
                  size={44}
                  className={classespro.buttonProgress2}
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div> */}
      {/* Dialogo de ver datos de los firmantes */}
      <DialogComp
        open={openDialogFirmantes}
        onClose={handleCloseFirmante}
        title="Informacion de los firmantes"
        content={
          <div>
            <Table
              tableHeaderColor="success"
              tableHead={["Nombre", "Apellidos", "Actuación", "Firma"]}
              tableData={firmantes}
            />
          </div>
        }
        buttons={[
          {
            buttonEvent: handleCloseFirmante,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* <div>
        <Dialog
          open={openDialogFirmantes}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Informacion de los firmantes"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography>
                <div>
                  <div>
                    <Table
                      tableHeaderColor="success"
                      tableHead={["Nombre", "Apellidos", "Actuación", "Firma"]}
                      tableData={firmantes}
                    />
                  </div>
                </div>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFirmante} color="success">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div> */}
      {/* Dialogo de ver datos de los firmantes rechazados */}
      <DialogComp
        open={openFirmantesRechazados}
        onClose={() => setopenFirmantesRechazados(false)}
        title="Informacion de los firmantes"
        content={
          <div>
            <Table
              tableHeaderColor="success"
              tableHead={["Nombre", "Apellidos", "Actuación", "Comentario"]}
              tableData={firmantes}
            />
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setopenFirmantesRechazados(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* <div>
        <Dialog
          open={openFirmantesRechazados}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Informacion de los firmantes"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography>
                <div>
                  <div>
                    <Table
                      tableHeaderColor="success"
                      tableHead={[
                        "Nombre",
                        "Apellidos",
                        "Actuación",
                        "Comentario",
                      ]}
                      tableData={firmantes}
                    />
                  </div>
                </div>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setopenFirmantesRechazados(false)}
              color="success"
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div> */}
      {/* Dialogo de ver datos del documento info */}

      <DialogComp
        open={openDialogInfo}
        onClose={() => setopenFirmantesRechazados(false)}
        title="Información del documento"
        content={
          <div>
            <b>Nombre del documento:</b> {docuDataPerson.filename}
            <br></br>
            <hr></hr>
            <b>Compañia emisora:</b>{" "}
            {docuDataPerson.corporacion != null
              ? docuDataPerson.corporacion.name
              : ""}
            <br></br>
            <hr></hr>
            {docuDataPerson.description ? (
              <React.Fragment>
                <b>Descripción:</b> {docuDataPerson.description}
                <br></br>
                <hr></hr>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Descripción:</b> {""}
                <br></br>
                <hr></hr>
              </React.Fragment>
            )}
            {docuDataPerson.reason ? (
              <React.Fragment>
                <b>Fecha de detención:</b>{" "}
                {moment(docuDataPerson.stopped_at).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                <br></br>
                <b>Motivo:</b> {docuDataPerson.reason}
                <br></br>
                <hr></hr>
              </React.Fragment>
            ) : null}
            <b>Hash:</b> {docuDataPerson.hash}
            <br></br>
            <hr></hr>
            <b>ID el documento:</b> {docuDataPerson._id}
            <br></br>
            <hr></hr>
            <b>Tamaño de documento:</b> {docuDataPerson.size}
            <br></br>
            <hr></hr>
            {docuDataPerson.meta && docuDataPerson.meta.vigencia_start ? (
              <React.Fragment>
                <b>Inicio de Vigencia:</b>{" "}
                {moment(docuDataPerson.meta.vigencia_start).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                <br></br>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Inicio de Vigencia:</b> <br></br>
              </React.Fragment>
            )}
            {docuDataPerson.meta && docuDataPerson.meta.vigencia_end ? (
              <React.Fragment>
                <b>Término de Vigencia:</b>{" "}
                {moment(docuDataPerson.meta.vigencia_end).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                <br></br>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Término de Vigencia:</b> <br></br>
              </React.Fragment>
            )}
            {docuDataPerson.meta && docuDataPerson.meta.dias_alerta ? (
              <React.Fragment>
                <b>Alerta de Vencimiento:</b> {docuDataPerson.meta.dias_alerta}{" "}
                días
                <br></br>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Alerta de Vencimiento:</b> <br></br>
              </React.Fragment>
            )}
            {docuDataPerson.meta && docuDataPerson.meta.info ? (
              <React.Fragment>
                <hr />
                <font color="black">
                  <b>Metadatos adicionales:</b>
                </font>
                <hr />
                <ShowMeta json={docuDataPerson.meta.info} />
              </React.Fragment>
            ) : null}
          </div>
        }
        buttons={[
          {
            buttonEvent: handleCloseInfo,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleChangeDataDocuPerson,
            buttonName: "Modificar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* <div>
        <Dialog
          open={openDialogInfo}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Información del documento"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography>
                <div>
                  <b>Nombre del documento:</b> {docuDataPerson.filename}
                  <br></br>
                  <hr></hr>
                  <b>Compañia emisora:</b>{" "}
                  {docuDataPerson.corporacion != null
                    ? docuDataPerson.corporacion.name
                    : ""}
                  <br></br>
                  <hr></hr>
                  {docuDataPerson.description ? (
                    <React.Fragment>
                      <b>Descripción:</b> {docuDataPerson.description}
                      <br></br>
                      <hr></hr>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <b>Descripción:</b> {""}
                      <br></br>
                      <hr></hr>
                    </React.Fragment>
                  )}
                  {docuDataPerson.reason ? (
                    <React.Fragment>
                      <b>Fecha de detención:</b>{" "}
                      {moment(docuDataPerson.stopped_at).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                      <br></br>
                      <b>Motivo:</b> {docuDataPerson.reason}
                      <br></br>
                      <hr></hr>
                    </React.Fragment>
                  ) : null}
                  <b>Hash:</b> {docuDataPerson.hash}
                  <br></br>
                  <hr></hr>
                  <b>ID el documento:</b> {docuDataPerson._id}
                  <br></br>
                  <hr></hr>
                  <b>Tamaño de documento:</b> {docuDataPerson.size}
                  <br></br>
                  <hr></hr>
                  {docuDataPerson.meta && docuDataPerson.meta.vigencia_start ? (
                    <React.Fragment>
                      <b>Inicio de Vigencia:</b>{" "}
                      {moment(docuDataPerson.meta.vigencia_start).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                      <br></br>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <b>Inicio de Vigencia:</b> <br></br>
                    </React.Fragment>
                  )}
                  {docuDataPerson.meta && docuDataPerson.meta.vigencia_end ? (
                    <React.Fragment>
                      <b>Término de Vigencia:</b>{" "}
                      {moment(docuDataPerson.meta.vigencia_end).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                      <br></br>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <b>Término de Vigencia:</b> <br></br>
                    </React.Fragment>
                  )}
                  {docuDataPerson.meta && docuDataPerson.meta.dias_alerta ? (
                    <React.Fragment>
                      <b>Alerta de Vencimiento:</b>{" "}
                      {docuDataPerson.meta.dias_alerta} días
                      <br></br>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <b>Alerta de Vencimiento:</b> <br></br>
                    </React.Fragment>
                  )}
                </div>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseInfo}
              variant="contained"
              color="primary"
            >
              Cerrar
            </Button>
            <Button
              onClick={() => {
                setOpenDialogInfo(false);
                if (docuDataPerson.description !== "") {
                  setDescripcion(docuDataPerson.description);
                }
                if (
                  docuDataPerson.meta &&
                  docuDataPerson.meta.vigencia_start !== "" &&
                  docuDataPerson.meta.vigencia_start !== undefined
                ) {
                  setDesde(
                    moment(docuDataPerson.meta.vigencia_start).format(
                      "YYYY-MM-DD"
                    )
                  );
                }
                if (
                  docuDataPerson.meta &&
                  docuDataPerson.meta.vigencia_end !== "" &&
                  docuDataPerson.meta.vigencia_end !== undefined
                ) {
                  setHasta(
                    moment(docuDataPerson.meta.vigencia_end).format(
                      "YYYY-MM-DD"
                    )
                  );
                }
                if (
                  docuDataPerson.meta &&
                  docuDataPerson.meta.dias_alerta !== "" &&
                  docuDataPerson.meta.dias_alerta !== undefined
                ) {
                  setDiasAlerta(docuDataPerson.meta.dias_alerta);
                }
                setOpenModificar(true);
              }}
              variant="contained"
              className={classespro.botoninfo}
            >
              Modificar
            </Button>
          </DialogActions>
        </Dialog>
      </div> */}
      {/* Dialogo de para ver los pdf */}
      <DialogComp
        open={openDialog}
        onClose={handleclosepdf}
        title="Tu documento"
        maxWidth={"md"}
        content={
          base64.length === 0 ? (
            <Backdrop className={classespro.backdrop} open={open}>
              <CircularProgress
                size={44}
                className={classespro.buttonProgress}
              />
            </Backdrop>
          ) : (
            <div align="center">
              <ControlPanel
                scale={scale}
                setScale={setScale}
                numPages={numPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                file={`data: application / pdf; base64, ${base64} `}
              />
              <Document
                error="Error al intentar cargar el PDF"
                loading="Cargando..."
                file={`data: application / pdf; base64, ${base64} `}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  scale={scale}
                  height={pdfHeigth}
                  width={pdfWidth}
                  renderTextLayer={false}
                  pageNumber={pageNumber}
                />
              </Document>

              <div className="text-center">
                <p>
                  Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                  {numPages || "--"}
                </p>
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  Atrás
                </button>{" "}
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  Siguiente
                </button>
              </div>
            </div>
          )
        }
        buttons={[
          {
            buttonEvent: handleclosepdf,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* <div>
        <Dialog
          open={openDialog}
          maxWidth={"md"}
          // fullWidth={true}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Tu documento"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {base64.length === 0 ? (
                <Backdrop className={classespro.backdrop} open={open}>
                  <CircularProgress
                    size={44}
                    className={classespro.buttonProgress}
                  />
                </Backdrop>
              ) : (
                <div align="center">
                  <ControlPanel
                    scale={scale}
                    setScale={setScale}
                    numPages={numPages}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    file={`data: application / pdf; base64, ${base64} `}
                  />
                  <Document
                    error="Error al intentar cargar el PDF"
                    loading="Cargando..."
                    file={`data: application / pdf; base64, ${base64} `}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      scale={scale}
                      height={pdfHeigth}
                      width={pdfWidth}
                      renderTextLayer={false}
                      pageNumber={pageNumber}
                    />
                  </Document>

                  <div className="text-center">
                    <p>
                      Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                      {numPages || "--"}
                    </p>
                    <button
                      className="btn btn-dark"
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                    >
                      Atrás
                    </button>{" "}
                    <button
                      className="btn btn-dark"
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleclosepdf} color="success">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div> */}
      {/* Dialogo para eliminar el documento  */}
      <DialogComp
        open={openDelete}
        onClose={() => setopenDelete(false)}
        title="Eliminar documento"
        content="¿Está seguro de eliminar este documento?"
        buttons={[
          {
            buttonEvent: () => setopenDelete(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleDelete,
            buttonName: "Eliminar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* <div>
        <Dialog
          // fullWidth={true}
          // maxWidth="md"

          open={openDelete}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Eliminar documento"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography variant="subtitle1" style={{ textTransform: "none" }}>
                ¿Está seguro de eliminar este documento?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setopenDelete(false)}>Cerrar</Button>
            <Button onClick={handleDelete} color="secondary">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      </div> */}
      {/* Dialogo para modificar metadata  */}
      <DialogComp
        open={openModificar}
        onClose={() => setopenDelete(false)}
        title="Modificar datos"
        content={
          <>
            <Container maxWidth="xs">
              <Box width={1} bgcolor="grey.300" p={1} my={0.5}>
                <Paper elevation={3} style={{ padding: 20 }}>
                  <Grid container>
                    <Grid item xs={6} sm={12}>
                      <p align="center">
                        <b>Información general del documento</b>
                      </p>
                      <hr />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      Descripción:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                        maxRows={4}
                        inputProps={{
                          maxlength: 360,
                        }}
                        placeholder=""
                        helperText={descripcion && `${descripcion.length}/360`}
                        value={descripcion}
                        //style={{ width: "100%" }}
                        onChange={(e) => {
                          console.log("descripcion", e.target.value); // Works
                          setDescripcion(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      Inicio vigencia:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="dateDesde"
                        type="date"
                        value={desde}
                        onChange={(e) => {
                          console.log("inicio", e.target.value); // Works
                          //setInicio(e.target.value);
                          setDesde(e.target.value);

                          if (e.target.value > hasta) {
                            setAlerta(
                              "La fecha de término debe ser superior a la fecha de inicio"
                            );
                          } else {
                            setAlerta("");
                          }
                          var date = new Date(e.target.value);
                          // var f = new Date();

                          // var hoy = new Date("Y-m-d");
                          if (date.getFullYear() > 2300) {
                            showNotificacion("La fecha de inicio es inválida");
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      Término vigencia:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="dateHasta"
                        type="date"
                        value={hasta}
                        onChange={(e) => {
                          console.log("termino", e.target.value); // Works
                          setHasta(e.target.value);

                          if (e.target.value < desde) {
                            //alert("La fecha de término debe ser superior a la fecha de inicio")
                            setAlerta(
                              "La fecha de término debe ser superior a la fecha de inicio"
                            );
                          } else {
                            setAlerta("");
                          }
                          var date = new Date(e.target.value);
                          if (date.getFullYear() > 2300) {
                            showNotificacion("La fecha de término es inválida");
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    {alerta ? (
                      <Grid item xs={12} sm={12}>
                        <font size="2" color="red">
                          <b>{alerta}</b>
                        </font>
                      </Grid>
                    ) : null}

                    {hasta ? (
                      <React.Fragment>
                        <Grid item xs={12} sm={2}></Grid>
                        <Grid
                          item
                          xs={12}
                          sm={7}
                          className={classes.textodiaslabel}
                        >
                          <Checkbox
                            checked={checked}
                            onChange={handleChangeBox}
                            color="default"
                            //inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          <font size="2" color="green">
                            Alerta de vencimiento:
                          </font>
                        </Grid>
                        <Grid item xs={12} sm={2} className={classes.textodias}>
                          <TextField
                            //variant="outlined"
                            id="standard-number"
                            type="number"
                            value={diasAlerta}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              min: 1,
                              max: 90,
                              style: { fontSize: 15, color: "green" },
                            }}
                            style={{ width: "80%" }}
                            onChange={(e) => {
                              console.log("días", e.target.value); // Works
                              setDiasAlerta(e.target.value);
                              setDiasAlert(e.target.value);
                              if (e.target.value < 2 || e.target.value > 90) {
                                showNotificacion(
                                  "La alerta de vencimiento debe comprender de mínimo 2 a máximo 90 días."
                                );
                                setDiasAlerta(30);
                                setDiasAlert(30);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={1} className={classes.textodias}>
                          <font size="2" color="green">
                            días
                          </font>
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </Grid>
                </Paper>
              </Box>
            </Container>
          </>
        }
        buttons={[
          {
            buttonEvent: handleCancelarActualizacion,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleModificar,
            buttonName: "Modificar",
            buttonColor: "primary",
          },
        ]}
      />

      {/* <div>
        <Dialog
          // fullWidth={true}
          // maxWidth="md"
          open={openModificar}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Modificar datos"}
          </DialogTitle>
          <DialogContent>
            //METADATA
            <Container maxWidth="xs">
              <Box width={1} bgcolor="grey.300" p={1} my={0.5}>
                <Paper elevation={3} style={{ padding: 20 }}>
                  <Grid container>
                    <Grid item xs={6} sm={12}>
                      <p align="center">
                        <b>Información general del documento</b>
                      </p>
                      <hr />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      Descripción:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        rowsMin={2}
                        maxlength="360"
                        placeholder=""
                        value={descripcion}
                        //style={{ width: "100%" }}
                        onChange={(e) => {
                          console.log("descripcion", e.target.value); // Works
                          setDescripcion(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      Inicio vigencia:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="dateDesde"
                        type="date"
                        value={desde}
                        onChange={(e) => {
                          console.log("inicio", e.target.value); // Works
                          //setInicio(e.target.value);
                          setDesde(e.target.value);

                          if (e.target.value > hasta) {
                            setAlerta(
                              "La fecha de término debe ser superior a la fecha de inicio"
                            );
                          } else {
                            setAlerta("");
                          }
                          var date = new Date(e.target.value);
                          var f = new Date();

                          var hoy = new Date("Y-m-d");
                          if (date.getFullYear() > 2300) {
                            showNotificacion("La fecha de inicio es inválida");
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      Término vigencia:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="dateHasta"
                        type="date"
                        value={hasta}
                        onChange={(e) => {
                          console.log("termino", e.target.value); // Works
                          setHasta(e.target.value);

                          if (e.target.value < desde) {
                            //alert("La fecha de término debe ser superior a la fecha de inicio")
                            setAlerta(
                              "La fecha de término debe ser superior a la fecha de inicio"
                            );
                          } else {
                            setAlerta("");
                          }
                          var date = new Date(e.target.value);
                          if (date.getFullYear() > 2300) {
                            showNotificacion("La fecha de término es inválida");
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    {alerta ? (
                      <Grid item xs={12} sm={12}>
                        <font size="2" color="red">
                          <b>{alerta}</b>
                        </font>
                      </Grid>
                    ) : null}

                    {hasta ? (
                      <React.Fragment>
                        <Grid item xs={12} sm={2}></Grid>
                        <Grid
                          item
                          xs={12}
                          sm={7}
                          className={classes.textodiaslabel}
                        >
                          <Checkbox
                            checked={checked}
                            onChange={handleChangeBox}
                            color="default"
                            //inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          <font size="2" color="green">
                            Alerta de vencimiento:
                          </font>
                        </Grid>
                        <Grid item xs={12} sm={2} className={classes.textodias}>
                          <TextField
                            //variant="outlined"
                            id="standard-number"
                            type="number"
                            value={diasAlerta}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              min: 1,
                              max: 90,
                              style: { fontSize: 15, color: "green" },
                            }}
                            style={{ width: "80%" }}
                            onChange={(e) => {
                              console.log("días", e.target.value); // Works
                              setDiasAlerta(e.target.value);
                              setDiasAlert(e.target.value);
                              if (e.target.value < 2 || e.target.value > 90) {
                                showNotificacion(
                                  "La alerta debe ser mínimo 2 y máximo 90 días"
                                );
                                setDiasAlerta(30);
                                setDiasAlert(30);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={1} className={classes.textodias}>
                          <font size="2" color="green">
                            días
                          </font>
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </Grid>
                </Paper>
              </Box>
            </Container>
            <Snackbar
              place="tc"
              color="danger"
              icon={ErrorIcon}
              message={messageError}
              open={openAlert}
              closeNotification={() => setOpenAlert(false)}
              close
            />
            //*FIN METADATA

          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenModificar(false);
                setDescripcion("");
                setDesde("");
                setHasta("");
                setDiasAlerta(30);
              }}
              className={classespro.botondanger}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleModificar();
              }}
              className={classespro.botongreen}
              disabled={false}
            >
              Modificar
            </Button>
          </DialogActions>
        </Dialog>
      </div> */}
      {/*fin fialogo para modificar metadata*/}
      {/* Dialogo para detener el proceso*/}

      <DialogComp
        open={openStop}
        title="Escriba el motivo por el cual quiere detener el proceso"
        onClose={handleCloseCDO}
        content={
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <TextField
              id="comment"
              label="Comentario"
              multiline
              rows={6}
              value={comment}
              variant="outlined"
              onChange={handleInputComment}
              inputProps={{ maxLength: 128 }}
            />
          </Grid>
        }
        buttons={[
          {
            buttonEvent: handleCloseCDO,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonDisabled: disabledDetener,
            buttonEvent: () => handleStopApi(),
            buttonName: "Detener",
            buttonColor: "primary",
          },
        ]}
      />

      {/* <div>
        <Dialog
          // fullWidth={true}
          // maxWidth="md"
          open={openStop}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {" Escriba el motivo por el cual quiere"} <br />{" "}
            {" detener el proceso"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <TextField
                  id="comment"
                  label="Comentario"
                  multiline
                  rows={6}
                  value={comment}
                  variant="outlined"
                  onChange={handleInputComment}
                  inputProps={{ maxLength: 128 }}
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setStopCDO(false);
                setopenStop(false);
                setComment("");
              }}
              color="primaryl"
            >
              Cerrar
            </Button>
            <Button
              disabled={disabledDetener}
              onClick={() => handleStopApi()}
              color="secondary"
            >
              detener
            </Button>
          </DialogActions>
        </Dialog>
      </div> */}
      {/* Dialogo para reenviar notificacion*/}

      <DialogComp
        open={openReminder}
        title="Reenviar notificacion"
        onClose={() => setopenDelete(false)}
        content=" El sistema reenviara un correo con la solicitud para firmar o
                confirmar segun sea el caso, al firmante en turno"
        buttons={[
          {
            buttonEvent: () => handleSendReminder(),
            buttonName: "Aceptar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* <div>
        <Dialog
          // fullWidth={true}
          // maxWidth="md"
          open={openReminder}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Reenviar notificacion"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography variant="subtitle1" style={{ textTransform: "none" }}>
                El sistema reenviara un correo con la solicitud para firmar o
                confirmar segun sea el caso, al firmante en turno
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleSendReminder()} color="succes">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div> */}
      {/*MODAL AGREGAR FIRMANTE*/}
      <DialogComp
        open={modalFirmante}
        fullScreen={false}
        fullWidth={true}
        onClose={() => {
          setModalFirmante(false);
        }}
        title="AGREGAR FIRMANTE"
        content={
          <>
            <Firmantes
              bucarFirmantesCDO={bucarFirmantesCDO}
              setEnviar={setEnviar}
              contrato={contratosID.cdo}
              contratoCC={contratosID.cc}
            />
            <hr />
            {enviar === false ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<PlayCircleFilledWhiteIcon />}
                onClick={() => {
                  console.log(contratosID);
                  activarProcesoCDO(contratosID.cdo);
                  setModalFirmante(false);
                }}
              >
                Mandar a Firmar
              </Button>
            ) : null}

            {/* ESTO ES UNA PRUEBA*/}
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  exportButton: false,
                  search: false,
                  headerStyle: {
                    backgroundColor: "#009cde",
                    color: "#FFF",
                  },
                  pageSize: 5, // make initial page size
                  pageSizeOptions: [5, 10, 20, 50],
                  actionsColumnIndex: -1,
                }}
                columns={[
                  { title: "NOMBRE Y APELLIDOS", field: "nombre" },
                  { title: "CORREO", field: "email" },
                  { title: "RFC", field: "rfc" },
                ]}
                //data={listaFirmantesCDO}
                data={listaFirmantes}
                title={"Firmantes"}
                actions={[
                  {
                    icon: () => (
                      <DeleteIcon
                        className={
                          classes2.tableActionButtonIcon + " " + classes2.edit
                        }
                      />
                    ),
                    tooltip: "Eliminar firmanteOlv",
                    onClick: (event, rowData) => {
                      handleConfirmarEliminarFirmante(rowData);
                    },
                  },
                ]}
              />
            ) : null}
          </>
        }
        buttons={[
          {
            buttonEvent: () => setModalFirmante(false),
            buttonName: "Aceptar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/*FIN DIALOG AGREGAR FIRMANTE*/}
      {/*MODAL MOSTRAR FIRMANTES CDO*/}

      <DialogComp
        open={modalFirmantes}
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {
          setModalFirmantes(false);
        }}
        title="AGREGAR FIRMANTE"
        content={
          <div>
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre y Apellidos", field: "nombre" },
                  { title: "RFC", field: "rfc" },
                  {
                    title: "Compañía",
                    field: "email",
                    render: (row) => <span>{companyCDO(row["email"])}</span>,
                  },
                  {
                    title: "Medio de Notificación",
                    field: "email",
                  },
                  {
                    title: "Estatus del Proceso",
                    field: "firmo",
                    render: (row) => <span>{estatusCDO(row)}</span>,
                  },
                  {
                    title: "Registrado",
                    field: "fecha_registro",
                    render: (row) => (
                      <span>
                        {row["fecha_registro"]
                          ? moment(row["fecha_registro"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Enviado",
                    field: "fecha_inicio",
                    render: (row) => (
                      <span>
                        {row["fecha_inicio"]
                          ? moment(row["fecha_inicio"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Aviso de Privacidad Aceptado",
                    field: "fecha_aceptacion",
                    render: (row) => (
                      <span>
                        {row["fecha_aceptacion"]
                          ? moment(row["fecha_aceptacion"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Visto",
                    field: "fecha_aceptacion",
                    render: (row) => (
                      <span>
                        {row["fecha_aceptacion"]
                          ? moment(row["fecha_aceptacion"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Firmado",
                    field: "fecha_firma",
                    render: (row) => (
                      <span>
                        {row["fecha_firma"]
                          ? moment(row["fecha_firma"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Comentarios",
                    field: "firmo",
                    render: (row) => <span>{row.motivo_rechazo}</span>,
                  },
                ]}
                data={listaFirmantes}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {"Firmantes"}
                  </Typography>
                }
              />
            ) : null}
            <hr />
            {listaNotificados ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre", field: "nombre" },
                  { title: "Email", field: "email" },
                ]}
                data={listaNotificados}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {"Notificados"}
                  </Typography>
                }
              />
            ) : null}
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setModalFirmantes(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* <Dialog
        open={modalFirmantes}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {
          setModalFirmantes(false);
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        ></DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre y Apellidos", field: "nombre" },
                  { title: "RFC", field: "rfc" },
                  {
                    title: "Compañía",
                    field: "email",
                    render: (row) => <span>{companyCDO(row["email"])}</span>,
                  },
                  {
                    title: "Medio de Notificación",
                    field: "email",
                  },
                  {
                    title: "Estatus del Proceso",
                    field: "firmo",
                    render: (row) => <span>{estatusCDO(row)}</span>,
                  },
                  {
                    title: "Registrado",
                    field: "fecha_registro",
                    render: (row) => (
                      <span>
                        {row["fecha_registro"]
                          ? moment(row["fecha_registro"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Enviado",
                    field: "fecha_inicio",
                    render: (row) => (
                      <span>
                        {row["fecha_inicio"]
                          ? moment(row["fecha_inicio"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Aviso de Privacidad Aceptado",
                    field: "fecha_aceptacion",
                    render: (row) => (
                      <span>
                        {row["fecha_aceptacion"]
                          ? moment(row["fecha_aceptacion"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Visto",
                    field: "fecha_aceptacion",
                    render: (row) => (
                      <span>
                        {row["fecha_aceptacion"]
                          ? moment(row["fecha_aceptacion"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Firmado",
                    field: "fecha_firma",
                    render: (row) => (
                      <span>
                        {row["fecha_firma"]
                          ? moment(row["fecha_firma"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Comentarios",
                    field: "firmo",
                    render: (row) => <span>{row.motivo_rechazo}</span>,
                  },
                ]}
                data={listaFirmantes}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {"Firmantes"}
                  </Typography>
                }
              />
            ) : null}
            <hr />
            {listaNotificados ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre", field: "nombre" },
                  { title: "Email", field: "email" },
                ]}
                data={listaNotificados}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {"Notificados"}
                  </Typography>
                }
              />
            ) : null}
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setModalFirmantes(false);
            }}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> */}
      {/*FIN DIALOG MOSTRAR FIRMANTES CDO*/}
      {/*MODAL MOSTRAR FIRMANTES CC*/}

      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />
      <DialogComp
        open={modalFirmantesCC}
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {
          setModalFirmantesCC(false);
        }}
        title="AGREGAR FIRMANTE"
        content={
          <div>
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  {
                    title: "Nombres",
                    field: "name",
                    render: (row) => (
                      <span>{row["name"] + " " + row["last_name"]}</span>
                    ),
                  },

                  {
                    title: "Prueba de vida",
                    field: "speech_required",
                    render: (row) => (
                      <span>
                        {row["speech_required"] == true ? (
                          <Tooltip
                            id="tooltip-top"
                            title="Se solicito prueba de vida"
                            placement="top"
                            classes={{
                              tooltip: classes2.tooltip,
                            }}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes2.tableActionButton}
                            >
                              <RecordVoiceOverIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          "No se solicito"
                        )}
                      </span>
                    ),
                  },
                  { title: "Compañía", field: "company" },
                  {
                    title: "Medio de Notificación",
                    field: "notification_types_id",
                    render: (row) => (
                      <span>
                        {row["notification_types_id"] === 1
                          ? row["email"]
                          : row["phone_country_code"] + row["phone"]}
                      </span>
                    ),
                  },
                  {
                    title: "Estatus del Proceso",
                    field: "date_signed",
                    render: (row) => (
                      <span>
                        {row["refused_comment"] && row["refused_comment"] !== ""
                          ? "Rechazó"
                          : row["role"] === "Notificado"
                          ? "Notificado"
                          : row["date_signed"]
                          ? "Firmó"
                          : row["firmas"] && row["firmas"].length > 0
                          ? "Pendiente de confirmar código"
                          : "Pendiente de Firma"}
                      </span>
                    ),
                  },
                  {
                    title: "Registrado",
                    field: "created_at",
                    render: (row) => (
                      <span>
                        {row["created_at"]
                          ? moment(row["created_at"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Enviado",
                    field: "date_notified",
                    render: (row) => (
                      <span>
                        {row["date_notified"]
                          ? moment(row["date_notified"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Aviso de Privacidad Aceptado",
                    field: "date_accepted",
                    render: (row) => (
                      <span>
                        {row["date_accepted"]
                          ? moment(row["date_accepted"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Visto",
                    field: "date_accepted",
                    render: (row) => (
                      <span>
                        {row["date_accepted"]
                          ? moment(row["date_accepted"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Firmado",
                    field: "date_signed",
                    render: (row) => (
                      <span>
                        {row["date_signed"]
                          ? moment(row["date_signed"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Comentarios",
                    field: "refused_comment",
                  },
                ]}
                data={listaFirmantes}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {tipoDoc === 6 ? "Notificados" : "Firmantes"}
                  </Typography>
                }
              />
            ) : null}
            <hr />
            {listaNotificados ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre", field: "name" },
                  { title: "Apellido", field: "last_name" },
                  { title: "Email", field: "email" },
                ]}
                data={listaNotificados}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {tipoDoc === 6 ? "Observadores" : "Notificados"}
                  </Typography>
                }
              />
            ) : null}
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setModalFirmantesCC(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* <Dialog
        open={modalFirmantesCC}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {
          setModalFirmantesCC(false);
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        ></DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  {
                    title: "Nombres",
                    field: "name",
                    render: (row) => (
                      <span>{row["name"] + " " + row["last_name"]}</span>
                    ),
                  },

                  {
                    title: "Prueba de vida",
                    field: "speech_required",
                    render: (row) => (
                      <span>
                        {row["speech_required"] == true ? (
                          <Tooltip
                            id="tooltip-top"
                            title="Se solicito prueba de vida"
                            placement="top"
                            classes={{
                              tooltip: classes2.tooltip,
                            }}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes2.tableActionButton}
                            >
                              <RecordVoiceOverIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          "No se solicito"
                        )}
                      </span>
                    ),
                  },
                  { title: "Compañía", field: "company" },
                  {
                    title: "Medio de Notificación",
                    field: "notification_types_id",
                    render: (row) => (
                      <span>
                        {row["notification_types_id"] === 1
                          ? row["email"]
                          : row["phone_country_code"] + row["phone"]}
                      </span>
                    ),
                  },
                  {
                    title: "Estatus del Proceso",
                    field: "date_signed",
                    render: (row) => (
                      <span>
                        {row["refused_comment"] && row["refused_comment"] !== ""
                          ? "Rechazó"
                          : row["role"] === "Notificado"
                          ? "Notificado"
                          : row["date_signed"]
                          ? "Firmó"
                          : row["firmas"] && row["firmas"].length > 0
                          ? "Pendiente de confirmar código"
                          : "Pendiente de Firma"}
                      </span>
                    ),
                  },
                  {
                    title: "Registrado",
                    field: "created_at",
                    render: (row) => (
                      <span>
                        {row["created_at"]
                          ? moment(row["created_at"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Enviado",
                    field: "date_notified",
                    render: (row) => (
                      <span>
                        {row["date_notified"]
                          ? moment(row["date_notified"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Aviso de Privacidad Aceptado",
                    field: "date_accepted",
                    render: (row) => (
                      <span>
                        {row["date_accepted"]
                          ? moment(row["date_accepted"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Visto",
                    field: "date_accepted",
                    render: (row) => (
                      <span>
                        {row["date_accepted"]
                          ? moment(row["date_accepted"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Firmado",
                    field: "date_signed",
                    render: (row) => (
                      <span>
                        {row["date_signed"]
                          ? moment(row["date_signed"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Comentarios",
                    field: "refused_comment",
                  },
                ]}
                data={listaFirmantes}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {tipoDoc === 6 ? "Notificados" : "Firmantes"}
                  </Typography>
                }
              />
            ) : null}
            <hr />
            {listaNotificados ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre", field: "name" },
                  { title: "Apellido", field: "last_name" },
                  { title: "Email", field: "email" },
                ]}
                data={listaNotificados}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {tipoDoc === 6 ? "Observadores" : "Notificados"}
                  </Typography>
                }
              />
            ) : null}
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setModalFirmantesCC(false);
            }}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> */}
      {/*FIN DIALOG MOSTRAR FIRMANTES CC*/}
      {/*DIALOGO RECORDTORIO PARA CDO*/}
      <DialogComp
        open={recordatorioCDO}
        title="Reenviar notificacion"
        content=" El sistema reenviará un correo con la solicitud para firmar el
              contrato dentro de las próximas 24Hrs."
        buttons={[
          {
            buttonEvent: () => setRecordatorioCDO(false),
            buttonName: "Ceptar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* DIALOGO PARA ABRIR LA RUTAS DEL ARCHIVO */}
      <DialogComp
        open={sendFolder}
        fullWidth={true}
        maxWidth={"md"}
        title="Selecciona donde quieres ubicar el documento"
        content={
          <div>
            <VistaArchivos
              setSendFolder={setSendFolder}
              idDocumento={idDocumento}
            />
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setSendFolder(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* DIALOGO PARA ABRIR EL COMPONENTE DE LOS ARCHIVOS*/}
      <DialogComp
        open={openCarpetas}
        title={""}
        fullWidth={true}
        maxWidth={"lg"}
        content={
          <div>
            <Archivos />
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setOpenCarpetas(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/* <Dialog
        open={recordatorioCDO}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Reenviar notificacion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="subtitle1" style={{ textTransform: "none" }}>
              El sistema reenviará un correo con la solicitud para firmar el
              contrato dentro de las próximas 24Hrs.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRecordatorioCDO(false)} color="succes">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog> */}
      {/*
        (logStored) ? null : (
          <StoreLog
            actividad={actividadLog}
            setlogStored={setlogStored}
            logStored={logStored}
          />
        )
        */}
    </div>
  );
}
