import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import decode from "jwt-decode";

import { Provider } from "react-redux";
// import { createStore, compose, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css";

// paginas del flujo de firma simple

import Components from "containers/Checkout.js";
import FirmaURL from "containers/FirmaURL/FirmaURL";
//LEMP: Nueva ruta en caso de requerir cargar ID para poder Firmar
import FirmaIdURL from "containers/FirmaURL/FirmaIdURL";
import Statistics from "containers/Statistics/Statistics";
import recoverContainer from "containers/RecoverPasssword/recoverContainer";
import changePass from "containers/RecoverPasssword/changePass";
import editFlow from "containers/editFlow/editFlowContainer";
import Confirmacion from "containers/confirmacion/ConfirmacionContainer";
import ReviewEnd from "containers/ReviewPDF/ReviewEnd";
import LoginPage from "containers/LoginPage/LoginPage.js";
import loginImage from "containers/loginImage/loginImage";
import Checkout from "containers/Checkout.js";
import FirmaAvanzada from "containers/FirmaAvanzada/Flujo";
import Logs from "containers/Logs/ConsultaLogs";
import Nom151 from "containers/Nom151/Flujo";
import FirmanteDashboard from "containers/FirmantesFrecuentes/FirmanteDashboard";
import Notificacion from "containers/NotificacionCertificada/Notificacion";
import DetallesProceso from "containers/Firma/Detalles";
import TestVoice from "containers/testVoice/testvoice";
import Homepage from "views/Components/Componentshome";
import Register from "views/Register/Register";
import VistoBuenoURL from "containers/VistoBuenoURL/VistoBuenoURL";
import Cellcamara from "containers/CellCamara/cellCamara";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import { isParameterPropertyDeclaration } from "typescript";
import { store } from "redux/store/store";

var hist = createBrowserHistory();

// const initialState = {
//   imgLogo: "",
//   permissions: "",
//   user: "",
// };

// function reducer(state, action) {
//   console.log("actionDev", action);
//   switch (action.type) {
//     case "SET_LOGO": {
//       return { ...state, imgLogo: action.payload };
//     }
//     case "SET_PERMISSIONS": {
//       return { ...state, permissions: action.payload };
//     }
//     case "SET_USER": {
//       return { ...state, user: action.payload };
//     }
//     default: {
//       return state;
//     }
//   }
// }
// const store = createStore(
//   reducer,
//   initialState,
//   composeEnhancers(applyMiddleware(thunk))
// );

const isAuthenticated = () => {
  const token = localStorage.getItem("Token");
  let isValid = true;
  try {
    isValid = decode(token);
  } catch (e) {
    return false;
  }
  return isValid;
};

let uri = localStorage.getItem("uri");

const PrivateRoute = (props) =>
  isAuthenticated() ? <Route {...props} /> : <Redirect to="/login" />;

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <PrivateRoute path={`/${uri}`} component={Admin} />
        <PrivateRoute path="/statistics" component={Statistics} />
        <PrivateRoute path="/firmaavanzada" component={FirmaAvanzada} />
        <PrivateRoute path="/testVoice" component={TestVoice} />
        <PrivateRoute path="/logs" component={Logs} />
        <PrivateRoute path="/firmantedashboard" component={FirmanteDashboard} />
        {/*
        <PrivateRoute
          path="/fiel/consultas"
          component={FirmaAvanzadaConsultas}
        />
        */}
        <Route path="/rtl" component={RTL} />
        <Route path="/testVoice" component={TestVoice} />
        <Route
          path="/notificacion/:idDoc/:idfirmante"
          component={Notificacion}
        />
        <Route
          path="/sign/miFirma/:idDoc/:idfirmante/:isLogged"
          component={FirmaURL}
        />
        <Route
          path="/signid/miFirma/:idDoc/:idfirmante/:isLogged"
          component={FirmaIdURL}
        />
        <Route path="/sign/:idDoc/:idfirmante" component={FirmaURL} />
        <Route path="/signid/:idDoc/:idfirmante" component={FirmaIdURL} />
        <Route path="/info/:idDoc" component={DetallesProceso} />
        <Route path="/recover/password" component={recoverContainer} />
        <Route path="/pass-reset/:idUsuario" component={changePass} />
        <Route path="/confirm/:idfirma" component={Confirmacion} />
        <Route path="/Home" component={Components} />
        <Route path="/download-signed/:idDoc" component={ReviewEnd} />
        <Route path="/editflow/:idDoc" component={editFlow} />
        <Route path="/login" component={loginImage} />
        <Route path="/home12" component={Homepage} />
        <Route path="/register" component={Register} />
        <Route path="/vistoBueno" component={VistoBuenoURL} />
        <Route path="/cellcamara" component={Cellcamara} />

        {/* <Route path="/statistics" component={Statistics} /> */}
        <Route path="/" component={Homepage} />
        <Redirect from="/dashboard" to="/admin/dashboard" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
