import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
//imput
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
//log
import {
  saveLog,
  saveLogFail,
  saveLogCaptureActividad,
} from "containers/Logs/funcionesLog.js";

import CircularProgress from "@material-ui/core/CircularProgress";

import Backdrop from "@material-ui/core/Backdrop";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { MySnackbar } from "components/Snackbar/MySnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "relative",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function GetEmail(props) {
  const { handleNext, success } = props;
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [dataSnack, setDataSnack] = useState({
    openSnack: false,
    snackMessage: "",
    snackSeverity: "error",
  });

  const { openSnack, snackMessage, snackSeverity } = dataSnack;

  const handleEnd = () => {
    saveLogFail("Password Request", email);
    saveLogCaptureActividad("Password Request", email);
    const url = `https://api.con-certeza.mx/api/auth/${email}/pass-request`;
    const token = `${process.env.REACT_APP_TOKEN}`;
    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        handleNext();
      })
      .catch((err) => {
        // console.log("Error servidor", err.response.data.message);
        // alert(err.response.data.message);
        openAlert(err.response.data.message, "error");
      });
  };

  const openAlert = (message, severity) => {
    setDataSnack({
      openSnack: true,
      snackMessage: message,
      snackSeverity: severity,
    });
  };

  const handleCloseSnack = () => {
    setDataSnack({
      openSnack: false,
      snackError: "",
      snackMessage: "",
      snackSeverity: "",
    });
  };

  const backHome = () => {
    history.push("/");
  };

  let disableEmail = true;

  if (email.length >= 8) {
    disableEmail = false;
  }
  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12}>
          <Typography
            variant="span"
            gutterBottom
            style={{ textTransform: "none" }}
            align="center"
          >
            Ingresa el correo electrónico de tu cuenta.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            id="filled-basic"
            label="Correo"
            variant="filled"
            autoComplete="email"
            onChange={(event) => setEmail(event.target.value)}
          />
        </Grid>
      </Grid>
      <div>
        <br></br>
      </div>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Button onClick={backHome} className={classes.button} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleEnd}
          className={classes.button}
          disabled={disableEmail}
          color="primary"
          variant="contained"
        >
          Continuar
        </Button>
      </Grid>
      <Backdrop className={classes.backdrop} open={success}>
        <Grid>
          <CircularProgress size={44} className={classes.buttonProgress} />
          <br></br>
          <Typography variant="body2" color="textSecondary" align="center">
            Espera un poco
          </Typography>
        </Grid>
      </Backdrop>
      <MySnackbar
        openAlert={openSnack}
        onClose={handleCloseSnack}
        message={snackMessage}
        severity={snackSeverity}
        duration={20000}
      />
    </React.Fragment>
  );
}
