import React from "react";
import Avatar from "@material-ui/core/Avatar";
import SigUp from "assets/img/singUpEnter.svg";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import logoCDO from "assets/img/LOGOCDO.png";
import GridItem from "components/Grid/GridItem.js";
//log
import {
  saveLog,
  saveLogFail,
  saveLogCaptureActividad,
} from "containers/Logs/funcionesLog.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <img src={logoCDO} alt="Con-Certeza" width="30" height="35" />
      <GridItem>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Powered by "}
          <Link color="inherit" href="https://certificaciondigital.online/">
            CDO
          </Link>
        </Typography>
      </GridItem>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SignUp(props) {
  const { nameCompany, setDialogUsers, getDataGroup, nameGroup } = props;
  const classes = useStyles();
  const history = useHistory();
  const [openDialogEnter, setOpenDialogEnter] = React.useState(false);
  const [checkPrivacidad, setCheckPrivadad] = React.useState(true);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\d)(?=.*[@$!%*;.,?&])[A-Za-z\d@$!%*?;.,&]{6,16}$/;

  const [dataRegister, setDataRegister] = React.useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPass: "",
    promo_code: "",
  });

  // alert functions

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const openAlert = (error) => {
    setOpenSnack(true);
    setMessageError(error);
  };

  const registerUser = () => {
    console.log("DARAKSMKJ", dataRegister);
    console.log("namecoaom", nameCompany);
    const ArrayGroup = [];
    ArrayGroup.push(nameGroup);
    console.log("nameGropy", ArrayGroup);
    console.log("sdasda", checkPrivacidad);

    const data = new FormData();
    data.append("name", dataRegister.name);
    data.append("email", dataRegister.email);
    data.append("phone", dataRegister.phone);
    data.append("company", nameCompany);
    data.append("password", dataRegister.password);
    data.append("password_confirmation", dataRegister.confirmPass);
    data.append("roles[]", nameGroup);

    let token = localStorage.getItem("Token");

    //const data = `{\n   "name": "${dataRegister.name}",\n    "email": "${dataRegister.email}",\n    "phone": "${dataRegister.phone}",\n    "company": "${dataRegister.company}",\n    "password": "${dataRegister.password}",\n    "password_confirmation": "${dataRegister.confirmPass}",\n    "promo_code": "${dataRegister.promo_code}",\n}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };
    let urlFirmante = `${process.env.REACT_APP_URL}api/users`;
    axios
      .post(urlFirmante, data, config)
      .then((response) => {
        // window.location.reload();
        getDataGroup();
        setDialogUsers(false);
        console.log("responseUSERS", response);
      })
      .catch((error) => {
        // console.log("errorSignUp", error.response.data.errors.promo_code);

        console.log("ERRORS");
      });
  };

  // const registerUserMore

  const handlesuccesSignUp = () => {
    setOpenDialogEnter(false);
    window.location.reload();
  };

  const handleInput = (event) => {
    setDataRegister({
      ...dataRegister,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheck = (event) => {
    if (checkPrivacidad === false) {
      setCheckPrivadad(event.target.value);
    } else setCheckPrivadad(false);
  };

  const alertVerifyPassword =
    dataRegister.password == dataRegister.confirmPass
      ? ""
      : "Las contraseñas no coinciden.";

  let alertPass = regexPass.test(dataRegister.password)
    ? ""
    : "La contraseña no es valida.";
  let disableform = true;
  if (
    dataRegister.name.length >= 3 &&
    dataRegister.email.length >= 3 &&
    dataRegister.phone.length >= 8 &&
    dataRegister.password !== "" &&
    dataRegister.confirmPass !== "" &&
    // checkPrivacidad === "true" &&
    dataRegister.password == dataRegister.confirmPass
  ) {
    disableform = false;
  }
  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <PersonAddIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrar usuario
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                value={dataRegister.email}
                onChange={handleInput}
                label="Correo"
                name="email"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="name"
                required
                name="name"
                variant="outlined"
                fullWidth
                id="name"
                value={dataRegister.name}
                onChange={handleInput}
                label="Nombre"
                autoFocus
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="phone"
                label="Celular"
                onChange={handleInput}
                value={dataRegister.phone}
                name="phone"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Contraseña"
                onChange={handleInput}
                value={dataRegister.password}
                inputProps={{
                  maxLength: 15,
                }}
                type="password"
                id="password"
              />
              <FormHelperText style={{ color: "red" }}>
                {alertPass}
              </FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPass"
                label="Confirma tu contraseña"
                type="password"
                onChange={handleInput}
                inputProps={{
                  maxLength: 12,
                }}
                value={dataRegister.confirmPass}
                id="confirmPass"
                error={alertVerifyPassword}
              />
              <FormHelperText style={{ color: "red" }}>
                {alertVerifyPassword}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" align="center">
                {
                  "La contraseña debe tener de 6 a 16 caracteres y contener una mayúscula, una minúscula, símbolos (guión, !@#$&*, etc.) y dos o mas números."
                }
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                onChange={handleCheck}
                control={<Checkbox value={true} color="primary" />}
                label="Acepto los terminos y condiciones de privacidad de Con-Certeza"
              />
            </Grid> */}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                onClick={registerUser}
                fullWidth
                disabled={disableform}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Registrar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      {/* <Box mt={5}>
        <Copyright />
      </Box> */}
      <div>
        <Dialog
          // fullWidth={true}
          // maxWidth="md"
          open={openDialogEnter}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Registro exitoso"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography
                variant="subtitle1"
                style={{
                  textTransform: "none",
                }}
              >
                El usuario ha sido registrado con éxito, podra iniciar sesión.
              </Typography>
              <div align="center">
                <img src={SigUp} alt="logo" width="350" height="240" />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handlesuccesSignUp()} color="succes">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className={classes.root}>
        <Snackbar
          open={openSnack}
          autoHideDuration={9000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {messageError}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
}
