import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import AppBar from '@material-ui/core/AppBar';
import GroupIcon from '@material-ui/icons/Group';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PersonIcon from '@material-ui/icons/Person';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';
import Button from '@material-ui/core/Button';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import AppsIcon from '@material-ui/icons/Apps';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ReactFlow, {
  removeElements,
  addEdge,
  Controls,
  Handle
} from "react-flow-renderer";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({

  paper:{
    width:"90%",
    margin:2,
    textAlign: "center",
    margin:"auto"
  },
  paper1:{
    width:"80%",
    margin:2,
    textAlign: "center"
  },
  paper2:{
    width:"80%",
    margin:1,
    //textAlign: "center"
  },
  paper3:{
    width:"80%",
    margin:0,
    textAlign: "right",
    backgroundColor:"white"
  },
  formControl: {
    margin: theme.spacing(1),
    //minWidth: 120,
    width:"80%"
  },
  botondanger: {
    backgroundColor: "red",
    color:"white"
  },
  botongreen: {
    backgroundColor: "green",
    color:"white",
    margin:10
  },
  gridsmall: {
    margin:5,
    backgroundColor: "white",
    border: "1px solid #777",
  },
  iconsmall: {
    fontSize:10,
    margin:2,
    marginTop:11, //15 center
    padding:0,
  },
  iconsmall2: {
    fontSize:12,
    margin:2,
    marginTop:5, //15 center
    padding:0,
  },
  iconsmall3: {
    fontSize:8,
    //margin:2,
    //marginTop:5, //15 center
    //padding:0,
  },
  colapse:{
    marginLeft:10,
  },
  nombrecatalogo:{
    fontSize:13,
    marginTop:0,
    marginLeft:10,
    textAlign:"left"
  },
  nombrearchivo:{
    marginTop:6,
    marginLeft:0,
    fontSize:10,
  },
  iconarchivo:{
    marginTop:0,
    fontSize:10,
  },
  p: {
    width: "90%",
    margin: "0",
    lineHeight: "0.7"
  },
  divtittle:{
    backgroundColor:"#3f51b5",
    color:"white"
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Iniciar(props) {
  const classes = useStyles();
  const [flujos, setFlujos] = React.useState("");
  const [flujo, setFlujo] = React.useState("");
  const [idFlujo, setIdFlujo] = React.useState("");
  const [grupos, setGrupos] = React.useState("");
  const [catalogos, setCatalogos] = React.useState("");
  const [miniaturas, setMiniaturas] = React.useState(true);
  const [elements, setElements] = React.useState([]);
  const [modalAgregar, setModalAgregar] = React.useState(false);
  const [idCatalogo, setIdCatalogo] = React.useState("");
  const [catalogosFiltrados, setCatalogosFiltrados] = React.useState("");
  const [nodoModificar, setNodoModificar] = React.useState("");
  const [catalogoEliminar, setCatalogoEliminar] = React.useState("");
  const [catalogosAdicionales, setcatalogosAdicionales] = React.useState([]);
  const [customId, setCustomId] = React.useState("");
  const [customNombre, setCustomNombre] = React.useState("");
  const [modalIniciar, setModalIniciar] = React.useState(false);
  const [modalEliminarCatalogo, setModalEliminarCatalogo] = React.useState(false);
//  const [nuevoFlujo, setNuevoFlujo] = React.useState("");

  const [openMensaje, setOpenMensaje] = React.useState(false);
  const [mensaje, setMensaje] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const handleCloseMensaje = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   setOpenMensaje(false);
  };

function alerta(texto,severidad){
  setOpenMensaje(true);
  setMensaje(texto);
  setSeverity(severidad);
}

  async function getGrupos(){
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
    method: 'get',
    url: `${process.env.REACT_APP_URL}api/workgroups`,
    headers: {
      'Accept': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
      axios(config)
        .then(function (response) {
          //console.log("Grupos:",response.data);
          setGrupos(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setGrupos("");
        });
  }



  async function getCatalogos(){
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
    method: 'get',
    url: `${process.env.REACT_APP_URL}api/catalogos`,
    headers: {
      'Accept': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
      axios(config)
        .then(function (response) {
          setCatalogos(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setCatalogos("");
        });
  }

  async function getFlujos(){
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
    method: 'get',
    url: `${process.env.REACT_APP_URL}api/flows`,
    headers: {
      'Accept': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
      axios(config)
        .then(function (response) {
          console.log("Flujos:",response.data);
          setFlujos(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setFlujos("");
        });
  }

function DisplayCatalogo(props){
  if(catalogos){
    const catalogo = catalogos.find(item => item._id == props.idcatalogo);
    if(catalogo)
    return(
      <React.Fragment key={catalogo._id}>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<FolderOpenIcon color="primary" className={classes.colapse} />}
        defaultExpandIcon={<FolderIcon color="primary" className={classes.colapse} />}
      >
        <TreeItem nodeId="0"
          label={
            <Grid container>
              <Grid item xs={11}>
                <Typography className={classes.nombrecatalogo}>
                  <font size="1">{catalogo.name}</font>
                  {props.adicional ?(
                    <>
                    {/*
                    <Tooltip title="Agregar catálogo al nodo de forma permanente" placement="top">
                      <AddCircleIcon
                        className={classes.iconsmall3}
                        color="primary"
                        onClick={()=>{
                          //setCatalogoModificar(catalogo);
                          //setModalAgregar(true);
                        }}
                      />
                    </Tooltip>
                    */}
                    <Tooltip title="Eliminar catálogo adicional" placement="top">
                      <DeleteIcon
                        className={classes.iconsmall3}
                        color="primary"
                        onClick={()=>{
                          //console.log("CATALOGO",catalogo)
                          //console.log("nodo",props.nodo)
                          //console.log("adicionales",catalogosAdicionales)
                          setNodoModificar(props.nodo)
                          setCatalogoEliminar(catalogo)
                          setModalEliminarCatalogo(true);
                        }}
                      />
                    </Tooltip>
                    </>
                  ):null}
                </Typography>

              </Grid>
            </Grid>
          }
          className={classes.root}
        >
        {catalogo.documentos.map((item,key) => (
          <TreeItem nodeId={item._id} key={key} label={
            <Grid container>
            {/*
              <Grid item>
              <WebAssetIcon
                className={classes.iconarchivo}
              />
              </Grid>
              */}
              <Grid item>
                <Typography className={classes.nombrearchivo}>
                  -{item.name} <font size="1">{item.required==="1" ? <b>(Requerido)</b>:<>(Opcional)</>}</font>
                </Typography>
              </Grid>
            </Grid>
          } />
        ))}
        </TreeItem>
      </TreeView>
      </React.Fragment>
    );
    return(null);
  }
  return(null)
}
function DisplayGrupo(props){
    const grupo = grupos.find(item => item._id == props.idgrupo);
  if(grupo)
  return(
    <React.Fragment key={grupo._id}>
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<PeopleOutlineIcon color="primary" className={classes.colapse} />}
      defaultExpandIcon={<GroupIcon color="primary" className={classes.colapse} />}
    >
      <TreeItem nodeId="0"
        label={
          <Grid container>
            <Grid item xs={11}>
              <Typography className={classes.nombrecatalogo}>
                <font size="1">{grupo.name}</font>
              </Typography>
            </Grid>
          </Grid>
        }
        className={classes.root}
      >
      {grupo.members.map((item,key) => (
        <TreeItem nodeId={item._id} key={key} label={
          <Grid container>
            <Grid item>
            <PersonIcon
              className={classes.iconarchivo}
            />
            </Grid>
            <Grid item>
              <Typography className={classes.nombrearchivo}>
                <font size="1">{item.user?item.user.name:null}</font>
              </Typography>
            </Grid>
          </Grid>
        } />
      ))}
      </TreeItem>
    </TreeView>
    </React.Fragment>
  );
  return(null);
}

function DisplayFlujo(){
  //ordenar
    flujo.nodes.sort(function (a, b) {
      if (a.step_id > b.step_id) {
        return 1;
      }
      if (a.step_id < b.step_id) {
        return -1;
      }
      return 0;
    });
//    console.log("flujo",flujo);
  //fin ordenar
    return (
    <React.Fragment>
        {flujo.nodes && flujo.nodes.map((item,key) => (
          <React.Fragment key={key} >
          <Grid item xs={5} className={classes.gridsmall}>
          <AppBar position="static">
          <Grid container>
            <Grid item xs={11} style={{textAlign:"left"}}>
              <font size="1" style={{textAlign:"left", marginLeft:10}}><b>{key+1+"- "+item.name}</b></font>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Agregar catálogo adicional" placement="top">
                <CreateNewFolderIcon
                  className={classes.iconsmall2}
                  onClick={()=>{
                    setNodoModificar(item)
                    var filtrados = [];
                    for (var i = 0; i < catalogos.length; i++) {
                      let encontrado=false;
                      if (item.catalogos){
                        for (var j = 0; j < item.catalogos.length; j++) {
                          if(catalogos[i]._id==item.catalogos[j]){
                            encontrado=true;
                          }
                        }
                      }
                      if(encontrado==false){
                        filtrados=[...filtrados,catalogos[i]]
                      }
                    }
                    if(filtrados.length>0)
                      setCatalogosFiltrados(filtrados);
                    else
                      setCatalogosFiltrados(catalogos);
                    setModalAgregar(true);
                  }}
                />
              </Tooltip>
            </Grid>
            </Grid>
          </AppBar>
          <DisplayGrupo idgrupo={item.workgroup_id} />
          {item.catalogos && item.catalogos.map((item,key) => (
            <DisplayCatalogo key={key} idcatalogo={item} />
          ))}
          {catalogosAdicionales.map((adicional,key) => {
            if (adicional.nodoid==item._id)
              return (
                <>
                  <DisplayCatalogo key={key} idcatalogo={adicional.catalogo} adicional={true} nodo={item} />
                </>
                )
            return(null)
          })}
          </Grid>
          </React.Fragment>
        ))}

    </React.Fragment>
  )
}

function Vista(){
  if(miniaturas){
    return (
      <Tooltip title="Ver gráfica" placement="top">
      <IconButton
        aria-label="settings"
        color="primary"
        variant="contained"
        onClick={()=>{
          setMiniaturas(false);
        }}
        >
        <AccountTreeIcon/>
      </IconButton>
      </Tooltip>
    );
  }
  else{
    return (
      <Tooltip title="Ver nodos" placement="top">
      <IconButton
        aria-label="settings"
        color="primary"
        variant="contained"
        onClick={()=>{
          setMiniaturas(true);
        }}
        >
        <AppsIcon/>
      </IconButton>
      </Tooltip>
    );
  }

}


const InputNode = ({ type, data, id }) => {
  const classes = useStyles();
  function nombreGrupo(idgrupo){
    const nombre = data.lista_grupos.find(item => item._id == idgrupo);
    if(nombre){
        return(nombre.name);
    }
    return(null);
  }
  function nombreCatalogo(idcatalogo){
    const nombre = data.lista_catalogos.find(item => item._id == idcatalogo);
    if(nombre){
        return(nombre.name);
    }
    return(null);
  }

  return (
    <>
      <Handle type="target" position="left" />
      <div className={classes.divtittle}>
        <p className={classes.p}><font size="1"><b>Proceso:</b></font></p>
        <p className={classes.p}><font size="1">{data.value}</font></p>
        <p className={classes.p}><font size="1">&nbsp;</font></p>
      </div>
      <hr />
      <p className={classes.p}><font size="1"><b>Responsable:</b></font></p>
      <p className={classes.p}><font size="1">{nombreGrupo(data.workgroup_id)}</font></p>
      <p className={classes.p}>&nbsp;</p>
      <p className={classes.p}></p>
      <p className={classes.p}><font size="1"><b>Catálogos:</b></font></p>
      {data.catalogos && data.catalogos.map((item,key) => (
        <p key={key} className={classes.p}><font size="1"><b>-</b>{nombreCatalogo(item)}</font></p>
      ))}
      <Handle type="source" position="right" id="a" />
    </>
  );
};

const nodeTypes = {
  InputNode: InputNode,
  //TextAreaNode: TextAreaNode,
  //BooleanNode: BooleanNode,
  //Input2Node: Input2Node
};

const onLoad = (reactFlowInstance) => {
  console.log('flow loaded:', reactFlowInstance);
  reactFlowInstance.fitView();
};

function DisplayFlowGraph(){
  //console.log("Grafico");
  var nodos=[];
  var x=0;
  //console.log(flujo);
  for (var i = 0; i < flujo.nodes.length; i++) {
    x=x+100;
    nodos=[...nodos,
      {
        type:'InputNode',
        id:flujo.nodes[i].step_id,
        data:{
          value:flujo.nodes[i].name,
          workgroup_id:flujo.nodes[i].workgroup_id,
          catalogos:flujo.nodes[i].catalogos,
          flow_id:flujo._id,
          lista_grupos:grupos,
          lista_catalogos:catalogos,
        },
        position:{x:x,y:25},
        style:{
          border: "2px solid #3f51b5",
          padding: 3,
          width: 80
        }
      }
    ]
  }
  //flechas
  for (var i = 0; i < flujo.nodes.length; i++) {
    if(flujo.nodes[i].prev_step){
      nodos=[...nodos,
        {
          arrowHeadType:'arrowclosed',
          id:flujo.nodes[i].prev_step+"-"+flujo.nodes[i].step_id,
          source:flujo.nodes[i].prev_step,
          sourceHandle:"a",
          target:flujo.nodes[i].step_id,
          targetHandle:null
        }
      ]
    }
  }
  //console.log("elements",nodos);
  //setElements(nodos);

  return(
    <div style={{ height: 300, width:"100%" }}>
    <ReactFlow
      elements={nodos}
      onLoad={onLoad}
      //defaultZoom={0.5}
      snapToGrid={true}
      snapGrid={[15, 15]}
      nodeTypes={nodeTypes}
      >
        <Controls />
      </ReactFlow>
    </div>
  )
}

function handleCancelar(){
  setFlujo("");
  setIdFlujo("");
  setMiniaturas(true);
  setcatalogosAdicionales([])
}

function nombreCatalogo(idcatalogo){
  const nombre = catalogos.find(item => item._id == idcatalogo);
  if(nombre){
      return(nombre.name);
  }
  return(null);
}

async function notify(nuevoflujo){
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL}api/expedientes/notify/${nuevoflujo._id}`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Notificados:",response.data);
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al notificar inicio","error")
      });

}

async function save(nuevoflujo,nodo){
  console.log("NODO EN SAVE",nodo)
  var dataForm = new FormData();
  dataForm.append('expediente_id',nuevoflujo._id);
  dataForm.append('status',0);
  dataForm.append('workgroup_id',nodo.workgroup_id);
  dataForm.append('name',nodo.name);
  dataForm.append('step_id',nodo.step_id);
  dataForm.append('prev_step',nodo.prev_step);
  if(nodo.catalogos){
    for (var i = 0; i < nodo.catalogos.length; i++) {
      dataForm.append('catalogos[]',nodo.catalogos[i]);
    }
  }
  if(catalogosAdicionales){
    for (var i = 0; i < catalogosAdicionales.length; i++) {
      if(catalogosAdicionales[i].nodoid==nodo._id){
        dataForm.append('catalogos[]',catalogosAdicionales[i].catalogo);
      }
    }
  }
  //catalogos adicionales

  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL}api/expedientenodes`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data : dataForm
    };

    axios(config)
      .then(function (response) {
        console.log("Nodo Creado:",response.data);
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al crear nodos","error")
      });

}

async function handleGuardarNodos(nodosF,nuevoexpediente){
  console.log("guardar",nodosF);

  for (var i = 0; i < nodosF.length; i++) {
    await save(nuevoexpediente,nodosF[i]);
  }
  await notify(nuevoexpediente);
  if(props.setOpcion){
    props.setOpcion(0);
  }
}

async function handleIniciar(){
  console.log("Flujo",flujo)
  var dataForm = new FormData();
  dataForm.append('name', customNombre);
  dataForm.append('custom_id', customId);
  dataForm.append('status', 0);
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL}api/expedientes`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data : dataForm
    };

    axios(config)
      .then(function (response) {
        console.log("nuevoexpediente",response.data);
        let nuevoexpediente = response.data
        handleGuardarNodos(flujo.nodes,nuevoexpediente)

        alerta("Nuevo proceso creado","info")
        handleCancelar();
        setModalIniciar(false);

      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al crear catálogo","error")
      });
}

function handleEliminarCatalogoAdicional(){
  //setModalEliminarCatalogo(false)
  /*
  console.log("Adicionales",catalogosAdicionales)
  console.log("Nodo modificar",nodoModificar)
  console.log("catalogo eliminar",catalogoEliminar)
  */
  //var ca = catalogosAdicionales.filter(x => x.catalogo == catalogoEliminar._id && x.nodoid == nodoModificar._id);
  var ca=[];
  for (var i = 0; i < catalogosAdicionales.length; i++) {
    if (catalogosAdicionales[i].catalogo == catalogoEliminar._id && catalogosAdicionales[i].nodoid == nodoModificar._id){}
    else
      ca=[...ca,catalogosAdicionales[i]]
  }

  setcatalogosAdicionales(ca);
  setModalEliminarCatalogo(false)
}

  React.useEffect(()=>{
    getFlujos();
    getGrupos();
    getCatalogos();
  },[]);

  return (
    <React.Fragment>
    <Paper elevation={3} className={classes.paper}>
    <Grid container justify = "center">
        <Typography variant="h6" component="h2">
          INICIAR FLUJO PARA CREAR EXPEDIENTE
        </Typography>
      <Paper elevation={3} className={classes.paper1}>
          <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-helper-label">Seleccione Flujo</InputLabel>
            {flujos.length>0 ?
              (
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={idFlujo}
                    onChange={(e) => {
                      setIdFlujo(e.target.value);
                      setFlujo(flujos.find(element => element._id == e.target.value))
                    }}
                  >
                    {flujos.map((item,key) => (
                      <MenuItem key={key} value={item._id}>{item.name}</MenuItem>
                    ))}
                  </Select>
              ):null}
          </FormControl>
       </Paper>
       {flujo ? (
         <React.Fragment>
         <Grid container justify = "center">
          <Vista />
         </Grid>
         <Grid container justify = "center">
            {miniaturas ? (<DisplayFlujo />):(<DisplayFlowGraph />)}
         </Grid>
        </React.Fragment>
       ):null}
    </Grid>
    <Grid container justify = "center">
    <div className={classes.paper3}>
    <Button
      variant="contained"
      disabled={idFlujo?false:true}
      onClick={()=>{
        handleCancelar();
      }}
      className={classes.botondanger}
    >
      Cancelar
    </Button>
      <Button
        variant="contained"
        disabled={idFlujo?false:true}
        onClick={() => {
          setModalIniciar(true);
          setCustomNombre(flujo.name)
        }}
        className={classes.botongreen}
      >
        Iniciar
      </Button>
      </div>
    </Grid>
    </Paper>

    {/*DIALOGO AGREGAR NODOS*/}
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={modalAgregar}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        Agregar catálogo adicional a {nodoModificar.name}
      </DialogTitle>
      <DialogContent>
      {catalogosFiltrados.length>0 ?
        (
      <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-helper-label">Seleccione catálogo</InputLabel>
      <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={idCatalogo}
          onChange={(e) => {
            setIdCatalogo(e.target.value)
          }}
        >
          {catalogosFiltrados.map((item,key) => (
            <MenuItem key={key} value={item._id}>{item.name}</MenuItem>
          ))}
        </Select>
        {catalogosAdicionales.map((item,key) => {
          if (item.nodoid==nodoModificar._id)
            return <DisplayCatalogo key={key} idcatalogo={item.catalogo} />
          return(null)
        })}
        </FormControl>
      ):null}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{
            setModalAgregar(false);
            setIdCatalogo("");
          }}
          //className={classes.botondanger}
        >
          Cerrar
        </Button>
        <Button
          variant="contained"
          //color="primary"
          onClick={()=>{
            //setModalAgregar(false);
            let cat = catalogosAdicionales;
            cat=[...cat,{nodoname:nodoModificar.name,nodoid:nodoModificar._id,catalogo:idCatalogo}]
            setcatalogosAdicionales(cat);
            console.log("Adicionales:",cat);
          }}
          className={classes.botongreen}
        >
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
    {/*FIN DIALOGO AGREGAR NODOS*/}

    {/*DIALOGO CONFIRMAR*/}
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={modalIniciar}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        Por favor ingresar código del expediente
      </DialogTitle>
      <DialogContent>
      <TextField
          autoFocus
          required
          margin="dense"
          id="custom ID"
          label="Código del Expediente"
          variant="outlined"
          fullWidth
          value={customId}
          onChange={(e)=>{
            setCustomId(e.target.value)
          }}
        />
        <TextField
            autoFocus
            required
            margin="dense"
            id="custom Nombre"
            label="Nombre del Expediente"
            variant="outlined"
            fullWidth
            value={customNombre}
            onChange={(e)=>{
              setCustomNombre(e.target.value)
            }}
          />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          //color="primary"
          onClick={()=>{
            setModalIniciar(false);
            //setIdCatalogo("");
          }}
          className={classes.botondanger}
        >
          Cerrar
        </Button>
        <Button
          variant="contained"
          disabled={(customId && customNombre)?false:true}
          onClick={()=>{
            handleIniciar();
          }}
          className={classes.botongreen}
        >
          Confirmar inicio
        </Button>
      </DialogActions>
    </Dialog>
    {/*FIN CONFIRMAR*/}

    {/*DIALOGO ELIMINAR CATALOGO*/}
    <Dialog
      open={modalEliminarCatalogo}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
      </DialogTitle>
      <DialogContent>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            ¿Está seguro de eliminar el catálogo?<br />
            {/*
             <b>{usuarioEliminar.user ? usuarioEliminar.user.name:""}</b>
             */}
          </Typography>
      </DialogContent>
      <DialogActions>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          setModalEliminarCatalogo(false)
        }}
        //className={classes.botondanger}
      >
        Cerrar
      </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleEliminarCatalogoAdicional();
          }}
          className={classes.botondanger}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
    {/* FIN DIALOGO ELIMINAR CATALOGO*/}

    {/*MENSAJE*/}
    <Snackbar
        open={openMensaje}
        autoHideDuration={3000}
        anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
        onClose={handleCloseMensaje}>
      <Alert onClose={handleCloseMensaje} severity={severity}>
        {mensaje}
      </Alert>
    </Snackbar>
    {/*FIN MENSAJE*/}
    </React.Fragment>
  );

}
