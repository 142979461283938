import React from "react";
import clsx from "clsx";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import FolderIcon from "@material-ui/icons/Folder";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DescriptionIcon from "@material-ui/icons/Description";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlPanel from "components/controlPanel/ControlPanel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialTable from "material-table";
import localization from "components/localization/localization";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import moment from "moment";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import { Grid } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

import PeopleIcon from "@material-ui/icons/People";
import Checkbox from "@material-ui/core/Checkbox";

import DeleteIcon from "@material-ui/icons/Delete";

import EditIcon from "@material-ui/icons/Edit";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";

import { green } from "@material-ui/core/colors";
import { grayColor } from "assets/jss/material-dashboard-react.js";
import { DialogComp } from "components/Dialog/DialogComp";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { CircularGridLines } from "react-vis";
import Almacenar from "containers/Boveda/Almacenar";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const drawerWidth = 200;

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  root: {
    height: "600px",
    background:
      "  linear-gradient(to right top, #eee404, #f1e405, #f3e407, #f6e308, #f8e30a)",
    borderRadius: "3px",

    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    backgroundColor: grayColor[0],
    float: "left",
  },

  iconGrayColor: {
    backgroundColor: grayColor[0],
    borderRadius: "3px",

    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    float: "left",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  botoninfo: {
    backgroundColor: "#00acc1",
    color: "white",
  },

  botondanger: {
    backgroundColor: "red",
    color: "white",
  },

  botongreen: {
    backgroundColor: "green",
    color: "white",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(7),

    right: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 70,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",

    justifyContent: "flex-end",
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    height: "550px",
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const useStylestable = makeStyles(styles2);

export default function MiniDrawer() {
  const classes = useStyles();
  const classes2 = useStylestable();

  const classespro = useStylesprogrees();
  const theme = useTheme();
  const [dataFolder, setDAtaFolder] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openZip, setOpenZip] = React.useState(false);

  const [ItemDataok, setItemDataOk] = React.useState([
    <Typography paragraph>Seleccione una Carpeta</Typography>,
  ]);
  const [folder, setFolder] = React.useState("");

  const [patch, setPatch] = React.useState("");
  const [pdfBase64, setPdfBase64] = React.useState([]);
  const [openPDF, setOpenPDF] = React.useState(false);
  const [scale, setScale] = React.useState(1.3);
  const [numPages, setNumPages] = React.useState(null);
  const [messageError, setMessageError] = React.useState("");

  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(575);
  const [pdfWidth, setpdfWidth] = React.useState(660);
  const [openCarpetas, setOpenCarpetas] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [detallesDocu, setDetallesDocu] = React.useState({});
  const [openSpeed, setOpenSpeed] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const [contratosID, setContratosID] = React.useState("");
  const [listaFirmantes, setListaFirmantes] = React.useState("");
  const [listaFirmantesCC, setListaFirmantesCC] = React.useState("");
  const [listaNotificados, setListaNotificados] = React.useState("");
  const [modalFirmante, setModalFirmante] = React.useState(false);
  const [modalFirmantes, setModalFirmantes] = React.useState(false);
  const [modalFirmantesCC, setModalFirmantesCC] = React.useState(false);
  const [enviar, setEnviar] = React.useState(true);
  const [tipoDoc, setTipoDoc] = React.useState("");
  const [openDialogInfo, setOpenDialogInfo] = React.useState(false);
  const [docuDataPerson, setDocuDataPerson] = React.useState([]);
  const [diasAlert, setDiasAlert] = React.useState(0);
  const [openDelete, setopenDelete] = React.useState(false);

  const [openFirmantesRechazados, setopenFirmantesRechazados] = React.useState(
    false
  );
  const [openAlert, setOpenAlert] = React.useState("");

  const [checked, setChecked] = React.useState(false);
  const [valueState, setValue] = React.useState(0);
  const [idDeleteCDO, setidDeleteCDO] = React.useState("");
  const [idDelete, setidDelete] = React.useState("");

  const [descripcion, setDescripcion] = React.useState("");
  const [desde, setDesde] = React.useState("");
  const [hasta, setHasta] = React.useState("");
  const [alerta, setAlerta] = React.useState("");
  const [diasAlerta, setDiasAlerta] = React.useState(0);
  const [openModificar, setOpenModificar] = React.useState(false);
  const [openAlmacenar, setOpenAlmacenar] = React.useState(false);
  const [archivoAlmacenar, setArchivoAlmacenar] = React.useState("");

  const PROXY_URL = "";
  let token = localStorage.getItem("Token");

  React.useEffect(() => {
    handleCatalogo();
    if (isMobile()) {
      setpdfHeigth(500);
      setpdfWidth(290);
    }
  }, []);

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  const actions = [
    {
      icon: (
        <CreateNewFolderIcon
          onClick={() => {
            setOpenCarpetas(true);
          }}
        />
      ),
      name: "Crear Carpeta",
    },
    {
      icon: (
        <div>
          <input
            accept="pdf/*"
            //className={classes.input}
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
            name="PDF"
            onChange={onChangeAlmacenar}
          />
          <label htmlFor="contained-button-file">
            <IconButton
              variant="contained"
              //color="primary"
              component="span"
              //disabled={loading}
            >
              <FileCopyIcon
                style={{ marginRight: 2 }}
                fontSize="large"
                color="primary"
              />
            </IconButton>
          </label>
        </div>
      ),
      name: "Crear documento",
    },
  ];

  const handleOpen = () => {
    setOpenSpeed(true);
  };
  function ShowMeta(props) {
    let mainData;
    if (typeof props.json === "string") {
      mainData = JSON.parse(props.json);
    } else {
      mainData = props.json;
    }
    return (
      <>
        {Object.keys(mainData).map((key, i) => (
          <p key={i}>
            <b>{key.toUpperCase()}:</b> {mainData[key]}
          </p>
        ))}
      </>
    );
  }
  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };
  const handleChangeBox = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      if (diasAlerta === 0 || diasAlerta === null) {
        setDiasAlert(30);
        setDiasAlerta(30);
      } else {
        setDiasAlert(diasAlerta);
      }
    } else {
      setDiasAlert(0);
      setDiasAlerta(0);
    }
  };

  const handleCloseInfo = () => {
    console.log("handleCloseInfo");
    setOpenDialogInfo(false);
  };
  const handleChangeDataDocuPerson = () => {
    setOpenDialogInfo(false);
    if (docuDataPerson.description !== "") {
      setDescripcion(docuDataPerson.description);
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.vigencia_start !== "" &&
      docuDataPerson.meta.vigencia_start !== undefined
    ) {
      setDesde(moment(docuDataPerson.meta.vigencia_start).format("YYYY-MM-DD"));
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.vigencia_end !== "" &&
      docuDataPerson.meta.vigencia_end !== undefined
    ) {
      setHasta(moment(docuDataPerson.meta.vigencia_end).format("YYYY-MM-DD"));
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.dias_alerta !== "" &&
      docuDataPerson.meta.dias_alerta !== undefined
    ) {
      setDiasAlerta(docuDataPerson.meta.dias_alerta);
    }
    setOpenModificar(true);
  };

  function bucarFirmantesCDO(contratoCDO_id) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      contratoCDO_id +
      "/firmante/lista";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Firmantes CDO:", response.data);
        setListaFirmantes(response.data);
        if (response.data.length === 0) {
          setEnviar(true);
        } else {
          setEnviar(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function bucarNotificadosCDO(contratoCDO_id) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      contratoCDO_id +
      "/notificador/lista";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Notificados CDO:", response.data);
        setListaNotificados(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleDeleteFolder = (infoFolder) => {
    let token = localStorage.getItem("Token");
    console.log("DATAFOLDER", infoFolder);
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/categorias/${infoFolder._id}`;
    console.log("URL DELETE");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        handleActualizar(infoFolder);

        console.log("respuest Delete", response);
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          console.log(error.response.data.message);
          alert(error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const crearCarpeta = () => {
    const URL = `${process.env.REACT_APP_URL}api/categorias`;
    const data = new FormData();
    let rutaFolder = "";
    if (patch.length > 1) {
      rutaFolder = `${patch}/${folder}`;
    } else {
      rutaFolder = folder;
    }
    data.append("folder", rutaFolder);
    let token = localStorage.getItem("Token");
    console.log("RUTAFOLDER", rutaFolder);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
        type: "formData",
      },
    };

    axios
      .post(URL, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        setOpenCarpetas(false);
        handleCatalogo();

        console.log("respADD", response.data);
        handleActualizar(response.data.categoria);
      })
      .catch((error) => {
        // Error 😨
        console.log("respERROR", error);
      });
  };

  // funcion para actualizar la data despues agregar o borrar una carpeta
  const handleActualizar = (dataPather) => {
    console.log("datapapa", dataPather);

    const idFolder = dataPather.parent_id;
    setItemDataOk([]);
    let token = localStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/categorias/${idFolder}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const okreturn = handleItemDocu(res.data);
        // setDAtaFolder(res.data);
        setItemDataOk(okreturn);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClose = () => {
    setOpenSpeed(false);
  };
  const handleCatalogo = () => {
    let token = localStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/categorias`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDAtaFolder(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //FUNCION PARA TRAER EL DOCUMENTO FIRMADO
  const handlePDFB64 = (idDoc) => {
    console.log("IDPDFD", idDoc);
    let token = localStorage.getItem("Token");

    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDoc}/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPdfBase64(res.data);
        setOpenPDF(true);
      })
      .catch((error) => {
        console.error(error);
        alert(error.data.message);
      });
  };

  function handleModificar() {
    console.log("handleModificar NOT ARROW FUNCTION");

    let tokenCC = localStorage.getItem("Token");
    //let tokenCC = `${process.env.REACT_APP_TOKEN}`;
    let urlmodificar = "";
    let desc = "";
    console.log("desde:", desde);
    console.log("hasta:", hasta);
    console.log("dias:", diasAlerta);

    if (descripcion != "" && descripcion != undefined) {
      desc = descripcion;
    }
    urlmodificar = "?description=" + desc;

    if (desde !== "" && desde !== undefined && desde !== "Invalid date") {
      urlmodificar =
        urlmodificar + "&meta[vigencia_start]=" + desde + "00:00:00";
    }
    if (desde === "" || desde === "Invalid date") {
      urlmodificar = urlmodificar + "&meta[vigencia_start]=";
      urlmodificar = urlmodificar + "&meta[vigencia_end]=";
    } else {
      if (hasta !== "" && hasta !== undefined && hasta !== "Invalid date") {
        urlmodificar =
          urlmodificar + "&meta[vigencia_end]=" + hasta + "23:59:59";
      }
      if (hasta === "" || hasta === "Invalid date") {
        urlmodificar = urlmodificar + "&meta[vigencia_end]=";
      }
    }

    if (checked) {
      urlmodificar = urlmodificar + "&meta[dias_alerta]=" + diasAlerta;
    } else {
      urlmodificar = urlmodificar + "&meta[dias_alerta]=";
    }

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${docuDataPerson._id}${urlmodificar}`,
      //url : `${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("ContratoCC Actualizado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    //reload

    setOpenModificar(false);
    setDescripcion("");
    setDesde("");
    setHasta("");
    setDiasAlerta(30);
  }

  const handleCancelarActualizacion = () => {
    setOpenModificar(false);
    setDescripcion("");
    setDesde("");
    setHasta("");
    setDiasAlerta(30);
  };
  const handleDetalles = async (idDoc, accion) => {
    const token = `${process.env.REACT_APP_TOKEN}`;
    console.log("ACCION", accion);
    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDoc}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (accion == "descarga") {
          descargarDocu(res.data);
        } else if (accion == "participantes") {
          participantesDocu(res.data);
        } else if (accion == "info") {
          handleinfoDocu(res.data);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          console.log(error.response.data.message);
        }
      });
  };
  const handleinfoDocu = (detalle) => {
    handleOpenInfo(detalle);
  };

  const handleOpenInfo = (data) => {
    setOpenDialogInfo(true);
    console.log("datainfo", data);
    setDocuDataPerson(data);
  };
  const participantesDocu = (detalle) => {
    if (detalle.type.id === 3) {
      setContratosID({
        cc: detalle._id,
        cdo: detalle.cdo_contratos_id,
      });
      setListaFirmantesCC(detalle.firmantes);
      bucarFirmantesCDO(detalle.cdo_contratos_id);
      bucarNotificadosCDO(detalle.cdo_contratos_id);
      setModalFirmantes(true);
    } else {
      // !ANTERIOR SALVADOR handleOpenTodosFirmantes(detalle);
      setTipoDoc(detalle.type.id);
      setListaFirmantes(detalle.firmantes);
      setListaNotificados(detalle.notificados);
      setModalFirmantesCC(true);
    }
  };

  const descargarDocu = (detalle) => {
    console.log("entro a descarga", detalle);
    if (detalle.type.id === 5) {
      console.log("Descargar constanciaCDO");

      console.log(detalle.constancia);
      var obj = JSON.parse(detalle.constancia);
      //var obj = JSON.parse('{"id" : "b0b7aab4-6e7d-49e3-b932-66cb9d9ba6de", "hash" : "3000b2954437d06c35bc0e96063ac10b0debdaf072cc5303130acc088ad59460", "datetime" : "2021-02-19 16:00:28", "descripcion" : "Constancia NOM151 -CONTRATODJEMEPLO1902-3.pdf", "originalname" : "CONTRATODJEMEPLO1902-3.pdf"}');
      //var obj = rowData.constancia;
      //console.log(obj.descripcion,obj.id);
      handleDescargarConstanciaCDO(obj.descripcion, obj.id);
    } else {
      getTotalDownloadPdf(detalle);
    }
  };

  const handleDescargarConstanciaCDO = (nombre, idConstancia) => {
    var nombre_archivo = nombre + ".zip";
    var URL =
      "https://api.certificaciondigital.online/api/constancia/solicitud/" +
      idConstancia +
      "/descargar";

    var config = {
      method: "get",
      url: PROXY_URL + URL,
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + token,
      },
    };
    axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
    });
  };

  const getTotalDownloadPdf = (data) => {
    console.log("DATA TYPE:", data.type.id);
    if (data.type.id === 3) {
      handleDescargarCDO(data.filename, data.status.id, data.cdo_contratos_id);
    } else {
      if (data.status.name === "firmado") {
        getZipDocument(data._id, data.filename);
      } else {
        //handleOpenPDF(data._id);
        handleDescargarPDF(data._id, data.filename);
      }
    }
  };

  //funcion para descargar el paquete de constancia del documentos

  const getZipDocument = (idDocu, fileName) => {
    const nameFormat = fileName.slice(0, -4);
    setOpenZip(true);

    let token = localStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocu}/bundle/download`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nameFormat}.zip`);
      document.body.appendChild(link);
      link.click();
      setOpenZip(false);
    });
  };
  const eliminarContratoCDO = (idcontrato) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idcontrato +
      "/delete";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Contrato CDO eliminado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDelete = () => {
    let token = localStorage.getItem("Token");

    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/documentos/${idDelete}`;
    console.log("URL DELETE");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log("res Delete", response);
        //eliminar contrato CDO
        if (idDeleteCDO) {
          eliminarContratoCDO(idDeleteCDO);
        }

        setopenDelete(false);
      })
      .catch((error) => {
        // Error 😨

        alert(error.data.message);
      });
    //eliminar contrato CDO
    if (idDeleteCDO) {
      eliminarContratoCDO(idDeleteCDO);
    }
  };

  const handleDescargarPDF = async (idDocu, nombre) => {
    const token = localStorage.getItem("Token");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocu}/download`,
      //responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config).then((response) => {
      const linkSource = `data:application/pdf;base64,${response.data}`;
      const downloadLink = document.createElement("a");
      const fileName = nombre;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      /*
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
      */
    });
    /*
    const token = localStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDocu}/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
      */
  };
  const handleDescargarCDO = (nombre, status, id) => {
    var nombre_archivo = nombre;
    var URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      id +
      "/descargar-certificado";
    if (status === 3) {
      nombre_archivo = nombre + ".zip";
    } else {
      URL =
        "https://api.certificaciondigital.online/api/contrato/" +
        id +
        "/descargar";
    }

    var config = {
      method: "get",
      url: PROXY_URL + URL,
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + token,
      },
    };
    axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // FUNCION  PARA TRAER EL CONTENIDO DE UNA CARPETA
  const HandleChildren = (data) => {
    setPatch(data.path);
    const idFolder = data._id;
    setItemDataOk([]);
    let token = localStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/categorias/${idFolder}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("CATALOGO2", res.data);
        const okreturn = handleItemDocu(res.data);

        console.log("OKRETURN", okreturn[0].length);

        setItemDataOk(okreturn);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //funcion para iterar las carpetas y documentos dentro de una carpeta
  const handleItemDocu = (data) => {
    let collectionData = [];
    var itemdocs = null;
    var carpetas = null;
    console.log("DATAITEM", data[0].documentos.length);

    if (data[0].documentos.length == 0 && data.length == 1) {
      return [<Typography paragraph>La carpeta esta vacia</Typography>];
    }
    if (data[0].documentos) {
      itemdocs = data[0].documentos.map((itemdocu) => {
        return (
          <div>
            <ListItem button key={itemdocu.filename}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={itemdocu.filename} />
              <div>
                <Tooltip
                  id="tooltip-top"
                  title="Descargar Documento"
                  placement="top"
                >
                  <IconButton
                    onClick={() => handleDetalles(itemdocu.id, "descarga")}
                    aria-label="more"
                    edge="end"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title=" Ver documento"
                  placement="top"
                >
                  <IconButton
                    onClick={() => handlePDFB64(itemdocu.id)}
                    aria-label="more"
                    edge="end"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip id="tooltip-top" title="Participantes" placement="top">
                  <IconButton
                    onClick={() => handleDetalles(itemdocu.id, "participantes")}
                    aria-label="more"
                    edge="end"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <PeopleIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Información del documento"
                  placement="top"
                >
                  <IconButton
                    onClick={() => handleDetalles(itemdocu.id, "info")}
                    aria-label="more"
                    edge="end"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </ListItem>
          </div>
        );
      });
    }
    collectionData.push(itemdocs);
    if (data.length > 1) {
      var dataFolder = data;
      dataFolder.splice(0, 1);

      console.log("jnjs", dataFolder);
      carpetas = dataFolder.map((ListItemData, index) => {
        return (
          <ListItem button key={ListItemData.name}>
            <ListItemIcon onClick={() => HandleChildren(ListItemData)}>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText
              onClick={() => HandleChildren(ListItemData)}
              primary={ListItemData.name}
            />
            {ListItemData.child_id == null ? (
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon onClick={() => handleDeleteFolder(ListItemData)} />
              </IconButton>
            ) : (
              ""
            )}
          </ListItem>
        );
      });
      collectionData.push(carpetas);
      console.log("CAERPETAS", carpetas);
    }

    return collectionData;
  };
  const handleclosepdf = () => {
    setOpenPDF(false);
  };
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function onChangeAlmacenar() {
    setArchivoAlmacenar(document.querySelector('input[type="file"]').files[0]);

    setOpenAlmacenar(true);
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Archivo: {patch}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        PaperProps={{
          style: {
            position: "absolute",
          },
        }}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {dataFolder.map((ListItemData) => {
            if (ListItemData.depth == 0) {
              return (
                <ListItem
                  button
                  key={ListItemData.name}
                  onClick={() => HandleChildren(ListItemData)}
                >
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText primary={ListItemData.name} />
                </ListItem>
              );
            } else {
              return null;
            }
          })}
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {/*AQUI SE RENDERIZA EL CONTENIDO DE LAS CARPETAS */}
        <div>{ItemDataok}</div>
      </main>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={classes.speedDial}
        hidden={hidden}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={openSpeed}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>

      <DialogComp
        open={openPDF}
        title="Tu documento"
        maxWidth={"md"}
        content={
          pdfBase64.length === 0 ? (
            <Backdrop className={classespro.backdrop} open={open}>
              <CircularProgress
                size={44}
                className={classespro.buttonProgress}
              />
            </Backdrop>
          ) : (
            <div align="center">
              <ControlPanel
                scale={scale}
                setScale={setScale}
                numPages={numPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                file={`data: application / pdf; base64, ${pdfBase64} `}
              />
              <Document
                error="Error al intentar cargar el PDF"
                loading="Cargando..."
                file={`data: application / pdf; base64, ${pdfBase64} `}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  scale={scale}
                  height={pdfHeigth}
                  width={pdfWidth}
                  renderTextLayer={false}
                  pageNumber={pageNumber}
                />
              </Document>

              <div className="text-center">
                <p>
                  Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                  {numPages || "--"}
                </p>
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  Atrás
                </button>{" "}
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  Siguiente
                </button>
              </div>
            </div>
          )
        }
        buttons={[
          {
            buttonEvent: handleclosepdf,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* DIALOGO PARA AGREGAR CARPETAS */}
      <DialogComp
        open={openCarpetas}
        title={" Ingresa la ruta y/o nombre de la carpeta:"}
        maxWidth={"md"}
        content={
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Ruta de la carpeta"
              variant="outlined"
              fullWidth
              value={folder}
              onChange={(e) => {
                setFolder(e.target.value);
              }}
            />
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setOpenCarpetas(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: () => crearCarpeta(),
            buttonName: "Guardar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* Dialogo de espera de descarga de paquete de documentos firmados */}
      <DialogComp
        open={openZip}
        title="Descarga de documento"
        content=" La carpeta con los documentos firmados se descargaran al lugar habitual de Descargas: Esto puede tardar unos segundos..."
        grid={true}
      />
      <DialogComp
        open={modalFirmantesCC}
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {
          setModalFirmantesCC(false);
        }}
        title="AGREGAR FIRMANTE"
        content={
          <div>
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  {
                    title: "Nombres",
                    field: "name",
                    render: (row) => (
                      <span>{row["name"] + " " + row["last_name"]}</span>
                    ),
                  },

                  {
                    title: "Prueba de vida",
                    field: "speech_required",
                    render: (row) => (
                      <span>
                        {row["speech_required"] == true ? (
                          <Tooltip
                            id="tooltip-top"
                            title="Se solicito prueba de vida"
                            placement="top"
                            classes={{
                              tooltip: classes2.tooltip,
                            }}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes2.tableActionButton}
                            >
                              <RecordVoiceOverIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          "No se solicito"
                        )}
                      </span>
                    ),
                  },
                  { title: "Compañía", field: "company" },
                  {
                    title: "Medio de Notificación",
                    field: "notification_types_id",
                    render: (row) => (
                      <span>
                        {row["notification_types_id"] === 1
                          ? row["email"]
                          : row["phone_country_code"] + row["phone"]}
                      </span>
                    ),
                  },
                  {
                    title: "Estatus del Proceso",
                    field: "date_signed",
                    render: (row) => (
                      <span>
                        {row["refused_comment"] && row["refused_comment"] !== ""
                          ? "Rechazó"
                          : row["role"] === "Notificado"
                          ? "Notificado"
                          : row["date_signed"]
                          ? "Firmó"
                          : row["firmas"] && row["firmas"].length > 0
                          ? "Pendiente de confirmar código"
                          : "Pendiente de Firma"}
                      </span>
                    ),
                  },
                  {
                    title: "Registrado",
                    field: "created_at",
                    render: (row) => (
                      <span>
                        {row["created_at"]
                          ? moment(row["created_at"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Enviado",
                    field: "date_notified",
                    render: (row) => (
                      <span>
                        {row["date_notified"]
                          ? moment(row["date_notified"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Aviso de Privacidad Aceptado",
                    field: "date_accepted",
                    render: (row) => (
                      <span>
                        {row["date_accepted"]
                          ? moment(row["date_accepted"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Visto",
                    field: "date_accepted",
                    render: (row) => (
                      <span>
                        {row["date_accepted"]
                          ? moment(row["date_accepted"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Firmado",
                    field: "date_signed",
                    render: (row) => (
                      <span>
                        {row["date_signed"]
                          ? moment(row["date_signed"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Comentarios",
                    field: "refused_comment",
                  },
                ]}
                data={listaFirmantes}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {tipoDoc === 6 ? "Notificados" : "Firmantes"}
                  </Typography>
                }
              />
            ) : null}
            <hr />
            {listaNotificados ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre", field: "name" },
                  { title: "Apellido", field: "last_name" },
                  { title: "Email", field: "email" },
                ]}
                data={listaNotificados}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {tipoDoc === 6 ? "Observadores" : "Notificados"}
                  </Typography>
                }
              />
            ) : null}
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setModalFirmantesCC(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* Dialogo de ver datos del documento info */}

      <DialogComp
        open={openDialogInfo}
        onClose={() => setopenFirmantesRechazados(false)}
        title="Información del documento"
        content={
          <div>
            <b>Nombre del documento:</b> {docuDataPerson.filename}
            <br></br>
            <hr></hr>
            <b>Compañia emisora:</b>{" "}
            {docuDataPerson.corporacion != null
              ? docuDataPerson.corporacion.name
              : ""}
            <br></br>
            <hr></hr>
            {docuDataPerson.description ? (
              <React.Fragment>
                <b>Descripción:</b> {docuDataPerson.description}
                <br></br>
                <hr></hr>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Descripción:</b> {""}
                <br></br>
                <hr></hr>
              </React.Fragment>
            )}
            {docuDataPerson.reason ? (
              <React.Fragment>
                <b>Fecha de detención:</b>{" "}
                {moment(docuDataPerson.stopped_at).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                <br></br>
                <b>Motivo:</b> {docuDataPerson.reason}
                <br></br>
                <hr></hr>
              </React.Fragment>
            ) : null}
            <b>Hash:</b> {docuDataPerson.hash}
            <br></br>
            <hr></hr>
            <b>ID el documento:</b> {docuDataPerson._id}
            <br></br>
            <hr></hr>
            <b>Tamaño de documento:</b> {docuDataPerson.size}
            <br></br>
            <hr></hr>
            {docuDataPerson.meta && docuDataPerson.meta.vigencia_start ? (
              <React.Fragment>
                <b>Inicio de Vigencia:</b>{" "}
                {moment(docuDataPerson.meta.vigencia_start).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                <br></br>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Inicio de Vigencia:</b> <br></br>
              </React.Fragment>
            )}
            {docuDataPerson.meta && docuDataPerson.meta.vigencia_end ? (
              <React.Fragment>
                <b>Término de Vigencia:</b>{" "}
                {moment(docuDataPerson.meta.vigencia_end).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                <br></br>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Término de Vigencia:</b> <br></br>
              </React.Fragment>
            )}
            {docuDataPerson.meta && docuDataPerson.meta.dias_alerta ? (
              <React.Fragment>
                <b>Alerta de Vencimiento:</b> {docuDataPerson.meta.dias_alerta}{" "}
                días
                <br></br>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Alerta de Vencimiento:</b> <br></br>
              </React.Fragment>
            )}
            {docuDataPerson.meta && docuDataPerson.meta.info ? (
              <React.Fragment>
                <hr />
                <font color="black">
                  <b>Metadatos adicionales:</b>
                </font>
                <hr />
                <ShowMeta json={docuDataPerson.meta.info} />
              </React.Fragment>
            ) : null}
          </div>
        }
        buttons={[
          {
            buttonEvent: handleCloseInfo,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleChangeDataDocuPerson,
            buttonName: "Modificar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* Dialogo para modificar metadata  */}
      <DialogComp
        open={openModificar}
        onClose={() => setopenDelete(false)}
        title="Modificar datos"
        content={
          <>
            <Container maxWidth="xs">
              <Box width={1} bgcolor="grey.300" p={1} my={0.5}>
                <Paper elevation={3} style={{ padding: 20 }}>
                  <Grid container>
                    <Grid item xs={6} sm={12}>
                      <p align="center">
                        <b>Información general del documento</b>
                      </p>
                      <hr />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      Descripción:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                        maxRows={4}
                        inputProps={{
                          maxlength: 360,
                        }}
                        placeholder=""
                        helperText={descripcion && `${descripcion.length}/360`}
                        value={descripcion}
                        //style={{ width: "100%" }}
                        onChange={(e) => {
                          console.log("descripcion", e.target.value); // Works
                          setDescripcion(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      Inicio vigencia:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="dateDesde"
                        type="date"
                        value={desde}
                        onChange={(e) => {
                          console.log("inicio", e.target.value); // Works
                          //setInicio(e.target.value);
                          setDesde(e.target.value);

                          if (e.target.value > hasta) {
                            setAlerta(
                              "La fecha de término debe ser superior a la fecha de inicio"
                            );
                          } else {
                            setAlerta("");
                          }
                          var date = new Date(e.target.value);
                          // var f = new Date();

                          // var hoy = new Date("Y-m-d");
                          if (date.getFullYear() > 2300) {
                            showNotificacion("La fecha de inicio es inválida");
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      Término vigencia:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="dateHasta"
                        type="date"
                        value={hasta}
                        onChange={(e) => {
                          console.log("termino", e.target.value); // Works
                          setHasta(e.target.value);

                          if (e.target.value < desde) {
                            //alert("La fecha de término debe ser superior a la fecha de inicio")
                            setAlerta(
                              "La fecha de término debe ser superior a la fecha de inicio"
                            );
                          } else {
                            setAlerta("");
                          }
                          var date = new Date(e.target.value);
                          if (date.getFullYear() > 2300) {
                            showNotificacion("La fecha de término es inválida");
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    {alerta ? (
                      <Grid item xs={12} sm={12}>
                        <font size="2" color="red">
                          <b>{alerta}</b>
                        </font>
                      </Grid>
                    ) : null}

                    {hasta ? (
                      <React.Fragment>
                        <Grid item xs={12} sm={2}></Grid>
                        <Grid
                          item
                          xs={12}
                          sm={7}
                          className={classes.textodiaslabel}
                        >
                          <Checkbox
                            checked={checked}
                            onChange={handleChangeBox}
                            color="default"
                            //inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          <font size="2" color="green">
                            Alerta de vencimiento:
                          </font>
                        </Grid>
                        <Grid item xs={12} sm={2} className={classes.textodias}>
                          <TextField
                            //variant="outlined"
                            id="standard-number"
                            type="number"
                            value={diasAlerta}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              min: 1,
                              max: 90,
                              style: { fontSize: 15, color: "green" },
                            }}
                            style={{ width: "80%" }}
                            onChange={(e) => {
                              console.log("días", e.target.value); // Works
                              setDiasAlerta(e.target.value);
                              setDiasAlert(e.target.value);
                              if (e.target.value < 2 || e.target.value > 90) {
                                showNotificacion(
                                  "La alerta de vencimiento debe comprender de mínimo 2 a máximo 90 días."
                                );
                                setDiasAlerta(30);
                                setDiasAlert(30);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={1} className={classes.textodias}>
                          <font size="2" color="green">
                            días
                          </font>
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </Grid>
                </Paper>
              </Box>
            </Container>
          </>
        }
        buttons={[
          {
            buttonEvent: handleCancelarActualizacion,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleModificar,
            buttonName: "Modificar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* Dialogo para eliminar el documento  */}
      <DialogComp
        open={openDelete}
        onClose={() => setopenDelete(false)}
        title="Eliminar documento"
        content="¿Está seguro de eliminar este documento?"
        buttons={[
          {
            buttonEvent: () => setopenDelete(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleDelete,
            buttonName: "Eliminar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* DIALOGO PARA ABRIR EL COMPONENTE DE LOS BÓVEDA*/}
      <DialogComp
        open={openAlmacenar}
        title={""}
        fullWidth={true}
        fullScreen={true}
        maxWidth={"lg"}
        content={
          <div>
            {archivoAlmacenar ? (
              <Almacenar
                file={archivoAlmacenar}
                carpeta={patch}
                setOpen={setOpenAlmacenar}
                setFile={setArchivoAlmacenar}
                setRefresh={handleCatalogo}
              />
            ) : null}
          </div>
        }
      />
    </div>
  );
}
