import React from "react";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SearchIcon from '@material-ui/icons/Search';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import formatoarchivos from "./formatoarchivos.xlsx";
import { ReactExcel, readFile, generateObjects } from "@ramonak/react-excel";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//import 'react-folder-tree/dist/style.css';
//import './style.css';

const useStyles = makeStyles((theme) => ({
  helperText: {
    //backgroundColor: "red",
    //color:"white"
    fontSize:8,
    marginTop:0,
  },
  botondanger: {
    backgroundColor: "red",
    color:"white"
  },
  botongreen: {
    backgroundColor: "green",
    color:"white"
  },
  botonwhite: {
    backgroundColor: "white",
    color:"gray"
  },
  iconsmall: {
    fontSize:16,
    margin:2,
    marginTop:11, //15 center
    padding:0,
  },
  nombrecatalogo:{
    fontSize:16,
    marginTop:7,
    marginLeft:10,
  },
  nombrearchivo:{
    marginTop:6,
    fontSize:14,
  },
  iconarchivo:{
    marginTop:0,
    fontSize:10,
  },
  paper:{
    width:"95%",
  },
  colapse:{
    marginLeft:20
  }

}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Catalogos() {
  const classes = useStyles();
  const [catalogos, setCatalogos] = React.useState("");
  const [nombreCatalogo, setNombreCatalogo] = React.useState("");
  const [descripcionCatalogo, setDescripcionCatalogo] = React.useState("");
  const [nombreCatalogoModificar, setNombreCatalogoModificar] = React.useState("");
  const [descripcionCatalogoModificar, setDescripcionCatalogoModificar] = React.useState("");
  const [catalogoEliminar, setCatalogoEliminar] = React.useState("");
  const [catalogoModificar, setCatalogoModificar] = React.useState("");

  const [modalNuevo, setModalNuevo] = React.useState(false);
  const [modalModificar, setModalModificar] = React.useState(false);
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [modalAgregar, setModalAgregar] = React.useState(false);
  const [modalEliminarArchivo, setModalEliminarArchivo] = React.useState(false);
  const [modalModificarArchivo, setModalModificarArchivo] = React.useState(false);
  const [agregarSiguiente, setAgregarSiguiente] = React.useState(false);
  const [nombreArchivo, setNombreArchivo] = React.useState("");
  const [descripcionArchivo, setDescripcionArchivo] = React.useState("");
  const [nombreArchivoModificar, setNombreArchivoModificar] = React.useState("");
  const [descripcionArchivoModificar, setDescripcionArchivoModificar] = React.useState("");
  const [archivoEliminar, setArchivoEliminar] = React.useState("");
  const [archivoModificar, setArchivoModificar] = React.useState("");
  const [order, setOrder] = React.useState("");

  const [modalAgregarVarios, setModalAgregarVarios] = React.useState(false);
  const [initialData, setInitialData] = React.useState(undefined);
  const [currentSheet, setCurrentSheet] = React.useState({});
  const [archivo, setArchivo] = React.useState(false);

  const [valueRequerido, setValueRequerido] = React.useState("1");
  const handleChangeRequerido = (event) => {
      setValueRequerido(event.target.value);
    };
    const [valueRequeridoModificar, setValueRequeridoModificar] = React.useState("");
    const handleChangeRequeridoModificar = (event) => {
        setValueRequeridoModificar(event.target.value);
      };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    //read excel file
    readFile(file)
      .then((readedData) => {
        setInitialData(readedData);
        setArchivo(true);
        //console.log("data:",readedData);
      })
      .catch((error) => console.error(error));
  };



  const [openMensaje, setOpenMensaje] = React.useState(false);
  const [mensaje, setMensaje] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const handleCloseMensaje = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   setOpenMensaje(false);
  };

function alerta(texto,severidad){
  setOpenMensaje(true);
  setMensaje(texto);
  setSeverity(severidad);
}


  let disabledNuevo = true;
  if(nombreCatalogo!=""){
    disabledNuevo=false;
  }

  let disabledVarios = true;
  if(nombreCatalogo!="" && archivo==true){
    disabledVarios=false;
  }
  let disabledModificar = true;
  if(nombreCatalogoModificar!=""){
    disabledModificar=false;
  }
  let disabledModificarArchivo = true;
  if(nombreArchivoModificar!=""){
    disabledModificarArchivo=false;
  }

  let disabledAgregar = true;
  if(nombreArchivo!=""){
    disabledAgregar=false;
  }


  async function getCatalogos(){
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
    method: 'get',
    url: `${process.env.REACT_APP_URL}api/catalogos`,
    headers: {
      'Accept': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
      axios(config)
        .then(function (response) {
          console.log("Catalogos:",response.data);
          setCatalogos(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setCatalogos("");
        });
  }

async function handleGuardarNuevo(){
    console.log("nuevo");
    var dataForm = new FormData();
    dataForm.append('name', nombreCatalogo);
    if(descripcionCatalogo!=""){
      dataForm.append('description', descripcionCatalogo);
    }
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_URL}api/catalogos`,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data : dataForm
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          //setCatalogoModificar(response.data);
          setModalNuevo(false);
          getCatalogos();
          alerta("Nuevo catálogo creado","info")
          setNombreCatalogo("");
          setDescripcionCatalogo("");
        })
        .catch(function (error) {
          console.log(error);
          alerta("Error al crear catálogo","error")
        });
/*
      return new Promise(function (resolve, reject) {
    		axios(config).then(
    			(response) => {
    				var result = response.data;
    				console.log('Processing Request');
    				resolve(result);
    			},
    				(error) => {
    				reject(error);
    			}
    		);
    	});
      */
}

async function handleGuardarNuevoLote(){
    console.log("nuevo LOTE");
    var dataForm = new FormData();
    dataForm.append('name', nombreCatalogo);
    if(descripcionCatalogo!=""){
      dataForm.append('description', descripcionCatalogo);
    }
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_URL}api/catalogos`,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data : dataForm
      };

      return new Promise(function (resolve, reject) {
    		axios(config).then(
    			(response) => {
    				var result = response.data;
    				console.log('Processing Request');
    				resolve(result);
    			},
    				(error) => {
    				reject(error);
    			}
    		);
    	});
}

function handleModificarArchivo(){
    //console.log(archivoModificar._id);
    var dataForm = new FormData();
    dataForm.append('name', nombreArchivoModificar);
    dataForm.append('required', valueRequeridoModificar);
    //dataForm.append('description', descripcionArchivoModificar);

    if(descripcionArchivoModificar!=""){
      dataForm.append('description', descripcionArchivoModificar);
    }

    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_URL}api/catalogodocumentos/${archivoModificar._id}`,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data : dataForm
      };

      axios(config)
        .then(function (response) {
          console.log("respuesta:",response.data);
          getCatalogos();
          setCatalogoModificar(response.data);
          alerta("Documento modificado","info")
          setModalModificarArchivo(false)

          setNombreArchivoModificar("");
          setDescripcionArchivoModificar("");
        })
        .catch(function (error) {
          console.log(error);
          alerta("Error al modificar documento","error");
        });
}

async function handleAgregarArchivo(){
    //se verific que el nombre no esté repetido
    var duplicado=false;
    for (var i = 0; i < catalogoModificar.documentos.length; i++) {
      if(catalogoModificar.documentos[i].name==nombreArchivo){
        alerta("Ya existe el documento "+nombreArchivo+" en este catálogo","warning");
        duplicado=true;
      }
    }
    if(duplicado)
      return(null);
    //fin se verifica
    var dataForm = new FormData();
    dataForm.append('catalog_id', catalogoModificar._id);
    dataForm.append('name', nombreArchivo);
    dataForm.append('required', valueRequerido);
    if(descripcionArchivo!=""){
      dataForm.append('description', descripcionArchivo);
    }

    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_URL}api/catalogodocumentos`,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data : dataForm
      };

      axios(config)
        .then(function (response) {
          console.log("respuesta:",response.data);
          getCatalogos();
          setCatalogoModificar(response.data);
          alerta("Documento creado","info")
          setNombreArchivo("");
          setDescripcionArchivo("");
        })
        .catch(function (error) {
          console.log(error);
          alerta("Error al agregar archivo","warning");
        });

}

async function handleModificar(){
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
        method: 'put',
        url: `${process.env.REACT_APP_URL}api/catalogos/${catalogoModificar._id}?name=${nombreCatalogoModificar}&description=${descripcionCatalogoModificar}`,
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          setModalModificar(false);
          getCatalogos();
          alerta("Catálogo modificado","info")
        })
        .catch(function (error) {
          console.log(error);
          alerta("Error al modificar","warning")
        });
}

function handleEliminar(){
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
  method: 'delete',
  url: `${process.env.REACT_APP_URL}api/catalogos/${catalogoEliminar._id}`,
  headers: {
    'Accept': 'application/json',
    Authorization: `Bearer ${token}`,
  }
};
    axios(config)
      .then(function (response) {
        console.log("Catalogo Eliminado:",response.data);
        getCatalogos();
        setModalEliminar(false);
        alerta("El catálogo ha sido eliminado","warning")
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al eliminar catálogo","error")
      });
}

function handleEliminarArchivo(){
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
  method: 'delete',
  url: `${process.env.REACT_APP_URL}api/catalogodocumentos/${archivoEliminar._id}`,
  headers: {
    'Accept': 'application/json',
    Authorization: `Bearer ${token}`,
  }
};
    axios(config)
      .then(function (response) {
        console.log("Documento Eliminado:",response.data);
        getCatalogos();
        setCatalogoModificar(response.data);
        setModalEliminarArchivo(false);
        alerta("El documento ha sido eliminado","warning")
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error eliminando documento","error")
      });
}

function ListItemsTreeView(){
  return (
    <div>
      {catalogos.map((catalogo) => (
        <React.Fragment key={catalogo._id}>
        <TreeView
          //key={catalogo._id}
          className={classes.root}
          defaultCollapseIcon={<FolderOpenIcon color="primary" className={classes.colapse} />}
          defaultExpandIcon={<FolderIcon color="primary" className={classes.colapse} />}
        >
          <TreeItem nodeId="0"
            label={
              <Grid container>
                <Grid item xs={10}>
                  <Typography className={classes.nombrecatalogo}>
                    {catalogo.name}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Tooltip title="Agregar o eliminar documentos" placement="top">
                    <PostAddIcon
                      className={classes.iconsmall}
                      color="primary"
                      onClick={()=>{
                        console.log("catalogo",catalogo)
                        setCatalogoModificar(catalogo);
                        setModalAgregar(true);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Editar catálogo" placement="top">
                    <EditIcon
                      className={classes.iconsmall}
                      color="primary"
                      onClick={()=>{
                        setCatalogoModificar(catalogo);
                        setNombreCatalogoModificar(catalogo.name);
                        setDescripcionCatalogoModificar(catalogo.description);
                        if (catalogo.description=="" || catalogo.description==null) {
                          setDescripcionCatalogoModificar("Sin Descripción");
                        }
                        setModalModificar(true);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Eliminar catálogo" placement="top">
                    <DeleteIcon
                      className={classes.iconsmall}
                      color="primary"
                      onClick={()=>{
                        setCatalogoEliminar(catalogo);
                        setModalEliminar(true);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={(catalogo.description) ? catalogo.description:"Sin descripción" } placement="top">
                    <InfoIcon
                      className={classes.iconsmall}
                      color="primary"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            }
            className={classes.root}
          >
              {catalogo.documentos.map((item,key) => (
                <TreeItem nodeId={item._id} key={key} label={
                  <Grid container>
                  {/*
                    <Grid item>
                    <DescriptionIcon
                      className={classes.iconarchivo}
                      //color="primary"
                    />
                    </Grid>
                    */}
                    <Grid item>
                      <Typography className={classes.nombrearchivo}>
                        {item.name} <font size="1">{item.required==="1" ? <b>(Requerido)</b>:<>(Opcional)</>}</font>
                      </Typography>
                    </Grid>
                    {item.description ? (
                      <Grid item>
                      <Tooltip title={item.description} placement="top">
                        <InfoIcon
                          className={classes.iconsmall}
                          color="primary"
                        />
                      </Tooltip>
                    </Grid>
                    ):null}
                    <Grid item>
                      <Tooltip title="Editar datos" placement="top">
                        <EditIcon
                          className={classes.iconsmall}
                          color="primary"
                          onClick={()=>{
                            console.log("Modificar:",item);
                            setNombreArchivoModificar(item.name);
                            if(item.description)
                              setDescripcionArchivoModificar(item.description);

                            if(item.required)
                              setValueRequeridoModificar(item.required);
                            else
                              setValueRequeridoModificar("0");

                            setArchivoModificar(item);
                            setModalModificarArchivo(true);
                          }}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                    <Tooltip title="Eliminar documento" placement="top">
                      <DeleteIcon
                        className={classes.iconsmall}
                        color="primary"
                        onClick={()=>{
                          setArchivoEliminar(item);
                          setModalEliminarArchivo(true);
                        }}
                      />
                    </Tooltip>
                    </Grid>
                  </Grid>
                } />
              ))}
          </TreeItem>
        </TreeView>
        <hr />
        </React.Fragment>
      ))}
    </div>
  );
};

function handleOrdenar(){
  if(order=="" || order=="des"){
    setOrder("asc");
    catalogos.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
  }
  else{
    setOrder("des");
    catalogos.sort(function (a, b) {
        if (a.name < b.name) {
          return 1;
        }
        if (a.name > b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
  }
}

async function handleAgregarArchivosLote(nuevo,nombre,requerido){
  var dataForm = new FormData();
  dataForm.append('catalog_id', nuevo._id);
  dataForm.append('name', nombre);
  dataForm.append('required', requerido);

  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL}api/catalogodocumentos`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data : dataForm
    };

    return new Promise(function (resolve, reject) {
      axios(config).then(
        (response) => {
          var result = response.data;
          console.log('Processing Request');
          resolve(result);
        },
          (error) => {
          reject(error);
          alerta("Error al agregar archivo","warning");
        }
      );
    });
}


async function handleAgregarVarios(){
  const nuevo = await handleGuardarNuevoLote();
  console.log("NUEVO:",nuevo);
  const result = generateObjects(currentSheet);
  console.log("Result Varios:", result);
    for (var i = 0; i < result.length; i++) {
      await handleAgregarArchivosLote(nuevo,result[i].NOMBRE,result[i].REQUERIDO)
    }
  getCatalogos();
  setModalAgregarVarios(false);
}

React.useEffect(()=>{
  getCatalogos();
},[]);

  return (
    <React.Fragment>
    <Grid container justify = "center">
     <Paper elevation={3} className={classes.paper}>
    {/*BOTONES SUPERIORES*/}
    <IconButton
      aria-label="Add"
      color="primary"
      onClick={()=>{
        setModalNuevo(true);
      }}
    >
      <Tooltip title="Crear nuevo catálogo" placement="top">
        <AddCircleIcon />
      </Tooltip>
    </IconButton>

    <IconButton
      aria-label="Add"
      color="primary"
      onClick={()=>{
        setModalAgregarVarios(true);
      }}
    >
      <Tooltip title="Importar desde archivo" placement="top">
        <CloudUploadIcon />
      </Tooltip>
    </IconButton>

      <IconButton
        aria-label="Add"
        color="primary"
        onClick={()=>{
          handleOrdenar();
        }}
      >
        <Tooltip title="Ordenar listado" placement="top">
          <SortByAlphaIcon />
        </Tooltip>
      </IconButton>
      {/*
      <IconButton aria-label="Add" color="primary">
        <Tooltip title="Buscar catálogo" placement="top">
          <SearchIcon />
        </Tooltip>
      </IconButton>
      */}

    {/*FIN BOTONES SUPERIORES*/}

    {/*LISTADO DE CATALOGOS*/}
    <AppBar position="static">
      <b><font style={{margin:10}}>Catálogos</font></b>
    </AppBar>
    <div style={{background:"white"}}>
      {catalogos ? (
        <ListItemsTreeView />
      ):null}
    </div>
    {/*FIN LISTADO DE CATALOGOS*/}
    </Paper>
    </Grid>

    {/*DIALOGO NUEVO CATÁLOGO*/}
    <Dialog
      open={modalNuevo}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
      </DialogTitle>
      <DialogContent>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            Por favor ingresar datos:
          </Typography>
      <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          label="Nombre del catálogo"
          variant="outlined"
          fullWidth
          inputProps={{
            maxlength: 128
          }}
          FormHelperTextProps={{
           className: classes.helperText
         }}
          helperText={`${nombreCatalogo.length}/128`}
          value={nombreCatalogo}
          onChange={(e)=>{
            setNombreCatalogo(e.target.value)
          }}
        />

        <TextField
          autoFocus
          margin="dense"
          id="descripcion_catalogo"
          label="Descripción / Comentarios"
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          inputProps={{
            maxlength: 256
          }}
          FormHelperTextProps={{
           className: classes.helperText
         }}
          helperText={`${descripcionCatalogo.length}/256`}
          value={descripcionCatalogo}
          onChange={(e) => {
            setDescripcionCatalogo(e.target.value);
          }}
        />
        {/*
        <TextareaAutosize
          aria-label="minimum height"
          rowsMin={4}
          style={{width:"100%"}}
          onChange={(e) => {
            setDescripcionCatalogo(e.target.value);
          }}
        />
        */}
      </DialogContent>
      <DialogActions>
      <Button
        variant="contained"
        onClick={()=>{
          setModalNuevo(false)
          setNombreCatalogo("");
          setDescripcionCatalogo("");
        }}
        className={classes.botondanger}
      >
        Cancelar
      </Button>
        <Button
          variant="contained"
          disabled={disabledNuevo}
          onClick={() => {
            handleGuardarNuevo();
          }}
          className={classes.botongreen}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
    {/* FIN NUEVO CATÁLOGO*/}

    {/*DIALOGO MODIFICAR CATÁLOGO*/}
    <Dialog
      open={modalModificar}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
      </DialogTitle>
      <DialogContent>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            Por favor completar datos:
          </Typography>
      <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nombre del catálogo"
          variant="outlined"
          fullWidth
          FormHelperTextProps={{
           className: classes.helperText
          }}
          helperText={`${nombreCatalogoModificar.length}/128`}
          value={nombreCatalogoModificar}
          onChange={(e)=>{
            setNombreCatalogoModificar(e.target.value)
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="descripcion_catalogo_modificar"
          label="Descripción / Comentarios"
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          inputProps={{
            maxlength: 256
          }}
          FormHelperTextProps={{
           className: classes.helperText
         }}
          helperText={`${descripcionCatalogoModificar.length}/256`}
          value={descripcionCatalogoModificar}
          onChange={(e) => {
            setDescripcionCatalogoModificar(e.target.value);
          }}
        />
        {/*
        <TextareaAutosize
          aria-label="minimum height"
          rowsMin={4}
          style={{width:"100%"}}
          value={descripcionCatalogoModificar}
          onChange={(e) => {
            setDescripcionCatalogoModificar(e.target.value);
          }}
        />
        */}
      </DialogContent>
      <DialogActions>
      <Button
        variant="contained"
        color="primary"
        //size="md"
        //type="button"
        onClick={()=>{
          setModalModificar(false)
        }}
        //className={classes.botondanger}
      >
        Cerrar
      </Button>
        <Button
          variant="contained"
          disabled={disabledModificar}
          onClick={() => {
            handleModificar();
          }}
          className={classes.botongreen}
        >
          Modificar
        </Button>
      </DialogActions>
    </Dialog>
    {/* FIN MODIFICAR CATÁLOGO*/}

    {/*DIALOGO ELIMINAR CATÁLOGO*/}
    <Dialog
      open={modalEliminar}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
      </DialogTitle>
      <DialogContent>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            ¿Está seguro de eliminar el catálogo?<br /> <b>{catalogoEliminar.name}</b>
          </Typography>
      </DialogContent>
      <DialogActions>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          setModalEliminar(false)
        }}
        //className={classes.botondanger}
      >
        Cerrar
      </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleEliminar();
          }}
          className={classes.botondanger}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
    {/* FIN ELIMINAR CATÁLOGO*/}

    {/*DIALOGO AGREGAR ARCHIVOS*/}
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={modalAgregar}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {catalogoModificar.name}
      </DialogTitle>
      <DialogContent>
      <TextField
          autoFocus
          required
          margin="dense"
          id="name_archivo"
          label="Nombre del documento"
          variant="outlined"
          fullWidth
          value={nombreArchivo}
          onChange={(e)=>{
            setNombreArchivo(e.target.value)
          }}
        />
        <TextField
            autoFocus
            margin="dense"
            id="descripcion_archivo"
            label="Descripción / Instrucciones"
            variant="outlined"
            fullWidth
            multiline
            maxRows={3}
            value={descripcionArchivo}
            onChange={(e)=>{
              setDescripcionArchivo(e.target.value)
            }}
          />

          <FormControl component="fieldset">
            <RadioGroup row aria-label="position" name="position" value={valueRequerido} onChange={handleChangeRequerido}>
              <FormControlLabel
                value={"1"}
                control={<Radio color="primary" />}
                label="Requerido"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"0"}
                control={<Radio color="primary" />}
                label="Opcional"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
      <Paper elevation={3}>
      {catalogoModificar.documentos?catalogoModificar.documentos.map((item,key) => (
        <Grid container key={key}>
          <Grid item xs={10}>
            <li style={{ listStyleType: "none", marginLeft:5 }} >- {item.name} <font size="1">{item.required==="1" ? <b>(Requerido)</b>:<>(Opcional)</>}</font>
          {/*
          </Grid>
          <Grid item xs={2}>
          */}
            {item.description ? (
              <Tooltip title={item.description} placement="top">
                <InfoIcon
                  className={classes.iconsmall}
                  color="primary"
                />
              </Tooltip>
            ):null}

            <Tooltip title="Eliminar documento" placement="top">
              <DeleteIcon
                className={classes.iconsmall}
                color="primary"
                onClick={()=>{
                  setArchivoEliminar(item);
                  setModalEliminarArchivo(true);
                }}
              />
            </Tooltip>
            </li>
          </Grid>
        </Grid>
      )):null}
      </Paper>
      </DialogContent>
      <DialogActions>
      <Button
        variant="contained"
        color="primary"
        //color="danger"
        //size="md"
        //type="button"
        onClick={()=>{
          setModalAgregar(false);
          setAgregarSiguiente(false);
          setNombreArchivo("");
        }}
        //className={classes.botondanger}
      >
        Cerrar
      </Button>
        <Button
          variant="contained"
          disabled={disabledAgregar}
          onClick={() => {
            setAgregarSiguiente(true);
            handleAgregarArchivo();
          }}
          className={classes.botongreen}
        >
          {agregarSiguiente ? "Agregar otro":"Agregar"}
        </Button>
      </DialogActions>
    </Dialog>
    {/* FIN AGREGAR ARCHIVOS*/}

    {/*DIALOGO ELIMINAR ARCHIVO*/}
    <Dialog
      open={modalEliminarArchivo}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
      </DialogTitle>
      <DialogContent>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            ¿Está seguro de eliminar el Archivo?<br /> <b>{archivoEliminar.name}</b>
          </Typography>
      </DialogContent>
      <DialogActions>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          setModalEliminarArchivo(false)
        }}
        //className={classes.botondanger}
      >
        Cerrar
      </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleEliminarArchivo();
          }}
          className={classes.botondanger}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
    {/* FIN ELIMINAR ARCHIVO*/}

    {/*DIALOGO ELIMINAR ARCHIVO*/}
    <Dialog
      open={modalModificarArchivo}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
      </DialogTitle>
      <DialogContent>
      {/*
          <Typography variant="h6" style={{ textTransform: "none" }}>
            ¿Está seguro de modifciar el Archivo?<br /> <b>{archivoModificar.name}</b>
          </Typography>
          */}
          <TextField
              autoFocus
              margin="dense"
              id="name_archivo_modificar"
              label="Nombre del documento"
              variant="outlined"
              fullWidth
              value={nombreArchivoModificar}
              onChange={(e)=>{
                setNombreArchivoModificar(e.target.value)
              }}
            />
            <TextField
                autoFocus
                margin="dense"
                id="descripcion_archivo_modificar"
                label="Descripción / Instrucciones"
                variant="outlined"
                fullWidth
                multiline
                maxRows={3}
                value={descripcionArchivoModificar}
                onChange={(e)=>{
                  setDescripcionArchivoModificar(e.target.value)
                }}
              />

              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" value={valueRequeridoModificar} onChange={handleChangeRequeridoModificar}>
                  <FormControlLabel
                    value={"1"}
                    control={<Radio color="primary" />}
                    label="Requerido"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={"0"}
                    control={<Radio color="primary" />}
                    label="Opcional"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
      </DialogContent>
      <DialogActions>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          setModalModificarArchivo(false)
          setNombreArchivoModificar("");
          setDescripcionArchivoModificar("");
        }}
        //className={classes.botondanger}
      >
        Cerrar
      </Button>
        <Button
          variant="contained"
          disabled={disabledModificarArchivo}
          onClick={() => {
            handleModificarArchivo();
          }}
          className={classes.botondanger}
        >
          Modificar
        </Button>
      </DialogActions>
    </Dialog>
    {/* FIN MODICIAR ARCHIVO*/}

    {/*DIALOGO AGREGAR VARIOS*/}
    <Dialog
      open={modalAgregarVarios}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "black" }}>
          Elabore un documento en formato .xlsx utilizando el formato guía:
          <br />
        <font style={{ margin: 0, color: "darkblue" }} size="2">
          <b>
            <a
              style={{ color: "darkblue" }}
              href={formatoarchivos}
              download="formatoarchivos.xlsx"
            >
              [Descargar formato guía]
            </a>
          </b>
        </font>
        </DialogContentText>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            Por favor ingresar datos:
          </Typography>
          <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              label="Nombre del catálogo"
              variant="outlined"
              fullWidth
              value={nombreCatalogo}
              onChange={(e)=>{
                setNombreCatalogo(e.target.value)
              }}
            />
            <Typography variant="h6" style={{ textTransform: "none" }}>
              Descripción:
            </Typography>
            <TextareaAutosize
              aria-label="minimum height"
              rowsMin={2}
              style={{width:"100%"}}
              onChange={(e) => {
                setDescripcionCatalogo(e.target.value);
              }}
            />
            <input
              id="contained-button-excel"
              type="file"
              accept=".xlsx"
              onChange={handleUpload}
              style={{ display: "none" }}
            />
            <label htmlFor="contained-button-excel">
              <Button
                variant="contained"
                color="primary"
                size="small"
                component="span"
              >
                <CloudUploadIcon />
                cargar archivo (.xlsx)
              </Button>
            </label>
            <hr />
            <ReactExcel
              initialData={initialData}
              onSheetUpdate={(currentSheet) => setCurrentSheet(currentSheet)}
              activeSheetClassName="active-sheet"
              reactExcelClassName="react-excel"
            />
            <hr />
      </DialogContent>
      <DialogActions>
      <Button
        variant="contained"
        onClick={()=>{
          setInitialData(undefined);
          setCurrentSheet({});
          setModalAgregarVarios(false)
        }}
        className={classes.botondanger}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        disabled={disabledVarios}
        onClick={() => {
          handleAgregarVarios();
        }}
        className={classes.botongreen}
      >
        Guardar
      </Button>
      </DialogActions>
    </Dialog>
    {/* FIN AGREGAR VARIOS*/}

    {/*MENSAJE*/}
    <Snackbar
        open={openMensaje}
        autoHideDuration={2000}
        anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
        onClose={handleCloseMensaje}>
      <Alert onClose={handleCloseMensaje} severity={severity}>
        {mensaje}
      </Alert>
    </Snackbar>
    {/*FIN MENSAJE*/}
    </React.Fragment>
  )

}
