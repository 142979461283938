import React, { useState, useRef, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import axios from "axios";

function LogoFirma(props) {
  const imgRef = useRef();
  const [mtState, setmtState] = React.useState(1020);
  const { data, ubicacionFirmaHistoria, setUbicacionFirmaHistoria } = props;
  useEffect(() => {
    //console.log("REF:"+imgRef.current.getBoundingClientRect().left)
    console.log("TOPMT", props.topmt);
    console.log("DATACUADRO", data);
    console.log("DATAHISTORIA", ubicacionFirmaHistoria);
    if (props.topmt != undefined) {
      setmtState(props.topmt);
    }
  });

  var sidebar = 260;
  var mt = mtState;

  //540

  if (props.mobile) {
    sidebar = 0;
    mt = 1150;
  }
  const x = props.left - sidebar + props.coordenadas.x;
  const y = props.coordenadas.y;
  const b = props.bottom + y;

  if (props.mobile) {
    var dif1 = props.height;
  } else {
    var dif1 = props.height;
  }
  const dif = dif1 + y;
  //const dif = props.defy + y
  const top = mt + dif;

  var texto = props.texto;
  if (texto === "") {
    texto = "Firmante";
  }

  const EliminarUnaPosicionFirma = () => {
    console.log("FIRMAINDIVIDUAL", data);
    const collection = [...ubicacionFirmaHistoria];

    const currentIndex = collection.indexOf(data);
    console.log("valorfirmante", currentIndex);
    collection.splice(currentIndex, 1);
    setUbicacionFirmaHistoria(collection);

    //buscar cordenadas por id de firmante que pertenezcan a al firmante que vamos actualizar
    const firmantesFiltrados = collection.filter(
      (x) => x.idFirmante == data.idFirmante
    );
    console.log("filtroDelete", firmantesFiltrados);

    let token = localStorage.getItem("Token");

    var datajs = JSON.stringify({
      signature_coords: firmantesFiltrados,
    });
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_URL}api/firmantes/${data.idFirmante}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: datajs,
    };

    axios(config)
      .then((res) => {
        const data = res.data;

        console.log("modificacion de Gryop.", data);
      })
      .catch((error) => {
        alert("El campo signature_coords debe ser un array");
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <div>
        <div className="handle">
          <img
            ref={imgRef}
            src={props.src}
            width={props.w}
            height={props.h}
            style={{
              opacity: "50%",
              display: "",
              position: "absolute",
              left: x + 7,
              top: top,
            }}
            //style={{ opacity:'50%',display:'', position: 'absolute', left: x, bottom: -b }}
          />
          <div
            style={{
              display: "",
              position: "absolute",
              left: x + 14,
              top: top,
            }}
          >
            <font color="white">{texto}</font>
          </div>
          <div
            style={{
              display: "",
              position: "absolute",
              left: x + 72,
              top: top - 19,
            }}
          >
            <Tooltip
              id="tooltip-top"
              title="Eliminar firma"
              placement="top"
              style={{ left: 24 }}
            >
              <IconButton aria-label="Edit">
                <CancelIcon
                  style={{ color: "#F20A0A" }}
                  onClick={() => EliminarUnaPosicionFirma()}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LogoFirma;
