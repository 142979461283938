import React from "react";
import axios from "axios";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ArchiveIcon from '@material-ui/icons/Archive';
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import VisibilityIcon from '@material-ui/icons/Visibility';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from '@material-ui/core/Button';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import PostAddIcon from '@material-ui/icons/PostAdd';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/Apps';
import ExpedienteGraph from "./ExpedienteGraph";
import ExpedienteNodos from "./ExpedienteNodos";
import MisPendientes from "./MisPendientes";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import ExpedienteArchivosDisplay from "./ExpedienteArchivos";
import ExpedienteCarpetas from "./ExpedienteCarpetas";
import Reportar from "./Reportar";
import ExpedientesTable from "./ExpedientesTable";
import FolderTree, { testData } from 'react-folder-tree';
import CircularProgress from "@material-ui/core/CircularProgress";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from "react-redux";


import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
const useStylestable = makeStyles(styles2);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  appbar:{
    //backgroundColor:"#3f51b5",
    backgroundColor:"white",
  },
  titulo:{
    backgroundColor:"#3f51b5",
    //backgroundColor:"white",
  },
  grafico:{
    //backgroundColor:"#3f51b5",
    //color:"white",
    margin:0,
    borderStyle: "groove",
    borderColor: "#3f51b5",
    //borderColor: "#fb8c00",
    //borderColor: "#00acc1",
    width:"100%"
  },
  botondanger: {
    backgroundColor: "red",
    color:"white"
  },
  botongreen: {
    backgroundColor: "green",
    color:"white"
  },
  botonwhite: {
    backgroundColor: "white",
    color:"gray"
  },
  iconsmall: {
    fontSize:12,
    margin:2,
    marginTop:11, //15 center
    padding:0,
  },
  iconsmall2: {
    fontSize:8,
    margin:2,
    //marginTop:15, //15 center
    //padding:0,
    cursor: "default"
  },
  nombrecatalogo:{
    fontSize:13,
    marginTop:7,
    marginLeft:10,
  },
  nombrearchivo:{
    marginTop:6,
    fontSize:10,
  },
  iconarchivo:{
    marginTop:0,
    fontSize:10,
  },
  paper:{
    width:"95%",
  },
  colapse:{
    marginLeft:20
  },
  p: {
    width: "90%",
    margin: "0",
    lineHeight: "0.7"
  },

  formControl: {
    margin: theme.spacing(1),
    //minWidth: 120,
    width:"100%"
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#00acc1",
    '&:hover': {
      backgroundColor: "#00acc1",
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },


}));

const localization = {
  body: {
    emptyDataSourceMessage: "No hay datos por mostrar",
    addTooltip: "Añadir",
    deleteTooltip: "Eliminar",
    editTooltip: "Editar",
    filterRow: {
      filterTooltip: "Filtrar",
    },
  },
  header: {
    actions: "",
  },
  pagination: {
    firstAriaLabel: "Primera página",
    firstTooltip: "Primera página",
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsPerPage: "Filas por página:",
    labelRowsSelect: "filas",
    lastAriaLabel: "Ultima página",
    lastTooltip: "Ultima página",
    nextAriaLabel: "Pagina siguiente",
    nextTooltip: "Pagina siguiente",
    previousAriaLabel: "Pagina anterior",
    previousTooltip: "Pagina anterior",
  },
  toolbar: {
    exportAriaLabel: "Exportar",
    exportTitle: "Exportar",
    exportCSVName: "Exportar a CVS",
    exportPDFName: "Exportar a PDF",
    searchPlaceholder: "Buscar",
    searchTooltip: "Buscar",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Expedientes() {
  const classes2 = useStylestable();
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const [headerColor, setHeaderColor] = React.useState("warning");
  const [valueState, setValueState] = React.useState(1);
  const [expedientes, setExpedientes] = React.useState("");
  const [expediente, setExpediente] = React.useState("");
  const [enProceso, setEnProceso] = React.useState("");
  const [completados, setCompletados] = React.useState("");
  const [cerrados, setCerrados] = React.useState("");
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [expedienteEliminar, setExpedienteEliminar] = React.useState("");
  const [modalExpedienteGraph, setModalExpedienteGraph] = React.useState(false);
  const [modalExpedienteNodos, setModalExpedienteNodos] = React.useState(false);
  const [modalExpedienteArchivos, setModalExpedienteArchivos] = React.useState(false);
  const [modalReportar, setModalReportar] = React.useState(false);
  const [catalogos, setCatalogos] = React.useState("");
  const [modalReAbrir, setModalReAbrir] = React.useState(false);
  const [modalCerrar, setModalCerrar] = React.useState(false);
  const [openZip, setOpenZip] = React.useState(false);
  const [openCerrando, setOpenCerrando] = React.useState(false);
  const [documentos, setDocumentos] = React.useState(false);
  const [misPendientes, setMisPendientes] = React.useState(false);

  const [openMensaje, setOpenMensaje] = React.useState(false);
  const [mensaje, setMensaje] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const handleCloseMensaje = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   setOpenMensaje(false);
  };


  function alerta(texto,severidad){
  setOpenMensaje(true);
  setMensaje(texto);
  setSeverity(severidad);
  }

 const handleChangeTabs = (event, value) => {
   setValueState(value);
   switch (value) {
     case 0:
      setHeaderColor("info");
      break;
    case 1:
      setHeaderColor("warning");
      break;
    case 2:
       setHeaderColor("success");
       break;
    case 3:
       setHeaderColor("info");
       break;
    case 4:
       setHeaderColor("info");
       break;
   }
   /*
   if (value == 1) {
     setHeaderColor("success");
   } else if (value == 0) {
     setHeaderColor("warning");
   } else if (value == 2 || value == 3 ) {
     setHeaderColor("info");
   }
   */
 };

function getExpedientes(){
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
  method: 'get',
  url: `${process.env.REACT_APP_URL}api/expedientes`,
  headers: {
    'Accept': 'application/json',
    Authorization: `Bearer ${token}`,
  }
};
    axios(config)
      .then(function (response) {
        console.log("Expedientes:",response.data);
        let exp=response.data;

        if(exp){
          setExpedientes(exp);
          setEnProceso(exp.filter(item => item.status == 0));
          setCompletados(exp.filter(item => item.status == 1));
          setCerrados(exp.filter(item => item.status == 2));
        }
        else{
          setExpedientes("");
          setEnProceso("");
          setCerrados("");
          setCompletados("");
        }
      })
      .catch(function (error) {
        console.log(error);
        setExpedientes("");
      });

}

function handleEliminar(){
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
  method: 'delete',
  url: `${process.env.REACT_APP_URL}api/expedientes/${expedienteEliminar._id}`,
  headers: {
    'Accept': 'application/json',
    Authorization: `Bearer ${token}`,
  }
};
    axios(config)
      .then(function (response) {
        console.log("Expediente Eliminado:",response.data);
        getExpedientes();
        setModalEliminar(false);
        alerta("El expediente ha sido eliminado","warning")
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al eliminar expediente","error")
      });
}


async function getCatalogos(){
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
  method: 'get',
  url: `${process.env.REACT_APP_URL}api/catalogos`,
  headers: {
    'Accept': 'application/json',
    Authorization: `Bearer ${token}`,
  }
};
    axios(config)
      .then(function (response) {
        console.log("Catalogos:",response.data);
        setCatalogos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setCatalogos("");
      });
}

function nombreCatalogo(idcatalogo){
  const nombre = catalogos.find(item => item._id == idcatalogo);
  if(nombre){
      return(nombre.name);
  }
  return(null);
}

function archivosCatalogo(idcatalogo){
  if(catalogos){
      const nombre = catalogos.find(item => item._id == idcatalogo);
      let children=[];
      if(nombre){
        for (var i = 0; i < nombre.documentos.length; i++) {
          children = [...children,{
            name:nombre.documentos[i].name,
            checked:0,
            isOpen:false
          }]
        }
        return(children);
      }
  }
  return(null);
}

function ExpedienteArchivos(){

  let nodos=[];
  if(expediente.nodes){
    for (var i = 0; i < expediente.nodes.length; i++) {
      if(expediente.nodes[i].catalogos){
        for (var j = 0; j < expediente.nodes[i].catalogos.length; j++) {
          nodos=[...nodos,expediente.nodes[i].catalogos[j]]
        }
      }
    }
  }

  let children=[];
  for (var i = 0; i < nodos.length; i++) {
    children=[...children,{
      checked:0,
      _id:i,
      name:nombreCatalogo(nodos[i]),
      children: archivosCatalogo(nodos[i]),
    }]
  }
  let tree = {
    name:expediente.name,
    isOpen:false,
    checked:0,
    _id:0,
    children:children
  }
  return(
    <FolderTree
      data={ tree }
      showCheckbox={ false }
      readOnly
    />
  );
}


function handleReAbrir(){
  console.log("Abrir");
  let url = `${process.env.REACT_APP_URL}api/expedientes/open/${expediente._id}`;
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  axios(config)
    //.post(url, config, {})
    .then((response) => {
      console.log(response.data)
      getExpedientes();
      setModalReAbrir(false)
    })
    .catch((error) => {
      // Error 😨
      console.log(error)
    });
}

function handleCerrar(){
  console.log("Cerrar");
  let url = `${process.env.REACT_APP_URL}api/expedientes/close/${expediente._id}`;
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  axios(config)
    //.post(url, config, {})
    .then((response) => {
      console.log("RESPUESTA CIERRE:",response.data)
      getExpedientes();
      setModalCerrar(false)
      setOpenCerrando(false)
    })
    .catch((error) => {
      // Error 😨
      console.log(error)
      alerta("No se puede cerrar el expediente. Revise si existe un documento rechazado","warning")
      setModalCerrar(false)
      setOpenCerrando(false)
    });
}

const getZipDocument = () => {
  setOpenZip(true);
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  axios({
    url: `${process.env.REACT_APP_URL}api/expedientes/download/${expediente._id}`,
    method: "GET",
    responseType: "blob", // important
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${expediente.name}-${expediente.custom_id}.zip`);
    document.body.appendChild(link);
    link.click();
    setOpenZip(false);
  })
  .catch(function (error) {
    alerta("No hay documentos en el expediente","error");
    console.log(error);
    setOpenZip(false);
  });
};

const getZipBundle = (exp) => {
  setOpenZip(true);
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  axios({
    url: `${process.env.REACT_APP_URL}api/expedientes/download/${exp._id}/bundle`,
    method: "GET",
    responseType: "blob", // important
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${exp.name}-${exp.custom_id}-CERTIFICADO.zip`);
    //link.setAttribute("download", `${exp.name}-${exp.custom_id}.pdf`);
    document.body.appendChild(link);
    link.click();
    setOpenZip(false);
  });
};

async function getDocumentos(expediente){
  let token = localStorage.getItem("Token");
  //let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
  method: 'get',
  url: `${process.env.REACT_APP_URL}api/expedientes/documentos/${expediente._id}`,
  headers: {
    'Accept': 'application/json',
    Authorization: `Bearer ${token}`,
  }
};
    axios(config)
      .then(function (response) {
        console.log("Documentos:",response.data)
        setDocumentos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setDocumentos("");
      });
}

/*
function DisplayExpedientes(props){
  return (
    <>
    <Grid container justify = "center">
      <AppBar position="static" className={classes.titulo}>
      <b>{props.expediente.name}</b>
      </AppBar>

    </Grid>
    </>
  )
}
*/

function showstatus(item){

  item.nodes.sort(function (a, b) {
      if (a.step_id > b.step_id) {
        return 1;
      }
      if (a.step_id < b.step_id) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

  return(
    item.nodes.map((nodo, key) => (
      <li key={key} style={{ listStyleType: "none" }}><font size="1" color={nodo.status == "0" ?"red":"green"}>- {nodo.name}</font></li>
    )
  ))
}

 React.useEffect(() => {
   getExpedientes();
   getCatalogos();
 }, []);

    return (
      <React.Fragment>
      <CustomTabs
        handleChange={handleChangeTabs}
        valueState={valueState}
        headerColor={headerColor}
        title=""
        tabs={[
          {
            tabName: "TODOS (" + expedientes.length + ")",
            tabIcon: FolderOpenIcon,
            tabContent: (
              <React.Fragment>
                <Grid container style={{ marginTop: 10 }}>
                  <div style={{ width: "100%" }}>
                  {expedientes ? (
                    <MaterialTable
                      localization={localization}
                      options={{
                        //exportButton: { csv: false, pdf: false },
                        exportAllData: true,
                        draggable: false,
                        pageSize: 20, // make initial page size
                        pageSizeOptions: [20, 50, 100, 500, 1000],
                        actionsColumnIndex: -1,
                      }}
                      columns={[
                        {
                          title: "Código",
                          field: "custom_id",
                        },
                        {
                          title: "Flujo",
                          field: "name",
                        },
                        {
                          title: "Fecha",
                          field: "created_at",
                          render: (row) => (
                            <span>
                              {row["created_at"]
                                ? moment(
                                    row["created_at"]
                                  ).format("DD/MM/YYYY")
                                : null}
                            </span>
                          ),
                        },
                        {
                          title: "Pasos",
                          field: "nodes",
                          render: (row) => (
                            <span>
                              {showstatus(row)}
                            </span>
                          ),
                        },
                        {
                          title: "Estatus",
                          field: "status",
                          render: (row) => (
                            <span>
                              {row["status"]==0?"En proceso":null}
                              {row["status"]==1?"Completado":null}
                              {row["status"]==2?"Cerrado":null}
                            </span>
                          ),
                        },
                        {
                          title: "ID",
                          field: "_id",
                        },
                      ]}
                      data={expedientes}
                      title={"Todos (" + expedientes.length + ")"}
                      /*
                      actions={[
                        {
                          icon: () => (
                            <AccountTreeIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Directorio de archivos",
                          onClick: (event, rowData) => {
                            console.log(rowData);
                            setExpediente(rowData);
                            setModalExpedienteArchivos(true);
                          },
                        },
                      ]}
                      */
                    />
                  ):null}
                  </div>
                </Grid>
              </React.Fragment>
            ),
          },
          {
            tabName: "EN PROCESO (" + enProceso.length + ")",
            tabIcon: FolderOpenIcon,
            tabContent: (
              <React.Fragment>
                <Grid container style={{ marginTop: 10 }}>
                {misPendientes ?(
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                      setMisPendientes(false)
                    }}
                  >
                    Todos
                  </Button>
                ):(
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                      setMisPendientes(true)
                    }}
                  >
                    Mis Pendientes
                  </Button>
                )}
                {misPendientes ? (
                  <MisPendientes
                    expedientes={enProceso}
                    setExpediente={setExpediente}
                    setModalReportar={setModalReportar}
                  />
                ):(
                  <div style={{ width: "100%" }}>
                  {enProceso ? (
                    <MaterialTable
                      localization={localization}
                      options={{
                        //exportButton: { csv: false, pdf: false },
                        exportAllData: true,
                        draggable: false,
                        pageSize: 20, // make initial page size
                        pageSizeOptions: [20, 50, 100, 500, 1000],
                        actionsColumnIndex: -1,
                      }}
                      columns={[
                        {
                          title: "Código",
                          field: "custom_id",
                        },
                        {
                          title: "Flujo",
                          field: "name",
                        },
                        {
                          title: "Fecha",
                          field: "created_at",
                          render: (row) => (
                            <span>
                              {row["created_at"]
                                ? moment(
                                    row["created_at"]
                                  ).format("DD/MM/YYYY")
                                : null}
                            </span>
                          ),
                        },
                        {
                          title: "Pasos",
                          field: "nodes",
                          render: (row) => (
                            <span>
                              {showstatus(row)}
                            </span>
                          ),
                        },
                        {
                          title: "ID",
                          field: "_id",
                        },
                      ]}
                      data={enProceso}
                      title={"En Proceso (" + enProceso.length + ")"}
                      actions={[
                        {
                          icon: () => (
                            <PostAddIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Completar expediente",
                          onClick: (event, rowData) => {
                            console.log(rowData)
                            setExpediente(rowData);
                            setModalReportar(true);
                          },
                        },
                        {
                          icon: () => (
                            <DeleteIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Eliminar",
                          onClick: (event, rowData) => {
                            console.log(rowData)
                            setModalEliminar(true);
                            setExpedienteEliminar(rowData)
                          },
                        },
                      ]}
                    />
                  ):null}
                  </div>
                )}
                </Grid>
              </React.Fragment>
            ),
          },
          {
            tabName: "COMPLETADOS (" + completados.length + ")",
            tabIcon: AssignmentTurnedInIcon,
            tabContent: (
              <React.Fragment>
                <Grid container style={{ marginTop: 10 }}>
                  <div style={{ width: "100%" }}>
                  {completados ? (
                    <MaterialTable
                      localization={localization}
                      options={{
                        //exportButton: { false: true, pdf: false },
                        exportAllData: true,
                        draggable: false,
                        pageSize: 20, // make initial page size
                        pageSizeOptions: [20, 50, 100, 500, 1000],
                        actionsColumnIndex: -1,
                      }}
                      columns={[
                        {
                          title: "Código",
                          field: "custom_id",
                        },
                        {
                          title: "Flujo",
                          field: "name",
                        },
                        {
                          title: "Fecha",
                          field: "created_at",
                          render: (row) => (
                            <span>
                              {row["created_at"]
                                ? moment(
                                    row["created_at"]
                                  ).format("DD/MM/YYYY")
                                : null}
                            </span>
                          ),
                        },
                        {
                          title: "Pasos",
                          field: "nodes",
                          render: (row) => (
                            <span>
                              {showstatus(row)}
                            </span>
                          ),
                        },
                        {
                          title: "ID",
                          field: "_id",
                        },
                      ]}
                      data={completados}
                      title={"Completados (" + completados.length + ")"}
                      actions={[
                        {
                          icon: () => (
                            <AccountTreeIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Directorio de archivos",
                          onClick: (event, rowData) => {
                            console.log(rowData);
                            setExpediente(rowData);
                            setModalExpedienteArchivos(true);
                          },
                        },
                        {
                          icon: () => (
                            <RestorePageIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Colocar en proceso",
                          onClick: (event, rowData) => {
                            console.log(rowData)
                            setExpediente(rowData);
                            setModalReAbrir(true);
                          },
                        },
                        {
                          icon: () => (
                            <LockOpenIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Cerrar expediente",
                          onClick: (event, rowData) => {
                            console.log(rowData)
                            setExpediente(rowData);
                            setModalCerrar(true);
                          },
                        },
                      ]}
                    />
                  ):null}
                  </div>
                </Grid>
              </React.Fragment>
            ),
          },
          {
            tabName: "CERRADOS (" + cerrados.length + ")",
            tabIcon: ArchiveIcon,
            tabContent: (
              <React.Fragment>
                <Grid container style={{ marginTop: 10 }}>
                  <div style={{ width: "100%" }}>
                  {cerrados ? (
                    <MaterialTable
                      localization={localization}
                      options={{
                        //exportButton: { false: true, pdf: false },
                        exportAllData: true,
                        draggable: false,
                        pageSize: 20, // make initial page size
                        pageSizeOptions: [20, 50, 100, 500, 1000],
                        actionsColumnIndex: -1,
                      }}
                      columns={[
                        {
                          title: "Código",
                          field: "custom_id",
                        },
                        {
                          title: "Flujo",
                          field: "name",
                        },
                        {
                          title: "Fecha",
                          field: "created_at",
                          render: (row) => (
                            <span>
                              {row["created_at"]
                                ? moment(
                                    row["created_at"]
                                  ).format("DD/MM/YYYY")
                                : null}
                            </span>
                          ),
                        },
                        {
                          title: "Pasos",
                          field: "nodes",
                          render: (row) => (
                            <span>
                              {showstatus(row)}
                            </span>
                          ),
                        },
                        {
                          title: "ID",
                          field: "_id",
                        },
                      ]}
                      data={cerrados}
                      title={"Cerrados (" + cerrados.length + ")"}
                      actions={[
                        {
                          icon: () => (
                            <AccountTreeIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Directorio de archivos",
                          onClick: (event, rowData) => {
                            console.log(rowData);
                            setExpediente(rowData);
                            setModalExpedienteArchivos(true);
                          },
                        },
                        {
                          icon: () => (
                            <ArchiveIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Descargar expediente certificado",
                          onClick: (event, rowData) => {
                            console.log(rowData);
                            //setExpediente(rowData);
                            getZipBundle(rowData);
                            //setModalExpedienteArchivos(true);
                          },
                        },
                      ]}
                    />
                  ):null}
                  </div>
                </Grid>
              </React.Fragment>
            ),
          },
          /*
          {
            tabName: "",
            tabIcon: PostAddIcon,
            tabContent: (
              <React.Fragment>
                <MisPendientes
                  expedientes={enProceso}
                  setExpediente={setExpediente}
                  setModalReportar={setModalReportar}
                />
              </React.Fragment>
            ),
          },
          */
          {
            tabName: "",
            tabIcon: FindInPageIcon,
            tabContent: (
              <React.Fragment>
                <ExpedientesTable expedientes={expedientes} />
              </React.Fragment>
            ),
          },
        ]}
      />

      {/*DIALOGO ELIMINAR FLUJO*/}
      <Dialog
        open={modalEliminar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        </DialogTitle>
        <DialogContent>
            <Typography variant="h6" style={{ textTransform: "none" }}>
              ¿Está seguro de eliminar el expediente?<br /> <b>{expedienteEliminar.custom_id}</b>
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={()=>{
              setModalEliminar(false)
            }}
            className={classes.botondanger}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleEliminar();
            }}
            className={classes.botondanger}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN ELIMINAR EXPEDIENTE*/}

      {/*DIALOGO EXPEDIENTE GRAFICO*/}
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={modalExpedienteGraph}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Expediente Nro. {expediente.custom_id} ({expediente.name})
        </DialogTitle>
        <DialogContent>
        <React.Fragment>
        <Grid container justify = "center">
            <ExpedienteGraph expediente={expediente} />
        </Grid>
       </React.Fragment>
        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{
            setModalExpedienteGraph(false)
          }}
          //className={classes.botondanger}
        >
          Cerrar
        </Button>
        </DialogActions>
      </Dialog>
      {/* FIN EXPEDIENTE GRAFICO*/}

      {/*DIALOGO EXPEDIENTE NODOS*/}
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={modalExpedienteNodos}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Expediente Nro. {expediente.custom_id} ({expediente.name})
        </DialogTitle>
        <DialogContent>
        <React.Fragment>
        <Grid container justify = "center">
          <ExpedienteNodos expediente={expediente} />
        </Grid>
       </React.Fragment>
        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{
            setModalExpedienteNodos(false)
          }}
          //className={classes.botondanger}
        >
          Cerrar
        </Button>
        </DialogActions>
      </Dialog>
      {/* FIN EXPEDIENTE NODOS*/}

      {/*DIALOGO EXPEDIENTE ARCHIVOS*/}
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={modalExpedienteArchivos}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Expediente Nro. {expediente.custom_id} ({expediente.name})
        </DialogTitle>
        <DialogContent>
        <React.Fragment>
        <Grid container>
          <ExpedienteCarpetas expediente={expediente} />
        </Grid>
       </React.Fragment>
        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          //color="primary"
          onClick={()=>{
            setModalExpedienteArchivos(false)
          }}
          className={classes.botondanger}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          //color="primary"
          onClick={()=>{
            console.log("Descargar");
            getZipDocument();
          }}
          className={classes.botongreen}
        >
          Descargar
        </Button>
        {expediente.status==1?(
          <Button
            variant="contained"
            color="primary"
            onClick={()=>{
              setModalExpedienteArchivos(false)
              setModalReAbrir(true);
            }}
            //className={classes.botondanger}
          >
            Colocar en proceso
          </Button>
        ):null}
        </DialogActions>
      </Dialog>
      {/* FIN EXPEDIENTE ARCHIVOS*/}

      {/*DIALOGO REPORTAR*/}
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={modalReportar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Expediente Nro. {expediente.custom_id} ({expediente.name})
        </DialogTitle>
        <DialogContent>
        <React.Fragment>
        <Grid container>
          <Reportar expediente={expediente} getExpedientes={getExpedientes} setModalReportar={setModalReportar}/>
        </Grid>
       </React.Fragment>
        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{
            setModalReportar(false)
          }}
          //className={classes.botondanger}
        >
          Cerrar
        </Button>
        <Button
          variant="contained"
          onClick={()=>{
            console.log("Descargar");
            getZipDocument();
          }}
          className={classes.botongreen}
        >
          Descargar
        </Button>
        </DialogActions>
      </Dialog>
      {/* FIN REPORTAR*/}

      {/*DIALOGO RE ABRIR*/}
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={modalReAbrir}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Expediente Nro. {expediente.custom_id} ({expediente.name})
        </DialogTitle>
        <DialogContent>
        <React.Fragment>
        <Grid container>
          ¿Está seguro de reabrir expediente?
        </Grid>
       </React.Fragment>
        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          //color="primary"
          onClick={()=>{
            setModalReAbrir(false)
          }}
          className={classes.botondanger}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          //color="primary"
          onClick={()=>{
            handleReAbrir()
          }}
          className={classes.botongreen}
        >
          Aceptar
        </Button>
        </DialogActions>
      </Dialog>
      {/* FIN RE ABRIR*/}

      {/*DIALOGO CERRAR EXPEDIENTE*/}
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={modalCerrar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Expediente Nro. {expediente.custom_id} ({expediente.name})
        </DialogTitle>
        <DialogContent>
        <React.Fragment>
        <Grid container>
          <font color="red">¿Está seguro de cerrar el expediente?</font><br /> Luego del cierre, el expediente quedará archivado y no podrá ser modificado.
        </Grid>
       </React.Fragment>
        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          //color="primary"
          onClick={()=>{
            setModalCerrar(false)
          }}
          className={classes.botondanger}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          //color="primary"
          onClick={()=>{
            setOpenCerrando(true);
            handleCerrar()
          }}
          className={classes.botongreen}
        >
          Aceptar
        </Button>
        </DialogActions>
      </Dialog>
      {/*FIN CERRAR EXPEDIENTE*/}

      {/*DIALOGO DE ESPERA PARA DESCARGA*/}
        <Dialog
          open={openZip}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          //aria-labelledby="alert-dialog-slide-title"
          //aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Descarga de expediente"}
          </DialogTitle>
          <DialogContent>
            <>
              <Typography>
                El archivo con los documentos del expediente se descargará al lugar
                habitual de Descargas: Esto puede tardar unos segundos...
              </Typography>
              <br />
              <CircularProgress />
              {/*
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
              */}
            </>
          </DialogContent>
        </Dialog>
      {/*FIN DIALOGO DE ESPERA PARA DESCARGA*/}

      {/*DIALOGO CERRANDO*/}
        <Dialog
          open={openCerrando}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Cierre de expediente"}
          </DialogTitle>
          <DialogContent>
            <>
              <Typography>
                Cerrando expediente, por favor espere unos segundos.
              </Typography>
              <CircularProgress />
              {/*
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
              */}
            </>
          </DialogContent>
        </Dialog>
      {/*FIN DIALOGO CERRANDO*/}

      {/*MENSAJE*/}
      <Snackbar
          open={openMensaje}
          autoHideDuration={3000}
          anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
          onClose={handleCloseMensaje}>
        <Alert onClose={handleCloseMensaje} severity={severity}>
          {mensaje}
        </Alert>
      </Snackbar>
      {/*FIN MENSAJE*/}
      </React.Fragment>
    );
}
