import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import InfoIcon from "@material-ui/icons/Info";
import Close from "@material-ui/icons/Close";
//modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from "moment";
import axios from "axios";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
const useStyles = makeStyles(styles);
const useStylestable = makeStyles(styles2);

export default function Documentos() {
  const colorStatistics="#024ebc";
  const classes = useStyles();
  const classes2 = useStylestable();
  const [loading, setLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [detalleDocumento, setDetalleDocumento] = React.useState({});
  const [dataUsers, setDataUsers] = React.useState("");

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const [documentos, setDocumentos] = React.useState({
    totalgeneral: 0,
    totales: 0,
    last30: 0,
    last7: 0,
    today: 0,
  });

  const [tablasDocumentos, setTablasDocumentos] = React.useState({
    resultTotal: [{}],
    resultYear: [{}],
    resultMonth: [{}],
    resultWeek: [{}],
    resultToday: [{}],
  });

  const [sortedField, setSortedField] = React.useState(null);
  const [tablaActual, setTablaActual] = React.useState([]);
  const [titulo, setTitulo] = React.useState("");
  const [desde, setDesde] = React.useState("");
  const [hasta, setHasta] = React.useState("");


function getusuarios(){
  let token = localStorage.getItem("Token");
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_URL}api/users`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  axios(config)
    .then(function (response) {
      let data = response.data;
      console.log("USERS", data);
    })
    .catch(function (error) {
      console.log(error);
    });
}

function setFechas(){
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let separator = "-";

  let fecha = `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
  setDesde(fecha);
  setHasta(fecha);
}

  React.useEffect(() => {
    /*
    if(dataUsers.length===0){
        getusuarios();
    }
    */

    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "-";

    let fecha = `${year}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${date}`;
    setDesde(fecha);
    setHasta(fecha);

    //DOCUMENTOS
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        let arr = response.data;
        //console.log(JSON.stringify(arr));
        console.log("DOCUMENTOS", arr);
        //var startDate = year+"-01-01";
        //var endDate = fecha;
        //var year = 2020;
        var startDate = new Date(year, 0, 1);
        var endDate = new Date();
        var thirtyDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 30);
        var sevenDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 7);
        var oneDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 1);

        var resultYear = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= startDate &&
            new Date(a.created_at) <= endDate
          );
        });

        var resultMonth = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= thirtyDaysAgo &&
            new Date(a.created_at) <= endDate
          );
        });

        var resultWeek = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= sevenDaysAgo &&
            new Date(a.created_at) <= endDate
          );
        });

        var resultToday = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= oneDaysAgo &&
            new Date(a.created_at) <= endDate
          );
        });

        setDocumentos({
          ...documentos,
          totalgeneral: arr.length,
          totales: resultYear.length,
          last30: resultMonth.length,
          last7: resultWeek.length,
          today: resultToday.length,
        });

        setTablasDocumentos({
          resultTotal: arr,
          resultYear: resultYear,
          resultMonth: resultMonth,
          resultWeek: resultWeek,
          resultToday: resultToday,
        });

        const data = resultYear;
        /*
        data.map(function (item) {
          item.created_at = moment(item.created_at).format("DD-MM-YYYY");
          item.firmantes_names = item.firmantes_names.replace(/,/g, " , ");
          return item;
        });
        */

        //crear los campos personlizados en la tabla
        let datos=[];
        for (var i = 0; i < data.length; i++) {
          datos[i]={
            //company:data[i].users.company,
            company:"",
            filename:data[i].filename,
            type:data[i].type.name,
            asistido:(data[i].asistido)?data[i].asistido:null,
            created_at:(data[i].created_at)? moment(data[i].created_at).format("DD/MM/YYYY HH:mm:ss"):null,
            status:data[i].status.name,
            //user:data[i].users.name,
            user:"",
            firmantes:getFirmantes(data[i]),
            id:data[i]._id,
            hash:data[i].hash,
            hash_signed:data[i].hash_signed,
            hash_constancia:(data[i].constancia)?data[i].constancia.hash:null,
            sello:(data[i].constancia)?data[i].constancia.datetime:null,
          }

        }
        //datos personalizados
        setTablaActual(datos);
        setTitulo("AÑO EN CURSO ("+datos.length+")");
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });

    //FIN DOCUMENTOS
  }, []);

  const handleTotal = () => {
    setFechas();
    //setTablaActual(tablasDocumentos.resultTotal);
    //crear los campos personlizados en la tabla
    let data = tablasDocumentos.resultTotal;
    let datos=[];
    for (var i = 0; i < data.length; i++) {
      datos[i]={
        //company:data[i].users.company,
        company:"",
        filename:data[i].filename,
        type:data[i].type.name,
        asistido:(data[i].asistido)?data[i].asistido:null,
        created_at:(data[i].created_at)? moment(data[i].created_at).format("DD/MM/YYYY HH:mm:ss"):null,
        status:data[i].status.name,
        //user:data[i].users.name,
        user:"",
        firmantes:getFirmantes(data[i]),
        id:data[i]._id,
        hash:data[i].hash,
        hash_signed:data[i].hash_signed,
        hash_constancia:(data[i].constancia)?data[i].constancia.hash:null,
        sello:(data[i].constancia)?data[i].constancia.datetime:null,
      }

    }
    //datos personalizados
    setTablaActual(datos);
    setTitulo("TOTAL ("+datos.length+")");
  };

  const handleYear = () => {
    setFechas();
    //setTablaActual(tablasDocumentos.resultYear);
    let data = tablasDocumentos.resultYear;
    let datos=[];
    for (var i = 0; i < data.length; i++) {
      datos[i]={
        //company:data[i].users.company,
        company:"",
        filename:data[i].filename,
        type:data[i].type.name,
        asistido:(data[i].asistido)?data[i].asistido:null,
        created_at:(data[i].created_at)? moment(data[i].created_at).format("DD/MM/YYYY HH:mm:ss"):null,
        status:data[i].status.name,
        //user:data[i].users.name,
        user:"",
        firmantes:getFirmantes(data[i]),
        id:data[i]._id,
        hash:data[i].hash,
        hash_signed:data[i].hash_signed,
        hash_constancia:(data[i].constancia)?data[i].constancia.hash:null,
        sello:(data[i].constancia)?data[i].constancia.datetime:null,
      }

    }
    //datos personalizados
    setTablaActual(datos);
    setTitulo("AÑO EN CURSO ("+datos.length+")");
  };

  const handleMonth = () => {
    setFechas();
    //setTablaActual(tablasDocumentos.resultMonth);
    let data = tablasDocumentos.resultMonth;
    let datos=[];
    for (var i = 0; i < data.length; i++) {
      datos[i]={
        //company:data[i].users.company,
        company:"",
        filename:data[i].filename,
        type:data[i].type.name,
        asistido:(data[i].asistido)?data[i].asistido:null,
        created_at:(data[i].created_at)? moment(data[i].created_at).format("DD/MM/YYYY HH:mm:ss"):null,
        status:data[i].status.name,
        //user:data[i].users.name,
        user:"",
        firmantes:getFirmantes(data[i]),
        id:data[i]._id,
        hash:data[i].hash,
        hash_signed:data[i].hash_signed,
        hash_constancia:(data[i].constancia)?data[i].constancia.hash:null,
        sello:(data[i].constancia)?data[i].constancia.datetime:null,
      }

    }
    //datos personalizados
    setTablaActual(datos);
    setTitulo("ÚLTIMOS 30 DÍAS ("+datos.length+")");
  };

  const handleWeek = () => {
    setFechas();
    //setTablaActual(tablasDocumentos.resultWeek);
    let data = tablasDocumentos.resultWeek;
    let datos=[];
    for (var i = 0; i < data.length; i++) {
      datos[i]={
        //company:data[i].users.company,
        company:"",
        filename:data[i].filename,
        type:data[i].type.name,
        asistido:(data[i].asistido)?data[i].asistido:null,
        created_at:(data[i].created_at)? moment(data[i].created_at).format("DD/MM/YYYY HH:mm:ss"):null,
        status:data[i].status.name,
        //user:data[i].users.name,
        user:"",
        firmantes:getFirmantes(data[i]),
        id:data[i]._id,
        hash:data[i].hash,
        hash_signed:data[i].hash_signed,
        hash_constancia:(data[i].constancia)?data[i].constancia.hash:null,
        sello:(data[i].constancia)?data[i].constancia.datetime:null,
      }

    }
    //datos personalizados
    setTablaActual(datos);
    setTitulo("ÚLTIMOS 7 DÍAS ("+datos.length+")");
  };

  const handleDay = () => {
    setFechas();
    //setTablaActual(tablasDocumentos.resultToday);
    let data = tablasDocumentos.resultToday;
    let datos=[];
    for (var i = 0; i < data.length; i++) {
      datos[i]={
        //company:data[i].users.company,
        company:"",
        filename:data[i].filename,
        type:data[i].type.name,
        asistido:(data[i].asistido)?data[i].asistido:null,
        created_at:(data[i].created_at)? moment(data[i].created_at).format("DD/MM/YYYY HH:mm:ss"):null,
        status:data[i].status.name,
        //user:data[i].users.name,
        user:"",
        firmantes:getFirmantes(data[i]),
        id:data[i]._id,
        hash:data[i].hash,
        hash_signed:data[i].hash_signed,
        hash_constancia:(data[i].constancia)?data[i].constancia.hash:null,
        sello:(data[i].constancia)?data[i].constancia.datetime:null,
      }

    }
    //datos personalizados
    setTablaActual(datos);
    setTitulo("ÚLTIMO DÍA");
  };

  const onChangeDesde = (e) => {
    setDesde(e.target.value);
    console.log("desde", e.target.value); // Works
  };

  const onChangeHasta = (e) => {
    setHasta(e.target.value);
    console.log("hasta", e.target.value); // Works
  };

/*
  const handleFecha = () => {
    setLoading(true);
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    //DOCUMENTOS
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        let arr = response.data;

        var startDate = new Date(desde+" 00:00:00");
        var endDate = new Date(hasta+" 23:59:59");

        var result = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= startDate &&
            new Date(a.created_at) <= endDate
          );
        });

        const data = result;

          let datos=[];
          for (var i = 0; i < data.length; i++) {
            datos[i]={
              //company:data[i].users.company,
              company:"",
              filename:data[i].filename,
              type:data[i].type.name,
              asistido:(data[i].asistido)?data[i].asistido:null,
              created_at:(data[i].created_at)? moment(data[i].created_at).format("DD/MM/YYYY HH:mm:ss"):null,
              status:data[i].status.name,
              //user:data[i].users.name,
              user:"",
              firmantes:getFirmantes(data[i]),
              id:data[i]._id,
              hash:data[i].hash,
              hash_signed:data[i].hash_signed,
              hash_constancia:(data[i].constancia)?data[i].constancia.hash:null,
              sello:(data[i].constancia)?data[i].constancia.datetime:null,
            }

          }
          setTablaActual(datos);
        setTitulo("INTERVALO PERSONALIZADO ( "+data.length+" )");
      })
      .catch(function (error) {
        console.log(error);
      });

    //FIN DOCUMENTOS
    setLoading(false);
  };
*/
  const handleFecha = () => {
    setLoading(true);
    //DOCUMENTOS
    let arr = tablasDocumentos.resultTotal;

    var startDate = new Date(desde+" 00:00:00");
    var endDate = new Date(hasta+" 23:59:59");

    var result = arr.filter(function (a) {
      return (
        new Date(a.created_at) >= startDate &&
        new Date(a.created_at) <= endDate
      );
    });

    const data = result;

      let datos=[];
      for (var i = 0; i < data.length; i++) {
        datos[i]={
          //company:data[i].users.company,
          company:"",
          filename:data[i].filename,
          type:data[i].type.name,
          asistido:(data[i].asistido)?data[i].asistido:null,
          created_at:(data[i].created_at)? moment(data[i].created_at).format("DD/MM/YYYY HH:mm:ss"):null,
          status:data[i].status.name,
          //user:data[i].users.name,
          user:"",
          firmantes:getFirmantes(data[i]),
          id:data[i]._id,
          hash:data[i].hash,
          hash_signed:data[i].hash_signed,
          hash_constancia:(data[i].constancia)?data[i].constancia.hash:null,
          sello:(data[i].constancia)?data[i].constancia.datetime:null,
        }

      }
      setTablaActual(datos);
      setTitulo("INTERVALO PERSONALIZADO ( "+data.length+" )");
    //FIN DOCUMENTOS
    setLoading(false);
  };

  const tiempo = (date1,date2) => {
    const dateOneObj = new Date(date1);
    const dateTwoObj = new Date(date2);
    const milliseconds = Math.abs(dateTwoObj - dateOneObj);
    const hour = milliseconds / 36e5;
    const num = Math.floor(milliseconds/1000/60);
    //se transforma en horas y minutos
    //var num = minute;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    if (rhours > 0){
      if (rhours >1){
        return(rhours + " Hrs - " + rminutes + " min");
      }
      else{
        return(rhours + " Hr - " + rminutes + " min");
      }
    }
    else{
      if(rhours===0){
        return(rminutes + " min");
      }
    }

    return(null);
  }

  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  function getFirmantes(data){
    //console.log(data);
    let datos="";
    for (var i = 0; i < data.firmantes.length; i++) {
      datos=datos+data.firmantes[i].name+" "+data.firmantes[i].last_name+" "+data.firmantes[i].email+",\n"
    }
    return(datos);
  }

function handleDetalle(rowData){
  console.log(rowData);
  let token = localStorage.getItem("Token");
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_URL}api/documentos/${rowData.id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  axios(config)
    .then(function (response) {
      //console.log("DOCU:", response.data);
      setDetalleDocumento(response.data);
      setModal(true);
    })
    .catch(function (error) {
      console.log(error);
    });

}

function getCompany(){
  return "HOLA"
}

  return (
    <React.Fragment>
      <Grid container style={{ marginTop: 0 }}>
        <Grid item xs={12}>
        {/*
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography color="primary" gutterBottom>
              <font size="5">
                <b>DOCUMENTOS</b>
              </font>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography color="dark" gutterBottom>
              <font size="1">
                <b>LISTADO DE DOCUMENTOS CARGADOS AL SISTEMA</b>
              </font>
            </Typography>
          </Box>
          */}
        </Grid>

        <Grid item xs={6} sm={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
              >
                <b>Total</b>
              </Typography>
              <hr />
              <h3 style={{ color: "#6cc24a", margin: 0}}>
                <b>{documentos.totalgeneral}</b>
              </h3>
            </CardContent>
            <Button variant="outlined" color="primary" onClick={handleTotal} style={{margin: 0}}>
              VER DETALLE
            </Button>
          </Card>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
              >
                <b>Año en Curso</b>
              </Typography>
              <hr />
              <h3 style={{ color: "#6cc24a", margin:0 }}>
                <b>{documentos.totales}</b>
              </h3>
            </CardContent>
            <Button variant="outlined" color="primary" onClick={handleYear} style={{margin: 0}}>
              VER DETALLE
            </Button>
          </Card>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
              >
                <b>Últimos 30 días</b>
              </Typography>
              <hr />
              <h3 style={{ color: "#6cc24a", margin:0 }}>
                <b>{documentos.last30}</b>
              </h3>
            </CardContent>
            <Button variant="outlined" color="primary" onClick={handleMonth} style={{margin: 0}}>
              VER DETALLE
            </Button>
          </Card>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
              >
                <b>Últimos 7 días</b>
              </Typography>
              <hr />
              <h3 style={{ color: "#6cc24a", margin:0 }}>
                <b>{documentos.last7}</b>
              </h3>
            </CardContent>
            <Button variant="outlined" color="primary" onClick={handleWeek} style={{margin: 0}}>
              VER DETALLE
            </Button>
          </Card>
        </Grid>
        {/*
      <Grid item xs={6} sm={3}>
        <Card variant="outlined">
          <CardContent>
            <Typography className={classes.title} color="textPrimary" gutterBottom>
              <b>Último día</b>
              </Typography>
              <hr />
            <h3 style={{color:"#6cc24a"}}><b>{documentos.today}</b></h3>
          </CardContent>
          <Button variant="outlined" color="primary" onClick={handleDay}>
            VER DETALLE
          </Button>
        </Card>
      </Grid>
*/}
        <Grid item xs={12} sm={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
              >
                <b>Intervalo personalizado</b>
              </Typography>
              <hr />
              <TextField
                id="dateDesde"
                label="Desde"
                type="date"
                onChange={onChangeDesde}
                value={desde}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="dateHasta"
                label="Hasta"
                type="date"
                onChange={onChangeHasta}
                value={hasta}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant="outlined" color="primary" onClick={handleFecha}>
                VER DETALLE
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 10 }}>
          <div style={{ maxWidth: "100%" }}>
          {
            loading ? (
              <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
              }}>
                <CircularProgress />
              </div>
            ):null
          }
            <MaterialTable
              localization={localization}
              options={{
                exportButton: { csv: true, pdf: false },
                exportAllData: true,
                draggable: false,
                headerStyle: {
                  backgroundColor: colorStatistics,
                  color: "#FFF",
                },

                pageSize: 20, // make initial page size
                pageSizeOptions: [20, 50, 100, 500, 1000],
                actionsColumnIndex: -1,
                actionsColumnIndex: 0,
              }}
              columns={[
                /*
                { title: "Compañía", field: "company",
                  render: (row) => (
                    <span>
                      {getCompany()}
                    </span>
                  ),
                },
                */
                { title: "Documento", field: "filename" },
                { title: "Tipo", field: "type" },
                { title: "Asistido", field: "asistido" },
                {
                  title: "Fecha",
                  field: "created_at",
                  render: (row) => (
                    <span>
                      {row["created_at"]}
                    </span>
                  ),
                },
                { title: "Estatus", field: "status" },
                /*
                { title: "Usuario", field: "user" },
                */
                {
                  title: "Firmantes",
                  field: "firmantes",
                  render: (row) => (
                    <span>
                      {row["firmantes"]}
                    </span>
                  ),
                },
                { title: "ID", field: "id" },
                { title: "HASH", field: "hash" },
                { title: "HASH Firmado", field: "hash_signed" },
                { title: "HASH Constancia", field: "hash_constancia" },
                { title: "Sello Tiempo", field: "sello" },
              ]}
              data={tablaActual}
              title={titulo}
              actions={[
                {
                  icon: () =>
                  <InfoIcon
                  className={
                    classes2.tableActionButtonIcon +
                    " " +
                    classes2.edit
                  }
                  />,
                  tooltip: "Ver más detalles",
                  onClick: (event, rowData) => {
                    handleDetalle(rowData);
                  },
                },
              ]}
            />
          </div>

      </Grid>

      <Dialog
        open={modal}
        transition={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth = {"lg"}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
        {/*
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          */}
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            {detalleDocumento.users ? (
              <p>
                <b>Compañía:</b> {detalleDocumento.users.company}
              </p>
            ) : null}
            <p>
              <b>Documento:</b> {detalleDocumento.filename}
            </p>
            {detalleDocumento.description ? (
              <p>
                <b>Descripción:</b> {detalleDocumento.description}
              </p>
            ) : (
              <p>
                <b>Descripción:</b> {""}
              </p>
            )}

            {detalleDocumento.meta && detalleDocumento.meta.vigencia_start ? (
              <p>
                <b>Inicio de Vigencia:</b>{" "}
                {moment(detalleDocumento.meta.vigencia_start).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </p>
            ) : (
              <p>
                <b>Inicio de Vigencia:</b>
              </p>
            )}

            {detalleDocumento.meta && detalleDocumento.meta.vigencia_end ? (
              <p>
                <b>Término de Vigencia:</b>{" "}
                {moment(detalleDocumento.meta.vigencia_end).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </p>
            ) : (
              <p>
                <b>Término de Vigencia:</b>
              </p>
            )}

            {detalleDocumento.constancia ? (
              <React.Fragment>
                <b>Constancia:</b> &nbsp;
                {detalleDocumento.constancia.descripcion}
                &nbsp;
                <b>Fecha:</b>
                &nbsp;
                {moment(detalleDocumento.constancia.datetime).format(
                  "DD/MM/YYYY"
                )}
                &nbsp;
                <b>ID:</b> &nbsp;{detalleDocumento.constancia.id}
              </React.Fragment>
            ) : null}
            <MaterialTable
              localization={localization}
              options={{
                draggable: false,
                exportButton: { csv: true, pdf: false },
                headerStyle: {
                  backgroundColor: colorStatistics,
                  color: "#FFF",
                },
                pageSize: 5, // make initial page size
                pageSizeOptions: [5, 20, 50, 100, 500, 1000],
                actionsColumnIndex: -1,
                actionsColumnIndex: 0,
              }}
              columns={[
                { title: "Nombre", field: "name" },
                { title: "Apellido", field: "last_name" },
                { title: "Empresa", field: "company" },
                { title: "Rol", field: "role" },
                { title: "Correo", field: "email" },
                {
                  title: "Creado",
                  field: "created_at",
                  render: (row) => (
                    <span>
                      {(row["created_at"])? moment(row["created_at"]).format("DD/MM/YYYY HH:mm:ss"):null}
                    </span>
                  ),
                },
                {
                  title: "Notificado",
                  field: "date_notified",
                  render: (row) => (
                    <span>
                      {(row["date_notified"])? moment(row["date_notified"]).format("DD/MM/YYYY HH:mm:ss"):null}
                    </span>

                  ),
                },
                {
                  title: "Firma",
                  field: "date_signed",
                  render: (row) => (
                    <span>
                      {(row["date_signed"])? moment(row["date_signed"]).format("DD/MM/YYYY HH:mm:ss"):null}
                    </span>
                  ),
                },
                { title: "Tiempo",
                  render: (row) => (
                    <span>
                      {tiempo(row.date_notified,row.date_signed)}
                    </span>
                  ),
                },
              ]}
              data={detalleDocumento.firmantes}
              title="Firmantes"
            />
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModal(false)} color="success">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
