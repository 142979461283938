import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import TextField from "@material-ui/core/TextField";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/FooterLogin.js";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
// import SectionBasics from "./Sections/SectionBasics.js";
// import SectionNavbars from "./Sections/SectionNavbars.js";
// import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";
import SignUp from "containers/signUp/signUp.js";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import styles from "assets/jss/material-kit-react/views/components.js";

import logo from "assets/img/ConCertezaAzul.png";
import hats from "./whats.css";

const useStyles = makeStyles(styles);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
      borderColor: "white",
      borderWidth: 2,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
      borderColor: "white",
      borderWidth: 2,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",

        borderWidth: 2,
      },
      "&:hover fieldset": {
        borderColor: "yellow",

        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",

        borderWidth: 2,
      },
    },
  },
})(TextField);

const useStyles2 = makeStyles((theme) => ({
  whatsapp: {
    position: "fixed",
    width: "80px",
    height: "80px",
    bottom: " 60px",
    right: " 60px",
    backgroundColor: " #25d366",
    color: "#ffff",
    borderRadius: "60px",
    textAlign: "center",
    fontSize: "50px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Components(props) {
  const classes = useStyles();
  const classess2 = useStyles2();
  const [openRegister, setOpenRegister] = React.useState(false);
  const { ...rest } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Header
        brand={<img src={logo} alt="logo" width="185" height="52" />}
        rightLinks={<HeaderLinks open={open} setOpen={setOpen} />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 200,
          color: "black",
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/logoMano.png")}>
        <div className={classes.container}>
          <GridContainer>
            <Hidden only={["sm", "xs"]}>
              <GridItem>
                <div className={classes.note}>
                  <h3
                    className={classes.subtitle}
                    style={{
                      color: "#8304b3",
                    }}
                  >
                    La forma más sencilla y segura para firmar, certificar y
                    notificar documentos electrónicos con certeza jurídica
                  </h3>
                </div>
              </GridItem>
            </Hidden>
            <Hidden only={["lg", "md"]}>
              <GridItem>
                <div className={classes.note}>
                  <h3
                    style={{
                      fontSize: "1.7rem",
                      color: "#0476E8",

                      fontWeight: "800",
                      margin: "20px",
                      marginTop: "-35px",
                      "-webkit-text-stroke": "0.9px white",
                    }}
                  >
                    La forma más sencilla y segura para firmar, certificar y
                    notificar documentos electrónicos con certeza jurídica
                  </h3>
                </div>
                <center>
                  <Button
                    onClick={() => {
                      setOpenRegister(true);
                    }}
                    variant="contained"
                    color="primary"
                    style={{
                      margin: "5px",
                      height: "60px",
                      width: "200px",
                      background: "#0074eb",
                    }}
                  >
                    prueba gratis
                  </Button>
                </center>
              </GridItem>
            </Hidden>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionPills />
        <Hidden only={["sm", "xs"]}>
          <div
            style={{
              marginTop: "-300",
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={require("assets/img/backgroundLegal.jpg")}
              width="100%"
              height="500"
            />
            <div
              style={{
                textAlign: "center",
                color: "#FFF",
                position: "absolute",
                padding: "80px 240px",
                marginTop: "100",
              }}
            >
              <h3 style={{ fontSize: "2.8rem", fontWeight: "500" }}>
                <i>¿Sabías que…?</i>
              </h3>
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "300",
                  lineHeight: "150%",
                }}
              >
                Las leyes en México reconocen el uso de las Firmas Electrónicas
                equiparando sus efectos jurídicos a los de la firma autógrafa.
                Ahorra tiempo de procesos, deja de imprimir, ya no gastes en
                envíos de documentos por paquetería, obtén el control total del
                proceso de firma, olvídate de almacenar los documentos en
                carpetas físicas y aumenta la productividad de tu negocio. Firma
                Digital Con-Certeza tus documentos digitales en minutos, desde
                cualquier lugar, y con validez legal plena.
              </p>
            </div>
          </div>
        </Hidden>

        <Hidden only={["lg", "md"]}>
          <div></div>
        </Hidden>

        <Hidden only={["sm", "xs"]}>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <br />
            <h3 style={{ fontSize: "2.1rem", fontWeight: "500" }}>
              Conoce el proceso de Firma Digital Con-Certeza
            </h3>
            <br />
            <img
              src={require("assets/img/Con-CertezaProceso.jpg")}
              width="90%"
              height="400"
            />
          </div>
        </Hidden>
        <Hidden only={["lg", "md"]}>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <h3 style={{ fontSize: "2.1rem", fontWeight: "500" }}>
              Conoce el proceso de Firma Digital Con-Certeza
            </h3>

            <img
              src={require("assets/img/procesoCon-CertezaResponsive.png")}
              width="100%"
              height="600"
            />
          </div>
        </Hidden>
        {/* <div style={{ backgroundColor: "#0474e3" }}>
          <h3
            style={{
              fontSize: "2.1rem",
              fontWeight: "500",
              marginLeft: "40px",
              marginTop: "60px",
              paddingTop: "40px",
              color: "white",
            }}
          >
            Contactanos
          </h3>
          <br />
          <form className={classes.form} style={{ marginLeft: "40px" }}>
            <Grid container spacing={2}>
              <Grid item item xs={12} sm={7}>
                <Grid container spacing={2}>
                  <Grid item item xs={6} sm={6}>
                    <CssTextField
                      style={{ width: "100%" }}
                      variant="outlined"
                      inputProps={{
                        style: {
                          color: "white",
                          width: "290px",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      id="custom-css-standard-input"
                      label="Tu nombre"
                    />
                  </Grid>
                  <Grid item item xs={6} sm={6}>
                    <CssTextField
                      variant="outlined"
                      style={{ width: "100%" }}
                      inputProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      id="custom-css-standard-input"
                      label="E-mail"
                    />
                  </Grid>
                  <Grid item item xs={6} sm={6}>
                    <CssTextField
                      style={{ width: "100%" }}
                      variant="outlined"
                      inputProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      id="custom-css-standard-input"
                      label="Telefono o celular"
                    />
                  </Grid>
                  <Grid item item xs={6} sm={6}>
                    <CssTextField
                      style={{ width: "100%" }}
                      variant="outlined"
                      inputProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      id="custom-css-standard-input"
                      label="Ciudad"
                    />
                  </Grid>
                  <Grid item item xs={6} sm={6}>
                    <CssTextField
                      style={{ width: "100%" }}
                      variant="outlined"
                      multiline
                      rows={5}
                      inputProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      id="custom-css-standard-input"
                      label="Comentario"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item item xs={3} sm={3}>
                <img
                  src={
                    "https://certificaciondigital.online/wp-content/uploads/2020/10/Certificacion-Digital-Online-CDO-Certeza-Juri%CC%81dica-Validez-Legal-de-tus-Documentos-Electro%CC%81nicos-contacto.png"
                  }
                  width="150%"
                  height="550"
                  style={{ marginLeft: "69px", marginTop: "-80px" }}
                />
              </Grid>
            </Grid>
          </form>
        </div> */}

        <a
          href="https://wa.me/5215529725568"
          className={classess2.whatsapp}
          target="_blank"
        >
          {" "}
          <i class="fa fa-whatsapp whatsapp-icon"></i>
        </a>
      </div>
      <Footer />

      {/* dialogo para registrarse */}

      <Dialog
        open={openRegister}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <SignUp />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpenRegister(false);
            }}
            color="secondary"
          >
            cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
