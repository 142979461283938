import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
//import ResizableRect from 'react-resizable-rotatable-draggable'
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import HelpIcon from "@material-ui/icons/Help";
import BackspaceIcon from "@material-ui/icons/Backspace";

import UndoIcon from "@material-ui/icons/Undo";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";

import axios from "axios";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import LogoFirma from "./newLogoFirma";
import FirmaPlasmada from "./firmaPlasmada";
import CuadroFirma from "./newCuadroFirma";
import rectangle from "./rectangle-firma.png";
import ControlPage from "./controlpage";
import { objectOf } from "prop-types";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function UbicarFirma(props) {
  const inputRef = useRef();
  const [tokenstate, setTokenstate] = React.useState("");
  //const [personalizada, setPersonalizada] = React.useState(false);
  //const personalizar = () => setPersonalizada(personalizada => !personalizada);
  const idDocumento = props.idDocumento;
  const nombre = props.nombre;
  const setCoordenadasFirma = props.setCoordenadasFirma;
  const topmt = props.topmt;
  const displaySign = props.displaySign;
  const {
    setDisplaySign,
    idFirmante,
    setCoordenadas,
    ubicacionFirmaHistoria,
    autoFirma,
    clickFijar,
    setUbicacionFirmaHistoria,
    firmaYaHechas,
  } = props;

  console.log("PROPSUBICAR", props);
  const [base64, setbase64] = React.useState("");
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(400);
  const [pdfWidth, setpdfWidth] = React.useState(475);
  const [marginTop, setMarginTop] = React.useState(600);
  //referencia a la distancia a la izq del DIV
  const [left, setLeft] = React.useState(0);
  const [disableFijar, setDisableFijar] = React.useState(true);
  const [IdclickFirmante, setIdclickFirmante] = React.useState([]);

  //almacena las firmas del firmante actual
  const [ubicacionFirma, setUbicacionFirma] = React.useState([
    {
      page: 0,
      x: 0,
      y: 0,
      screenX: 0,
      screenY: 0,
      w: 0,
      h: 0,
      top: 0,
      left: 0,
      nombre: "",
      height: 0,
      width: 0,
    },
  ]);

  React.useEffect(() => {
    const rect = inputRef.current.getBoundingClientRect();
    console.log("Ubicación PDF:", rect);

    setLeft(rect.left);
    //var localY = event.clientY

    if (isMobile()) {
      setpdfHeigth(500);
      setpdfWidth(290);
      setMarginTop(400);
    }
    let token = localStorage.getItem("Token");
    setTokenstate(token);

    axios
      .get(
        `${process.env.REACT_APP_URL}api/documentos/${idDocumento}/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [base64]);

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function Firmas() {
    //let result = ubicacionFirma.filter(det => det.pagina = 1);
    //{ubicacionFirma.map((detalle, index) =>
    if (ubicacionFirmaHistoria) {
      if (ubicacionFirmaHistoria.length >= 1) {
        return (
          <div>
            {ubicacionFirmaHistoria
              .filter((item) => item.page === pageNumber)
              .map((detalle, index) => (
                <LogoFirma
                  key={index}
                  src={rectangle}
                  w={detalle.w}
                  h={detalle.h}
                  coordenadas={{ x: detalle.screenX + 1, y: detalle.screenY }}
                  left={detalle.left - 23}
                  bottom={detalle.top - 15}
                  texto={detalle.nombre}
                  height={detalle.height}
                  width={detalle.width}
                  mobile={isMobile()}
                  topmt={topmt}
                  data={detalle}
                  ubicacionFirmaHistoria={ubicacionFirmaHistoria}
                  setUbicacionFirmaHistoria={setUbicacionFirmaHistoria}
                />
              ))}
          </div>
        );
      }
    }
    return <div></div>;
  }

  function FirmasPlasmadas() {
    //let result = ubicacionFirma.filter(det => det.pagina = 1);
    //{ubicacionFirma.map((detalle, index) =>
    if (firmaYaHechas) {
      if (firmaYaHechas.length >= 1) {
        return (
          <div>
            {firmaYaHechas
              .filter((item) => item.page === pageNumber)
              .map((detalle, index) => (
                <FirmaPlasmada
                  key={index}
                  src={rectangle}
                  w={detalle.w}
                  h={detalle.h}
                  coordenadas={{
                    x: detalle.screenX + 1,
                    y: detalle.screenY,
                  }}
                  left={detalle.left - 23}
                  bottom={detalle.top - 15}
                  texto={detalle.nombre}
                  height={detalle.height}
                  width={detalle.width}
                  mobile={isMobile()}
                  topmt={topmt}
                  data={detalle}
                  ubicacionFirmaHistoria={firmaYaHechas}
                  setUbicacionFirmaHistoria={setUbicacionFirmaHistoria}
                />
              ))}
          </div>
        );
      }
    }
    return <div></div>;
  }
  return (
    <React.Fragment>
      <Grid>
        <div ref={inputRef}>
          <br />
          <ControlPage
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            file={`data: application / pdf; base64, ${base64} `}
          />
          <Document
            error="Error al intentar cargar el PDF"
            loading="Cargando..."
            file={`data: application / pdf; base64, ${base64} `}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              //size="LEGAL"
              scale={1}
              height={pdfHeigth}
              width={pdfWidth}
              renderTextLayer={false}
              pageNumber={pageNumber}
            />
          </Document>
          {displaySign == true ? (
            <CuadroFirma
              pagina={pageNumber}
              defx={0}
              defy={marginTop}
              left={left}
              setCoordenadas={setCoordenadas}
              nombre={props.nombre}
              setDisableFijar={setDisableFijar}
              clickFijar={clickFijar}
              texto={nombre}
              setDisplaySign={setDisplaySign}
              idFirmante={props.idFirmante}
              autoFirma={autoFirma}
            />
          ) : (
            <div />
          )}
        </div>
      </Grid>
      <Firmas />

      <FirmasPlasmadas />
    </React.Fragment>
  );
}

export default UbicarFirma;
