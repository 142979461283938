import React from "react";
import PropTypes from "prop-types";
// import stylesf from "containers/Firma/styles.module.css";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import { green, blueGrey } from "@material-ui/core/colors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStylesprogrees = makeStyles(() => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: blueGrey[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },
}));

export const DialogComp = ({
  open,
  title,
  content,
  buttons,
  onClose,
  transition,
  maxWidth,
  fullWidth,
  fullScreen,
}) => {
  const classespro = useStylesprogrees();
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth ? maxWidth : false}
        fullWidth={fullWidth ? fullWidth : false}
        fullScreen={fullScreen ? fullScreen : false}
        keepMounted
        TransitionComponent={transition && Transition}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {title && (
          <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttons &&
            buttons.map(
              (
                {
                  buttonColor,
                  buttonName,
                  buttonSize = "",
                  buttonEvent,
                  buttonDisabled = false,
                },
                i
              ) => (
                <Button
                  key={i}
                  variant="contained"
                  disabled={buttonDisabled}
                  size={buttonSize}
                  onClick={buttonEvent}
                  color={buttonColor}
                >
                  {buttonName}
                </Button>
              )
            )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
DialogComp.propTypes = {
  open: PropTypes.bool.isRequired,
  grid: PropTypes.bool,
  title: PropTypes.element,
  buttons: PropTypes.array,
  onClose: PropTypes.element,
  transition: PropTypes.element,
  // content: PropTypes.element,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
};
