// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";

// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import DescriptionIcon from "@material-ui/icons/Description";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Statistics from "containers/Statistics/Statistics";
import Nom151 from "containers/Nom151/Flujo";
import FirmanteDashboard from "containers/FirmantesFrecuentes/FirmanteDashboard";
import Plantillas from "containers/Plantillas/ListadoPlantillas";
import Expedientes from "containers/Expedientes/Inicio";
import GestureIcon from "@material-ui/icons/Gesture";
import BallotIcon from "@material-ui/icons/Ballot";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";

// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
import Components from "containers/ServiciosLegales/ServiciosContainer.js";

import newProcess from "containers/newProcess/newProcess.js";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import AddBoxIcon from "@material-ui/icons/AddBox";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GavelIcon from "@material-ui/icons/Gavel";
import ContainerEditGroup from "containers/editGroup/containerGroup";
import newDashboard from "views/newDashboard/newDashboard";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ContainerAutoSign from "containers/autoSign/autoSingContainer";

let uri = localStorage.getItem("uri");
export const dashboardRoutes = [
  {
    path: "/nuevoProceso",
    name: "NUEVO PROCESO",
    icon: NoteAddIcon,
    component: newProcess,
    layout: `/${uri}`,
  },

  {
    path: "/dashboard",
    name: "DOCUMENTOS",
    rtlName: "لوحة القيادة",
    icon: DescriptionIcon,
    component: DashboardPage,
    layout: `/${uri}`,
  },

  {
    path: "/firmantedashboard",
    name: "MIS FIRMAS ",
    rtlName: "رموز",
    icon: GestureIcon,
    component: FirmanteDashboard,
    layout: `/${uri}`,
  },

  {
    path: "/plantillas",
    name: "PLANTILLAS (BETA) ",
    rtlName: "رموز",
    icon: BallotIcon,
    component: Plantillas,
    layout: `/${uri}`,
  },

  // {
  //   path: "/expedientes",
  //   name: "EXPEDIENTES (DEV) ",
  //   rtlName: "رموز",
  //   icon: FolderSpecialIcon,
  //   component: Expedientes,
  //   layout: `/${uri}`,
  // },

  {
    path: "/statistics",
    name: "ESTADÍSTICAS",
    rtlName: "قائمة الجدول",
    icon: AssessmentIcon,
    component: Statistics,
    layout: `/${uri}`,
  },
  {
    path: "/serviciosLegales",
    name: "SERVICIOS LEGALES",
    rtlName: "الرموز",
    icon: GavelIcon,
    component: Components,
    layout: `/${uri}`,
  },

  //  {
  //    path: "/autoSign",
  //    name: "AUTODECLARACIÓN",
  //    rtlName: "قائمة الجدول",
  //    icon: AssignmentTurnedInIcon,
  //    component: ContainerAutoSign,
  //    layout: `/${uri}`,
  //  },
  //  {
  //    path: "/registrerUser",
  //    name: "NUESTROS CLIENTES ",
  //    rtlName: "الرموز",
  //    icon: PersonAddIcon,
  //    component: ContainerEditGroup,
  //    layout: `/${uri}`,
  //  },
  {
    path: "/user",
    name: "MI PERFIL",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: `/${uri}`,
  },
];

export const AdminRoutes = [
  {
    path: "/nuevoProceso",
    name: "NUEVO PROCESO",
    icon: NoteAddIcon,
    component: newProcess,
    layout: `/${uri}`,
  },

  {
    path: "/dashboard",
    name: "DOCUMENTOS",
    rtlName: "لوحة القيادة",
    icon: DescriptionIcon,
    component: DashboardPage,
    layout: `/${uri}`,
  },
  // {
  //   path: "/newdashboard",
  //   name: "DASHBOARD (BETA)",
  //   rtlName: "الرموز",
  //   icon: Dashboard,
  //   component: newDashboard,
  //   layout: `/${uri}`,
  // },

  {
    path: "/firmantedashboard",
    name: "MIS FIRMAS ",
    rtlName: "رموز",
    icon: GestureIcon,
    component: FirmanteDashboard,
    layout: `/${uri}`,
  },

  {
    path: "/plantillas",
    name: "PLANTILLAS (BETA) ",
    rtlName: "رموز",
    icon: BallotIcon,
    component: Plantillas,
    layout: `/${uri}`,
  },

  {
    path: "/expedientes",
    name: "EXPEDIENTES (DEV) ",
    rtlName: "رموز",
    icon: FolderSpecialIcon,
    component: Expedientes,
    layout: `/${uri}`,
  },

  {
    path: "/statistics",
    name: "ESTADÍSTICAS",
    rtlName: "قائمة الجدول",
    icon: AssessmentIcon,
    component: Statistics,
    layout: `/${uri}`,
  },
  {
    path: "/serviciosLegales",
    name: "SERVICIOS LEGALES",
    rtlName: "الرموز",
    icon: GavelIcon,
    component: Components,
    layout: `/${uri}`,
  },

  // {
  //   path: "/autoSign",
  //   name: "AUTODECLARACIÓN",
  //   rtlName: "قائمة الجدول",
  //   icon: AssignmentTurnedInIcon,
  //   component: ContainerAutoSign,
  //   layout: `/${uri}`,
  // },
  {
    path: "/registrerUser",
    name: "NUESTROS CLIENTES ",
    rtlName: "الرموز",
    icon: PersonAddIcon,
    component: ContainerEditGroup,
    layout: `/${uri}`,
  },
  {
    path: "/user",
    name: "MI PERFIL",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: `/${uri}`,
  },
];

export const GondiRoutes = [
  {
    path: "/expedientes",
    name: "EXPEDIENTES",
    rtlName: "رموز",
    icon: FolderSpecialIcon,
    component: Expedientes,
    layout: `/${uri}`,
  },
  {
    path: "/user",
    name: "MI PERFIL",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: `/${uri}`,
  },
];
